import {format} from "date-fns";

export const selectNongamestatsState = store => store.nongamestatsData;

export const selectNongamestatsByRosterStat = (rosterId, stat) => (store) => {
    try {
        return selectNongamestatsState(store).dataByRosterStat[rosterId + '~' + stat].map(r => {
            return {
                ...r,
                key: r.playerId,
                date_recorded: r.date_recorded ? format(new Date(r.date_recorded), 'MM/dd/yyyy') : null,
                [stat + '_target']: r[stat + '_target'] ? r[stat + '_target'] : 'none'
            }
        });
    }
    catch (e) {
        return null;
    }
}

export const selectNongamestatsLoading = store =>
    selectNongamestatsState(store) ? selectNongamestatsState(store).loading : false;
