import React from "react"
import {parseQueryString} from "../../shared-components/queryStringUtils";
import RosterTabs from "./components/RosterTabs";
import TeamPerformance from "./components/TeamPerformance";
import {Card, Col, Layout, Row} from 'antd';
import TeamSchedule from "./components/TeamSchedule";
import {PlayerPositionGroupTabs} from "./components/playerPositionGroupTabs";

const Dashboard = (props) => {

    const {rosterId} = parseQueryString(props.location.search, 'rosterId');

    return (
        <div>
            <Layout>
                <Layout.Content>
                    <RosterTabs rosterId={rosterId} hideInactive={true} hideScouting={true} />
                    <Col>
                        <Row>
                            <Card style={{marginBottom: "10px"}}>
                                <TeamSchedule rosterId={rosterId}/>
                            </Card>
                        </Row>
                        <Row>
                            <Card style={{marginBottom: "10px"}}>
                                <TeamPerformance rosterId={rosterId}/>
                            </Card>
                        </Row>
                        <Row>
                            <Card title={"Depth Chart"} style={{marginBottom: "10px"}}>
                                <PlayerPositionGroupTabs rosterId={rosterId} readOnly={true}/>
                            </Card>
                        </Row>
                    </Col>
                </Layout.Content>
            </Layout>
        </div>
);
}

export default Dashboard;
