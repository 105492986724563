import {
    GET_PLAYER_LINKS_FAILURE,
    GET_PLAYER_LINKS_STARTED,
    GET_PLAYER_LINKS_SUCCESS,

    PUT_PLAYER_LINKS_FAILURE,
    PUT_PLAYER_LINKS_STARTED,
    PUT_PLAYER_LINKS_SUCCESS,

    GET_PLAYER_ABOUTS_FAILURE,
    GET_PLAYER_ABOUTS_STARTED,
    GET_PLAYER_ABOUTS_SUCCESS,

    PUT_PLAYER_ABOUTS_FAILURE,
    PUT_PLAYER_ABOUTS_STARTED,
    PUT_PLAYER_ABOUTS_SUCCESS,

    GET_MY_PLAYERIDS_FAILURE,
    GET_MY_PLAYERIDS_STARTED,
    GET_MY_PLAYERIDS_SUCCESS,

    GET_PLAYER_PROFILEDATA_FAILURE,
    GET_PLAYER_PROFILEDATA_STARTED,
    GET_PLAYER_PROFILEDATA_SUCCESS,
    CLEAR_PLAYER_PROFILEDATA,

} from '../actions/playerProfileActions';

const initialState = {
    loading: false,
    syncing: false,
    error: null,
    currentPlayerId: null,
    playerIds: [],
    myPlayerIds: [],
    linksByPlayerId: {},
    aboutsByPlayerId: {},
    profiledataByPlayerId: {},
};

export default function playerProfileReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PLAYER_LINKS_STARTED:
            return {
                ...state,
                loading: true,
                currentPlayerId: action.payload,
            };
        case GET_PLAYER_LINKS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                playerIds: state.playerIds.includes(action.payload.playerId) ? state.playerIds : [...state.playerIds, action.payload.playerId],
                linksByPlayerId: { ...state.linksByPlayerId, [action.payload.playerId]: action.payload.data },
            };
        case GET_PLAYER_LINKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case PUT_PLAYER_LINKS_STARTED:
            return {
                ...state,
                syncing: true,
                linksByPlayerId: { ...state.linksByPlayerId, [action.payload.playerId]: action.payload.data },
            };
        case PUT_PLAYER_LINKS_SUCCESS:
            return {
                ...state,
                syncing: false,
                error: null,
            };
        case PUT_PLAYER_LINKS_FAILURE:
            return {
                ...state,
                syncing: false,
                error: action.payload.error
            };
        case GET_PLAYER_ABOUTS_STARTED:
            return {
                ...state,
                loading: true,
                currentPlayerId: action.payload,
            };
        case GET_PLAYER_ABOUTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                playerIds: state.playerIds.includes(action.payload.playerId) ? state.playerIds : [...state.playerIds, action.payload.playerId],
                aboutsByPlayerId: { ...state.aboutsByPlayerId, [action.payload.playerId]: action.payload.data },
            };
        case GET_PLAYER_ABOUTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case PUT_PLAYER_ABOUTS_STARTED:
            return {
                ...state,
                syncing: true,
                aboutsByPlayerId: { ...state.aboutsByPlayerId, [action.payload.playerId]: action.payload.data },
            };
        case PUT_PLAYER_ABOUTS_SUCCESS:
            return {
                ...state,
                syncing: false,
                error: null,
            };
        case PUT_PLAYER_ABOUTS_FAILURE:
            return {
                ...state,
                syncing: false,
                error: action.payload.error
            };
        case GET_MY_PLAYERIDS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_MY_PLAYERIDS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                myPlayerIds: action.payload.data.map(p => p.playerId),
            };
        case GET_MY_PLAYERIDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case GET_PLAYER_PROFILEDATA_STARTED:
            return {
                ...state,
                loading: true,
                currentPlayerId: action.payload,
            };
        case GET_PLAYER_PROFILEDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                playerIds: state.playerIds.includes(action.payload.playerId) ? state.playerIds : [...state.playerIds, action.payload.playerId],
                profiledataByPlayerId: { ...state.profiledataByPlayerId, [action.payload.playerId]: action.payload.data },
            };
        case GET_PLAYER_PROFILEDATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case CLEAR_PLAYER_PROFILEDATA:
            return {
                ...state,
                profiledataByPlayerId: { ...state.profiledataByPlayerId, [action.payload.playerId]: null },
            }
        default:
            return state;
    }
}
