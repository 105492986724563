import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {selectGamedataByGameId, selectGamedataLoading} from "../../redux/selectors/gamesSelectors";
import {
    getGamedataByGameId,
    recalcGamestatsByGameId,
    resetGamedataByGameId
} from "../../redux/actions/gamesActions";
import '../../css/game.css';
import PlayerGamestatsTable from "./components/PlayerGamestatsTable";
import {Button, Layout, Popconfirm, Space, Tabs} from "antd";
import moment from "moment";
import GameUpload from "./components/GameUpload";
import {TeamEfficiency} from "./components/GameAnalytics";
import TeamPerformance from "./components/TeamPerformance";
import {CustomAnalytics} from "./CustomAnalytics";

const {TabPane} = Tabs;

export const GameHeader = ({gameInfo, gameScores}) => {

    if (gameInfo) {
        const final = gameScores.filter(p => p.period === 'F')[0];
        let wlt = null;
        if (final && final.score) {
            if (parseInt(final.score) > parseInt(final.opposingScore))
                wlt = 'W';
            else if (parseInt(final.score) < parseInt(final.opposingScore))
                wlt = 'L';
            else if (parseInt(final.score) === parseInt(final.opposingScore))
                wlt = 'T';
            else
                wlt = 'X';
        }

        return (
            <h1>
                {moment.utc(new Date(gameInfo.date)).format('M/D/YYYY')}{' '}
                {gameInfo.level === 'Scouting' ? 'Scouting: ' + gameInfo.teamName : ''}
                {(gameInfo.gameType === 'home' ? ' vs ' : ' @ ')}
                {gameInfo.opposingSchoolName + ' ' + gameInfo.opposingTeamName}
                {wlt ? ' (' + wlt + ') ' : null}

            </h1>
        );
    } else
        return null;
}

const periodSorter = (p1, p2) => {
    const ps1 = p1.period.substring(0, 1);
    const ps2 = p2.period.substring(0, 1);

    if (ps1 === ps2)
        return p1.period.localeCompare(p2.period);
    else if (ps1 === 'Q')
        return -1;
    else if (ps2 === 'Q')
        return 1;
    else if (ps1 === 'F')
        return 1;
    else if (ps2 === 'F')
        return -1;
    else
        return 0
}

const GameScore = ({gameScores, teamName, opposingTeamName}) => {
    if (gameScores) {
        gameScores.sort(periodSorter);
        return (
            <table className="scoreTable">
                <thead>
                <tr>
                    <th></th>
                    {gameScores.map((p, i) => (<th key={i}>{p.period}</th>))}
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{teamName}</td>
                    {gameScores.map((p, i) => (<td key={i}>{p.score}</td>))}
                </tr>
                <tr>
                    <td>{opposingTeamName}</td>
                    {gameScores.map((p, i) => (<td key={i}>{p.opposingScore}</td>))}
                </tr>
                </tbody>

            </table>
        )
    } else
        return null;
}

const GameScreen = ({rosterId, gameId, seasonId}) => {

    const data = useSelector(selectGamedataByGameId(gameId));
    const loading = useSelector(selectGamedataLoading);
    const dispatch = useDispatch();

    useEffect(() => {
            // don't do anything if already loading
            if (!loading && !data) {
                dispatch(getGamedataByGameId(gameId));
            }
        }
    )

    if (data && data.game_scores.length > 0) {
        return (
            <Layout>
                <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                    <GameHeader gameInfo={data ? data.game_info[0] : null}
                                gameScores={data ? data.game_scores : null}
                    />
                </Layout.Content>
                <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                    <GameScore gameScores={data ? data.game_scores : null}
                               teamName={data ? data.game_info[0].teamName : null}
                               opposingTeamName={data ? data.game_info[0].opposingTeamName : null}
                    />
                </Layout.Content>

                <Tabs>
                    <TabPane tab="Breakdown" key="breakdown">
                        <TeamPerformance rosterId={rosterId} gameId={gameId}/>
                        <TeamEfficiency rosterId={rosterId} gameId={gameId} od='O'/>
                        <TeamEfficiency rosterId={rosterId} gameId={gameId} od='D'/>
                    </TabPane>
                    {
                        (data.game_info[0].level !== 'Scouting')
                            ?
                            (<>
                                <TabPane tab="Offensive Production" key="offence">
                                    <h2>Passing</h2>
                                    <PlayerGamestatsTable statGroupings={'passing'} gameId={gameId}
                                                          sortField={'Passing_YDS'}
                                                          sortOrder={'descend'}/>
                                    <h2>Rushing</h2>
                                    <PlayerGamestatsTable statGroupings={'rushing'} gameId={gameId}
                                                          sortField={'Rushing_YDS'}
                                                          sortOrder={'descend'}/>
                                    <h2>Receiving</h2>
                                    <PlayerGamestatsTable statGroupings={'receiving'} gameId={gameId}
                                                          sortField={'Receiving_YDS'}
                                                          sortOrder={'descend'}/>
                                    <h2>Kicking</h2>
                                    <PlayerGamestatsTable statGroupings={'kicking'} gameId={gameId}
                                                          sortField={'playerName'}
                                                          sortOrder={'asscend'}/>
                                </TabPane>
                                <TabPane tab="Defensive Production" key="defence">
                                    <h2>Defense</h2>
                                    <PlayerGamestatsTable statGroupings={'defense'} gameId={gameId}
                                                          sortField={'Defense_TOT'}
                                                          sortOrder={'descend'}/>
                                </TabPane>
                            </>)
                            :
                            (<></>)
                    }
                    <TabPane tab="Analytics" key="analytics">
                        <CustomAnalytics
                            gameId={gameId}
                            seasonId={seasonId}
                        />
                    </TabPane>
                </Tabs>

                <Space style={{display: 'flex', justifyContent: 'center', margin: 10}}>
                    <Popconfirm
                        placement="top"
                        title={() => (
                            <>
                                <p>Are you sure you want to reset game data?</p>
                                <p>This will remove play-by-play data for this game and<br/>
                                    calculated player, team, and score summaries.</p>
                            </>
                        )}
                        onConfirm={() => dispatch(resetGamedataByGameId(gameId))}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                        >
                            Reset Game Data
                        </Button>
                    </Popconfirm>

                    <Popconfirm
                        placement="top"
                        title={() => (
                            <>
                                <p>Are you sure you want to recalc game stats?</p>
                            </>
                        )}
                        onConfirm={() => dispatch(recalcGamestatsByGameId(rosterId, gameId))}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                        >
                            Recalc Game Stats
                        </Button>
                    </Popconfirm>
                </Space>
            </Layout>
        );
    } else if (data && data.game_scores.length === 0) {
        return <GameUpload rosterId={rosterId} gameId={gameId} seasonId={seasonId}/>;
    } else {
        return (
            <Layout>
                <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                    <GameHeader gameInfo={data ? data.game_info[0] : null}
                                gameScores={data ? data.game_scores : null}
                    />
                </Layout.Content>
            </Layout>
        );
    }


}

export default GameScreen;
