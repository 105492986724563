import React, {useEffect} from "react";
import {PlayerPositionGroupTabs} from "../coach/components/playerPositionGroupTabs";
import {useDispatch, useSelector} from "react-redux";
import {selectMyPlayerIds, selectPlayerProfileLoading} from "../../redux/selectors/playerProfileSelectors";
import {parseQueryString} from "../../shared-components/queryStringUtils";
import {selectPlayerstatsByPlayerId} from "../../redux/selectors/playerstatsSelectors";
import {getMyPlayerIds} from "../../redux/actions/playerProfileActions";

const Team = (props) => {
    const dispatch = useDispatch();
    const myPlayerIds = useSelector(selectMyPlayerIds);
    const loading = useSelector(selectPlayerProfileLoading);
    const playerId = parseQueryString(props.location.search, 'playerId').playerId ?
        parseQueryString(props.location.search, 'playerId').playerId :
        myPlayerIds[0];
    const playerData = useSelector(selectPlayerstatsByPlayerId(playerId));
    const rosterId = playerData && playerData.player_info[0] ? playerData.player_info[0].rosterId : null;

    useEffect(() => {
        if (!loading && !playerId && !playerData) {
            dispatch(getMyPlayerIds());
        }
    });

    return (
        <div className="center">
            <h1>
                Team Management
            </h1>
            <PlayerPositionGroupTabs rosterId={rosterId} readOnly={true}/>
        </div>
    );
}

export default Team;