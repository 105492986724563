import React, {useState} from "react";
import {Input, Modal} from "antd";

const NameSaveFilterModal = ({isVisible, onHandle, handleCreate, filter}) => {
    const [inputValue, setInputValue] = useState('');
    const okCancel = (ok) => () => {
        if(ok) {
            handleCreate(inputValue);
        }

        onHandle();
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <Modal
            title="Saved Filter Name"
            visible={isVisible}
            onOk={okCancel(true)}
            onCancel={okCancel(false)}
            destroyOnClose={true}
        >
            <Input
                placeholder={'Saved Filter Name'}
                defaultValue={filter ? filter.filterName : null}
                autoFocus
                onChange={handleInputChange}
                onPressEnter={okCancel(true)}
            />
        </Modal>
    );
};

export default NameSaveFilterModal;