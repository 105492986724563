import React from "react";
import {Auth} from "aws-amplify";

import {navigate} from "@reach/router"
import {Button} from "antd";

const signOut = () => {
    Auth.signOut();
    navigate('/');
}

const Signout = () => {
    //const { user, signOut } = useAuthenticator((context) => [context.user]);

    return (
        <Button onClick={signOut}>
            Sign Out
        </Button>
    );
}

export default Signout;
