import React, {useEffect} from "react";
import {Tabs} from "antd";
import {useLocation, useNavigate} from "@reach/router"
import {nongamestats} from "../../../shared-components/categories_fb";
import * as queryString from "query-string";
import './custom_styles.css';

const {TabPane} = Tabs;

const NongamestatTabs = ({stat, rosterId, children}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const onChangeStat = (stat) => {
        const queryParams = queryString.parse(location.search);
        navigate(location.pathname + '?' + queryString.stringify({...queryParams, stat}));
    };

    const statOptions = nongamestats
        .map(s => <TabPane key={s.stat} value={s.stat} tab={s.label}>{children}</TabPane>)
        .concat(<TabPane key="attribs" value="attribs" tab="Player Attributes">{children}</TabPane>);

    useEffect(() => {
        // go to first stat if none is selected:
        if (!stat) {
            onChangeStat(nongamestats[0].stat);
        }
    });

    return (
        <>
            <Tabs
                activeKey={stat}
                onChange={onChangeStat}
                tabPosition={'left'}
            >
                {statOptions}
            </Tabs>
        </>
    );
};

export default NongamestatTabs;
