import {
    GET_QUICKSEARCH_FAILURE,
    GET_QUICKSEARCH_STARTED,
    GET_QUICKSEARCH_SUCCESS,
} from '../actions/quicksearchActions';

const initialState = {
    loading: false,
    error: null,
    currentQuery: null,
    searchResults: null,
};

export default function quicksearchReducer(state = initialState, action) {
    switch (action.type) {
        case GET_QUICKSEARCH_STARTED:
            return {
                ...state,
                loading: true,
                currentQuery: action.payload,
            };
        case GET_QUICKSEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                currentQuery: action.payload.queryString,
                searchResults: action.payload.data,
            };
        case GET_QUICKSEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}
