import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectGamestatColumnsByQueryString,
    selectGamestatsByQueryString,
    selectGamestatsLoading
} from "../../../redux/selectors/gamestatsSelectors";
import {getGamestatsByFilter} from "../../../redux/actions/gamestatsActions";
import PlayerTable from "../../../shared-components/PlayerTable";

const PlayerGamestatsTable = ({gameId, statGroupings, sortField, sortOrder}) => {

    const queryString = 'gameId=' + gameId + '&statGroupings=' + statGroupings;
    const playerData = useSelector(selectGamestatsByQueryString(queryString));
    const loading = useSelector(selectGamestatsLoading);
    const columns = useSelector(selectGamestatColumnsByQueryString(queryString))
        // apply sortOrder to columns
        .map(column => { return { ...column, sortOrder: (column.dataIndex === sortField ? sortOrder : false)}})

    const dispatch = useDispatch();

    useEffect(() => {
            if (!loading && !playerData)
                dispatch(getGamestatsByFilter({gameId, statGroupings}));
        }
    );

    return (
        <PlayerTable
            playerData={playerData}
            columns={columns}
            loading={loading}
            navBase={'/coaches'}
            useStateUpdate={true}
        />
    )};

export default PlayerGamestatsTable;
