import {API} from "aws-amplify";

export const GET_QUICKSEARCH_STARTED = 'GET_QUICKSEARCH_STARTED';
export const GET_QUICKSEARCH_SUCCESS = 'GET_QUICKSEARCH_SUCCESS';
export const GET_QUICKSEARCH_FAILURE = 'GET_QUICKSEARCH_FAILURE';

export function getQuicksearchByQuerystring(queryString) {
    console.log('getting quicksearch for: ', queryString);

    return async (dispatch) => {
        try {
            dispatch(getQuicksearchStarted(queryString));
            const players = await API.get('recruiterAPI', `players?${queryString}`);
            console.log('got quicksearch for:', queryString);
            dispatch(getQuicksearchSuccess(queryString, players));
        } catch (e) {
            console.log('problem getting quicksearch:', e);
            dispatch(getQuicksearchFailure(e));
        }
    }
}

const getQuicksearchSuccess = (queryString, data = []) => ({
    type: GET_QUICKSEARCH_SUCCESS,
    payload: {queryString, data}
});

const getQuicksearchStarted = (queryString) => ({
    type: GET_QUICKSEARCH_STARTED,
    payload: queryString,
});

const getQuicksearchFailure = error => ({
    type: GET_QUICKSEARCH_FAILURE,
    payload: {
        error
    }
});
