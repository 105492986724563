import {
    GET_RECRUITER_PLAYER_PIPELINE_HISTORY_FAILURE,
    GET_RECRUITER_PLAYER_PIPELINE_HISTORY_STARTED,
    GET_RECRUITER_PLAYER_PIPELINE_HISTORY_SUCCESS
} from "../actions/recruiterPlayerPipelineHistoryActions";

const initialState = {
    loadingPlayerPipelineHistory: false,
    error: null,
    playerPipelineHistory: [],
    playerPipelineHistoryByPlayerId: {}
};

export default function recruiterPlayerPipelineHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case GET_RECRUITER_PLAYER_PIPELINE_HISTORY_STARTED:
            return {
                ...state,
                playerPipelineHistory: true,
            };
        case GET_RECRUITER_PLAYER_PIPELINE_HISTORY_SUCCESS:
            return {
                ...state,
                loadingPlayerPipelineHistory: false,
                error: null,
                playerPipelineHistory: action.payload.playerNotes,
                playerPipelineHistoryByPlayerId: getRecruiterPlayerPipelineHistoryByPlayerId(action.payload.playerPipelineHistory),
            };
        case GET_RECRUITER_PLAYER_PIPELINE_HISTORY_FAILURE:
            return {
                ...state,
                playerPipelineHistory: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}

function getRecruiterPlayerPipelineHistoryByPlayerId(playerPipelineHistory) {
    return playerPipelineHistory.reduce((r, a) => {
        r[a.playerId] = r[a.playerId] || [];
        r[a.playerId].push({...a});
        return r;
    }, Object.create(null));
}