import {API} from "aws-amplify";

export const GET_RECRUITER_SEARCHES_STARTED = 'GET_RECRUITER_SEARCHES_STARTED';
export const GET_RECRUITER_SEARCHES_SUCCESS = 'GET_RECRUITER_SEARCHES_SUCCESS';
export const GET_RECRUITER_SEARCHES_FAILURE = 'GET_RECRUITER_SEARCHES_FAILURE';

export const GET_RECRUITER_SEARCH_STARTED = 'GET_RECRUITER_SEARCH_STARTED';
export const GET_RECRUITER_SEARCH_SUCCESS = 'GET_RECRUITER_SEARCH_SUCCESS';
export const GET_RECRUITER_SEARCH_FAILURE = 'GET_RECRUITER_SEARCH_FAILURE';

export const CREATE_RECRUITER_SEARCH_STARTED = 'CREATE_RECRUITER_SEARCH_STARTED';
export const CREATE_RECRUITER_SEARCH_SUCCESS = 'CREATE_RECRUITER_SEARCH_SUCCESS';
export const CREATE_RECRUITER_SEARCH_FAILURE = 'CREATE_RECRUITER_SEARCH_FAILURE';

export const CLEAR_NEW_SEARCHID = 'CLEAR_NEW_SEARCHID';

export const UPDATE_RECRUITER_SEARCH_STARTED = 'UPDATE_RECRUITER_SEARCH_STARTED';
export const UPDATE_RECRUITER_SEARCH_SUCCESS = 'UPDATE_RECRUITER_SEARCH_SUCCESS';
export const UPDATE_RECRUITER_SEARCH_FAILURE = 'UPDATE_RECRUITER_SEARCH_FAILURE';

export const DELETE_RECRUITER_SEARCH_STARTED = 'DELETE_RECRUITER_SEARCH_STARTED';
export const DELETE_RECRUITER_SEARCH_SUCCESS = 'DELETE_RECRUITER_SEARCH_SUCCESS';
export const DELETE_RECRUITER_SEARCH_FAILURE = 'DELETE_RECRUITER_SEARCH_FAILURE';

export function getSearches() {
    console.log('getting searches');
    return async (dispatch) => {
        try {
            dispatch(getSearchesStarted());
            const results = await API.get('recruiterAPI', `savedSearches`);
            dispatch(getSearchesSuccess(results));
        } catch (e) {
            dispatch(getSearchesFailure(e));
        }
    }
}

const getSearchesSuccess = (data = []) => ({
    type: GET_RECRUITER_SEARCHES_SUCCESS,
    payload: {data}
});

const getSearchesStarted = () => ({
    type: GET_RECRUITER_SEARCHES_STARTED,
});

const getSearchesFailure = error => ({
    type: GET_RECRUITER_SEARCHES_FAILURE,
    payload: {error},
});

export function getSearch(search) {
    return getSearchById(search.searchId);
}

export function getSearchById(searchId) {
    console.log('getting search:', searchId);
    return async (dispatch) => {
        try {
            dispatch(getSearchByIdStarted());
            const results = await API.get('recruiterAPI', `savedSearches/${searchId}`);
            dispatch(getSearchByIdSuccess(searchId, results));
        } catch (e) {
            dispatch(getSearchByIdFailure(e));
        }
    }
}

const getSearchByIdSuccess = (searchId, data = {}) => ({
    type: GET_RECRUITER_SEARCH_SUCCESS,
    payload: {searchId, data}
});

const getSearchByIdStarted = () => ({
    type: GET_RECRUITER_SEARCH_STARTED,
});

const getSearchByIdFailure = error => ({
    type: GET_RECRUITER_SEARCH_FAILURE,
    payload: {error},
});

export function createSearch(searchData, dispatchOnSuccess = null) {
    console.log('creating search', searchData);
    return async (dispatch) => {
        try {
            dispatch(createSearchStarted());
            const results = await API.post('recruiterAPI', `savedSearches`, {
                body: searchData
            });
            if (dispatchOnSuccess) {
                if (Array.isArray(dispatchOnSuccess))
                    dispatchOnSuccess.forEach(d => dispatch(d({searchId: results.searchId})));
                else
                    dispatch(dispatchOnSuccess({searchId: results.searchId}));
            }
            return dispatch(createSearchSuccess(searchData, results))
        } catch (e) {
            dispatch(createSearchFailure(e));
        }
    }
}

const createSearchSuccess = (searchData, data = {}) => ({
    type: CREATE_RECRUITER_SEARCH_SUCCESS,
    payload: {searchData, data}
});

const createSearchStarted = () => ({
    type: CREATE_RECRUITER_SEARCH_STARTED,
});

const createSearchFailure = error => ({
    type: CREATE_RECRUITER_SEARCH_FAILURE,
    payload: {error},
});

export function clearNewSearchId() {
    return {
        type: CLEAR_NEW_SEARCHID,
    }
}

export function updateSearch(searchId, searchData, dispatchOnSuccess = null) {
    console.log('updating search', searchId, searchData);
    return async (dispatch) => {
        try {
            dispatch(updateSearchStarted(searchId, searchData));
            await API.put('recruiterAPI', `savedSearches/${searchId}`, {
                body: searchData
            });
            if (dispatchOnSuccess) {
                if (Array.isArray(dispatchOnSuccess))
                    dispatchOnSuccess.forEach(d => dispatch(d({searchId: searchId})));
                else
                    dispatch(dispatchOnSuccess({searchId: searchId}));
            }
            dispatch(updateSearchSuccess());
        } catch (e) {
            dispatch(updateSearchFailure(e));
        }
    }
}

const updateSearchSuccess = () => ({
    type: UPDATE_RECRUITER_SEARCH_SUCCESS,
});

const updateSearchStarted = (searchId, searchData) => ({
    type: UPDATE_RECRUITER_SEARCH_STARTED,
    payload: {searchId, searchData},
});

const updateSearchFailure = error => ({
    type: UPDATE_RECRUITER_SEARCH_FAILURE,
    payload: {error},
});

export function deleteSearch(searchId) {
    console.log('deleting search', searchId);
    return async (dispatch) => {
        try {
            dispatch(deleteSearchStarted(searchId));
            await API.del('recruiterAPI', `savedSearches/${searchId}`);
            dispatch(deleteSearchSuccess());
        } catch (e) {
            dispatch(deleteSearchFailure(e));
        }
    }
}

const deleteSearchSuccess = () => ({
    type: DELETE_RECRUITER_SEARCH_SUCCESS,
});

const deleteSearchStarted = (searchId) => ({
    type: DELETE_RECRUITER_SEARCH_STARTED,
    payload: {searchId},
});

const deleteSearchFailure = error => ({
    type: DELETE_RECRUITER_SEARCH_FAILURE,
    payload: {error},
});
