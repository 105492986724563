import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import user from "./user_slice";
import newuser from "./newuser_slice";
import customAnalytics from "./customanaytics_slice";
import customAnalyticsFilter from "./customAnayticsFilter_slice";
import stagedUsers from "./stagedusers_slice";
import seasonstatsData from "./reducers/seasonstatsData";
import rosterData from "./reducers/rosterData";
import recruiterlistData from "./reducers/recruiterlistData"
import recruitersearchData from "./reducers/recruitersearchData"
import gamestatsData from "./reducers/gamestatsData";
import gamePlaysData from "./reducers/gamePlaysData";
import messageData from "./reducers/messageData";
import playerstatsData from "./reducers/playerstatsData";
import teamstatsData from "./reducers/teamstatsData";
import gamesData from "./reducers/gamesData";
import quicksearchData from "./reducers/quicksearchData";
import nongamestatsData from "./reducers/nongamestatsData";
import depthChartData from "./reducers/depthChartData";
import recruiterPlayerNotesData from "./reducers/recruiterPlayerNotesData";
import playerProfileData from "./reducers/playerProfileData";
import recruiterPlayerPipelineHistoryData from "./reducers/recruiterPlayerPipelineHistoryData";
import seasonsData from "./season_slice";
import statesData from "./reducers/statesData";
import prospectListData from "./prospect_slice";
import referralData from "./referral_slice";
import schoolProfilesData from "./reducers/schoolProfilesData";
import {RESET_ALL} from "./actions/appActions";
import scoutingTeams from './scoutingteams_slice';

const appReducer = combineReducers({
    user,
    newuser,
    stagedUsers,
    seasonstatsData,
    rosterData,
    depthChartData,
    recruiterlistData,
    recruitersearchData,
    gamestatsData,
    messageData,
    playerstatsData,
    playerProfileData,
    teamstatsData,
    gamesData,
    gamePlaysData,
    quicksearchData,
    nongamestatsData,
    recruiterPlayerNotesData,
    recruiterPlayerPipelineHistoryData,
    seasonsData,
    statesData,
    prospectListData,
    referralData,
    schoolProfilesData,
    customAnalytics,
    customAnalyticsFilter,
    scoutingTeams,
});

const rootReducer = (state, action) => {
    if (action.type === RESET_ALL) {
        state = undefined
    }

    return appReducer(state, action);
}

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});

export const dispatch = store.dispatch;
export default store;
