import {Button, Form, Input, InputNumber, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {
    createSearch,
    getSearch,
    getSearchById,
    getSearches,
    updateSearch
} from "../../../redux/actions/recruitersearchActions";
import {getSeasonstatsByFilter} from "../../../redux/actions/seasonstatsActions";
import {useDispatch, useSelector} from "react-redux";
import {selectSearchByID, selectSearchLoading} from "../../../redux/selectors/recruitersearchSelectors";
import {SeasonStatisticSelect} from "../../../shared-components/seasonStatisticSelect";
import {useLocation, useNavigate} from "@reach/router";
import * as queryString from "query-string";
import {statTableForStat, STATES} from "../../../shared-components/categories_fb";
import {FBPositionSelect} from "../../../shared-components/FBSelects";

const {Option} = Select;

const BuildSearchForm = ({searchId}) => {
    const location = useLocation();
    const editMode = queryString.parse(location.search).editMode === 'true';

    return (
        <BuildSearch searchId={searchId} editMode={editMode}/>
    )
};

const HeightSelect = (props) => {
    return (
        <Select {...props} placeholder={'Select height'} style={{minWidth: 150}}>
            <Option value={'60'}>5' 0"</Option>
            <Option value={'63'}>5' 3"</Option>
            <Option value={'66'}>5' 6"</Option>
            <Option value={'69'}>5' 9"</Option>
            <Option value={'72'}>6' 0"</Option>
            <Option value={'75'}>6' 3"</Option>
            <Option value={'78'}>6' 6"</Option>
            <Option value={'81'}>6' 9"</Option>
            <Option value={'84'}>7' 0"</Option>
        </Select>
    )
};

const StateSelect = (props) => {
    return (
        <Select {...props} placeholder={'Select state'} style={{minWidth: 50}}>
            {STATES.map(s => <Option value={s.st}>{s.state}</Option>)}
        </Select>
    );
};

const WhichInput = ({fieldName, ...props}) => {
    if (fieldName === 'height')
        return <HeightSelect {...props} />
    else if (fieldName === 'position')
        return <FBPositionSelect {...props} />
    else if (fieldName === 'state')
        return <StateSelect {...props} />
    else
        return <InputNumber {...props} style={{minWidth: 150}} />
};

const CriteriaRow = ({field, remove, initFieldName}) => {
    // need to manage fieldName in state for when user changes it:
    const [fieldName, setFieldName] = useState(initFieldName);

    return (
            <Space key={field.key} style={{display: 'flex', marginBottom: 4}} align="start">
                <Form.Item
                    {...field}
                    name={[field.name, 'criteriaColumn']}
                    fieldKey={[field.fieldKey, 'criteriaColumn']}
                    rules={[{required: true, message: 'Stat required'}]}
                >
                    <SeasonStatisticSelect
                        style={{width: '210px'}}
                        onChange={setFieldName}
                        listHeight={512}
                    />
                </Form.Item>
                <Form.Item
                    {...field}
                    name={[field.name, 'comparator']}
                    fieldKey={[field.fieldKey, 'comparator']}
                >
                    <Select
                        placeholder="Select comparator"
                        style={{width: '150px'}}
                        allowClear
                    >
                        <Option value=">">greater than</Option>
                        <Option value=">=">greater/equal</Option>
                        <Option value="=">equal to</Option>
                        <Option value="<">less than</Option>
                        <Option value="<=">less/equal</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    {...field}
                    name={[field.name, 'criteriaValue']}
                    fieldKey={[field.fieldKey, 'criteriaValue']}
                >
                    <WhichInput fieldName={fieldName || initFieldName} />
                </Form.Item>

                <MinusCircleOutlined
                    onClick={() => {
                        remove(field.name);
                    }}
                />
            </Space>
        );
};

export const BuildSearch = ({searchId, editMode}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const search = useSelector(selectSearchByID(searchId));
    const loading = useSelector(selectSearchLoading);

    const [form] = Form.useForm();

    useEffect(() => {
        if (searchId && searchId !== 'new' && !loading && !search) {
            dispatch(getSearchById(searchId));
        }

        if (search) {
            form.setFieldsValue(search);
        }
        else {
            form.setFieldsValue({searchName: null, criteria: [{}]});
        }
    });

    const dispatch = useDispatch();

    const toggleEditMode = () => {
        const queryParams = queryString.parse(location.search);
        navigate(location.pathname + '?' + queryString.stringify({
            ...queryParams,
            editMode: editMode ? 'false' : 'true'
        }));
    };

    const handleSubmit = (values) => {
        // add criteriaTable:
        values.criteria = values.criteria.map(c => {
            return {...c, criteriaTable: statTableForStat(c.criteriaColumn)}
        });

        if (searchId === 'new')
            dispatch(createSearch(values, [getSeasonstatsByFilter, getSearch, getSearches]));
        else
            dispatch(updateSearch(searchId, values, [getSeasonstatsByFilter, getSearch, getSearches]));
    };

    if (editMode) {
        return (
            <Form name="search" form={form} autoComplete="off" onFinish={handleSubmit}>
                <Form.Item
                    name="searchName"
                    label="Search Name"
                    rules={[
                        {required: true,},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.List name="criteria">
                    {(fields, {add, remove}) => {
                        return (
                            <div>
                                {fields.map(field => (
                                    <CriteriaRow
                                        form={form}
                                        key={field.key}
                                        field={field}
                                        remove={remove}
                                        // pass form field value to initFieldName for the row:
                                        initFieldName={form.getFieldValue(['criteria', field.name, 'criteriaColumn'])}
                                    />
                                    ))}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add();
                                        }}
                                        block
                                    >
                                        <PlusOutlined/> Add Criteria
                                    </Button>
                                </Form.Item>
                            </div>
                        );
                    }}
                </Form.List>

                <Form.Item>
                    <Space direction={"horizontal"}>
                        <Button type="primary" htmlType="submit">
                            Save & Refresh
                        </Button>
                        <Button type="primary" onClick={toggleEditMode}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        );
    } else {
        return (
            <Button type="primary" onClick={toggleEditMode}>Modify Criteria</Button>
        );
    }
};

export default BuildSearchForm;
