export const nongamestats = [
    { stat: 'forty_yd_dash', label: '40-Yard Dash' },
    { stat: 'bench_press', label: 'Bench Press' },
    { stat: 'squat', label: 'Squat' },
    { stat: 'vertical', label: 'Vertical' },
    { stat: 'power_clean', label: 'Power Clean' },
    { stat: 'broad_jump', label: 'Broad Jump' },
    { stat: 'pro_agility', label: 'Pro Agility' },
    { stat: 'sixty_yd_shuttle', label: '60-Yard Shuttle' },
    { stat: 'twenty_yd_shuttle', label: '20-Yard Shuttle' },
    { stat: 'three_cone_drill', label: '3-cone Drill' },
];

const statGroupings = {
    passing: {
        name: 'Passing',
        group: 'offense',
        positions: [ 'QB' ],
        statPrefix: 'Passing_',
        stats: [ 'CMP', 'ATT', 'YDS', 'CMP_pct', 'AVG', 'LNG', 'TD', 'INT', 'SACK', 'SYL', 'RTG' ],
        statTable: 'season_stats_fb',
        keyStats: ['ATT'],
        statLabels: [
            { stat: 'Passing_CMP', label: 'Passing Completions' },
            { stat: 'Passing_ATT', label: 'Passing Attempts' },
            { stat: 'Passing_YDS', label: 'Passing Yards' },
            { stat: 'Passing_CMP_pct', label: 'Passing Completion %' },
            { stat: 'Passing_AVG', label: 'Passing Average' },
            { stat: 'Passing_LNG', label: 'Passing Longest' },
            { stat: 'Passing_TD', label: 'Passing Touchdowns' },
            { stat: 'Passing_INT', label: 'Interceptions Thrown' },
            { stat: 'Passing_SACK', label: 'QB Sacks' },
            { stat: 'Passing_SYL', label: 'Yards Lost On QB Sacks' },
            { stat: 'Passing_RTG', label: 'Passer Rating' },
        ],
    },
    rushing: {
        name: 'Rushing',
        group: 'offense',
        positions: [ 'HB', 'FB' ],
        statPrefix: 'Rushing_',
        stats: ['ATT', 'YDS', 'AVG', 'LNG', 'TD' ],
        statTable: 'season_stats_fb',
        keyStats: ['ATT'],
        statLabels: [
            { stat: 'Rushing_ATT', label: 'Rushing Attempts' },
            { stat: 'Rushing_YDS', label: 'Rushing Yards' },
            { stat: 'Rushing_AVG', label: 'Rushing Average' },
            { stat: 'Rushing_LNG', label: 'Rushing Longest' },
            { stat: 'Rushing_TD', label: 'Rushing Touchdowns' },
        ],
    },
    receiving: {
        name: 'Receiving',
        group: 'offense',
        positions: [ 'WR', 'TE', 'OL' ],
        statPrefix: 'Receiving_',
        stats: [ 'REC', 'YDS', 'AVG', 'LNG', 'TD' ],
        statTable: 'season_stats_fb',
        keyStats: ['REC'],
        statLabels: [
            { stat: 'Receiving_REC', label: 'Receptions' },
            { stat: 'Receiving_YDS', label: 'Receiving Yards' },
            { stat: 'Receiving_AVG', label: 'Receiving Average' },
            { stat: 'Receiving_LNG', label: 'Receiving Longest' },
            { stat: 'Receiving_TD', label: 'Receiving Touchdowns' },
        ],
    },
    kicking: {
        name: 'Kicking',
        group: 'specialTeams',
        positions: [ 'PK', 'P', 'K', 'LS'],
        statPrefix: 'Kicking_',
        stats: [ 'XPM', 'XPA', 'XP_pct', 'FGM', 'FGA', 'FG_pct', 'FGM_1to19', 'FGA_1to19', 'FGM_20to29', 'FGA_20to29', 'FGM_30to39', 'FGA_30to39', 'FGM_40to49', 'FGA_40to49', 'FGM_50plus', 'FGA_50plus', 'LNG', 'PTS' ],
        statTable: 'season_stats_fb',
        keyStats: ['XPA', 'FGA'],
        statLabels: [
            { stat: 'Kicking_XPM', label: 'Extra Points Made' },
            { stat: 'Kicking_XPA', label: 'Extra Points Attempted' },
            { stat: 'Kicking_XP_pct', label: '% Extra Points Made' },
            { stat: 'Kicking_FGM', label: 'Field Goals Made' },
            { stat: 'Kicking_FGA', label: 'Field Goals Attempted' },
            { stat: 'Kicking_FG_pct', label: '% Field Goals Made' },
            { stat: 'Kicking_FGM_1to19', label: 'Field Goals 1 to 19 yds' },
            { stat: 'Kicking_FGM_20to29', label: 'Field Goals 20 to 29 yds' },
            { stat: 'Kicking_FGM_30to39', label: 'Field Goals 30 to 39 yds' },
            { stat: 'Kicking_FGM_40to49', label: 'Field Goals 40 to 49 yds' },
            { stat: 'Kicking_FGM_50plus', label: 'Field Goals 50+ yds' },
            { stat: 'Kicking_LNG', label: 'Kicking Longest' },
            { stat: 'Kicking_PTS', label: 'Kicking Points' },
        ],
    },
    defense: {
        name: 'Defense',
        group: 'defense',
        positions: ['CB', 'DB', 'DE', 'DL', 'DT', 'LB', 'NT', 'S' ],
        statPrefix: 'Defense_',
        stats: [ 'SOLO', 'AST', 'TFL', 'TOT', 'SACK', 'SCKYDS', 'PD', 'INT', 'YDS', 'LNG', 'TD', 'FF', 'FR', 'FTD' ],
        statTable: 'season_stats_fb',
        keyStats: ['TOT'],
        statLabels: [
            { stat: 'Defense_SOLO', label: 'Solo Tackles' },
            { stat: 'Defense_AST', label: 'Defense Assisted Tackles' },
            { stat: 'Defense_TOT', label: 'Total Tackles' },
            { stat: 'Defense_SACK', label: 'Defensive Sacks' },
            { stat: 'Defense_SCKYDS', label: 'Defensive Yards from Sacks' },
            { stat: 'Defense_PD', label: 'Pass Deflections' },
            { stat: 'Defense_INT', label: 'Interceptions' },
            { stat: 'Defense_YDS', label: 'Defensive Yards' },
            { stat: 'Defense_TD', label: 'Defensive Touchdowns' },
            { stat: 'Defense_FF', label: 'Forced Fumbles' },
            { stat: 'Defense_FR', label: 'Fumbles Recovered' },
            { stat: 'Defense_FTD', label: 'Fumbles for Touchdowns' },
        ],
    },
    nonGameStats: {
        name: 'Non-game Stats',
        group: 'nonGameStats',
        noLeaders: true,
        statPrefix: '',
        stats: [ 'forty_yd_dash', 'bench_press', 'squat', 'vertical', 'power_clean', 'broad_jump', 'pro_agility', 'sixty_yd_shuttle', 'twenty_yd_shuttle', 'three_cone_drill'],
        statTable: 'best_non_game_stats_fb',
        keyStats: ['forty_yd_dash'],
        statLabels: nongamestats,
    },
    playerAttributes: {
        name: 'Player Attributes',
        group: 'playerAttributes',
        noLeaders: true,
        statPrefix: '',
        stats: ['height', 'weight', 'wingspan', 'graduationYear'],
        statTable: 'last_non_game_stats',
        keyStats: ['weight'],
        statLabels: [
            { stat: 'height', label: 'Height' },
            { stat: 'weight', label: 'Weight' },
            { stat: 'wingspan', label: 'Wingspan' },
            { stat: 'graduationYear', label: 'Grad Year', statTable: 'players' },
            { stat: 'state', label: 'State', statTable: 'schools' },
            { stat: 'position', label: 'Position', statTable: 'season_stats_fb' },
        ],
    }
};

export const DEPTH_CHART_LEVELS = {
    defense: ['Defensive Back', 'Linebackers', 'Defensive Line'],
    offense: ['Offensive Line', 'QB / HB / FB', 'Receivers'],
    specialTeams: ['P / K / LS'],
};

export const COLOR_BY_POSITION_TYPE = [
    ///changing colors to make default color scheme
    {type: 'defense', positions: ['CB', 'DB', 'DE', 'DL', 'DT', 'LB', 'NT', 'S'], color: 'white'},
    {type: 'offense', positions: ['WR', 'TE', 'OL', 'HB', 'FB', 'QB'], color: '#C8D5EA'},
    {type: 'specialTeams', positions: ['PK', 'P', 'K', 'LS'], color: '#E4EAF5'},
];

export default statGroupings;

export const STATES = [
    { state: 'Alabama', st: 'AL' },
    { state: 'Alaska', st: 'AK' },
    { state: 'Arizona', st: 'AZ' },
    { state: 'Arkansas', st: 'AR' },
    { state: 'California', st: 'CA' },
    { state: 'Colorado', st: 'CO' },
    { state: 'Connecticut', st: 'CT' },
    { state: 'Delaware', st: 'DE' },
    { state: 'Florida', st: 'FL' },
    { state: 'Georgia', st: 'GA' },
    { state: 'Hawaii', st: 'HI' },
    { state: 'Idaho', st: 'ID' },
    { state: 'Illinois', st: 'IL' },
    { state: 'Indiana', st: 'IN' },
    { state: 'Iowa', st: 'IA' },
    { state: 'Kansas', st: 'KS' },
    { state: 'Kentucky', st: 'KY' },
    { state: 'Louisiana', st: 'LA' },
    { state: 'Maine', st: 'ME' },
    { state: 'Maryland', st: 'MD' },
    { state: 'Massachusetts', st: 'MA' },
    { state: 'Michigan', st: 'MI' },
    { state: 'Minnesota', st: 'MN' },
    { state: 'Mississippi', st: 'MS' },
    { state: 'Missouri', st: 'MO' },
    { state: 'Montana', st: 'MT' },
    { state: 'Nebraska', st: 'NE' },
    { state: 'Nevada', st: 'NV' },
    { state: 'New Hampshire', st: 'NH' },
    { state: 'New Jersey', st: 'NJ' },
    { state: 'New Mexico', st: 'NM' },
    { state: 'New York', st: 'NY' },
    { state: 'North Carolina', st: 'NC' },
    { state: 'North Dakota', st: 'ND' },
    { state: 'Ohio', st: 'OH' },
    { state: 'Oklahoma', st: 'OK' },
    { state: 'Oregon', st: 'OR' },
    { state: 'Pennsylvania', st: 'PA' },
    { state: 'Rhode Island', st: 'RI' },
    { state: 'South Carolina', st: 'SC' },
    { state: 'South Dakota', st: 'SD' },
    { state: 'Tennessee', st: 'TN' },
    { state: 'Texas', st: 'TX' },
    { state: 'Utah', st: 'UT' },
    { state: 'Vermont', st: 'VT' },
    { state: 'Virginia', st: 'VA' },
    { state: 'Washington', st: 'WA' },
    { state: 'West Virginia', st: 'WV' },
    { state: 'Wisconsin', st: 'WI' },
    { state: 'Wyoming', st: 'WY' },
];

export const positionsList = Object.keys(statGroupings)
    .filter(k => !!statGroupings[k].positions)
    .map(k => statGroupings[k].positions)
    .reduce((a, v) => a.concat(v), []);

export const otherLabels = [
    { stat: 'playerName', label: 'Player Name'},
    { stat: 'schoolName', label: 'School Name'},
    //{ stat: 'position', label: 'Postion'},
    //{ stat: 'graduationYear', label: 'Grad Year' },
];

export const allStatLabels = Object.values(statGroupings).flatMap(g => g.statLabels)
    .concat(otherLabels);

export function lookupStat(stat) {
    return allStatLabels.find(s => s.stat === stat);
}
export function lookupStatLabel(stat) {
    const statLabel = lookupStat(stat);
    return statLabel ? statLabel.label : stat;
}

export const statTableForStat = (stat) => {
    const statLabel = lookupStat(stat);
    if (statLabel && statLabel.statTable) {
        return statLabel.statTable;
    }
    else {
        const statGrouping = Object.values(statGroupings)
            .find(sg => sg.stats.map(s => sg.statPrefix + s).includes(stat));

        return statGrouping.statTable;
    }
};
