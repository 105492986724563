import React from "react";
import {parseQueryString} from "../../shared-components/queryStringUtils";
import RosterTabs from "./components/RosterTabs";
import {PlayerPositionGroupTabs} from "./components/playerPositionGroupTabs";

const Depth = ({testRosterId, ...props}) => {
    const rosterId = testRosterId ? testRosterId : parseQueryString(props.location.search, 'rosterId')['rosterId'];

    return (
        <div className="center">
            <h1>
                Depth Management
            </h1>

            <RosterTabs rosterId={rosterId} hideScouting={true} />
            <PlayerPositionGroupTabs rosterId={rosterId}/>
        </div>
    );
};

export default Depth;
