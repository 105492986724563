import React from "react";
import {PositionCardsForPosition} from "./positionCardsForPosition";
import {DEPTH_CHART_LEVELS} from "../../../shared-components/categories_fb";
import {Button, Divider, message, Popconfirm} from "antd";
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDepthChartPlayerPositionsByLevel, selectDepthChartPlayersGroupedByPosition,
} from "../../../redux/selectors/depthChartSelectors";
import {updateDepthChartPlayerDataByPosition} from "../../../redux/actions/depthChartActions";

export const PositionCardsForPositionGroups = ({chartId, positionGroup, readOnly = false, rosterId}) => {
    const dispatch = useDispatch();
    const levels = DEPTH_CHART_LEVELS[positionGroup];
    let playerPositionsByLevel = useSelector(selectDepthChartPlayerPositionsByLevel());
    const activeDepthByChartId = useSelector(selectDepthChartPlayersGroupedByPosition);

    function buildPositionCardRow(level) {
        return playerPositionsByLevel[level] ? playerPositionsByLevel[level].map(p => <PositionCardsForPosition chartId={chartId} level={level} key={p} position={p} readOnly={readOnly} rosterId={rosterId}/>) : null;
    }

    const addPositionToLevel = (level) => {
        if(playerPositionsByLevel[level] && playerPositionsByLevel[level].indexOf("New Position") !== -1) {
            message.error(`There is already an unnamed 'New Position'. Position must be named before another new position can be added at this level.`, 4);
            return;
        }

        let positionCount = 0;
        Object.entries(activeDepthByChartId).forEach(([k, v], index) => {
            if(levels.includes(k)) {
                positionCount = positionCount + Object.entries(v).length;
            }
        });
        if(positionCount < 11) {
            dispatch(updateDepthChartPlayerDataByPosition('addPosition', level, 'New Position'));
        } else {
            message.error(`There are already 11 positions created for ${level}`, 3);
        }
    };

    return (playerPositionsByLevel ?
        levels.map(level => {
                return (<div key={level}>
                    <Divider orientation="left">
                        {level}&nbsp;&nbsp;
                        {!readOnly ?
                            <Popconfirm
                                placement="topRight"
                                title={`Position will not remain in chart unless a player is assigned.`}
                                onConfirm={() => addPositionToLevel(level)}
                                okText="Understood"
                                cancelText="No"
                            >
                                <Button icon={<PlusCircleOutlined />}>Add Position</Button>
                            </Popconfirm> :
                            null}
                    </Divider>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>{buildPositionCardRow(level)}</div>
                </div>)
        }) :
        null);
};
