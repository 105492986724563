import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectGotLists, selectLoading, selectPlayerPipelineLists} from "../../redux/selectors/recruiterlistSelectors";
import {Button, Input, Layout, message, Modal, Space, Tabs} from "antd";
import {createList, deleteList, getLists, updateList, updateListOrder} from "../../redux/actions/recruiterlistActions";
import {DeleteOutlined, EditOutlined, UserSwitchOutlined} from "@ant-design/icons";
import SavedList from "./SavedList";
import {SavedListsSelect} from "../../shared-components/SavedListsSelect";
import {getSeasonstatsByFilter} from "../../redux/actions/seasonstatsActions";
import {getQuicksearchByQuerystring} from "../../redux/actions/quicksearchActions";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import '../../css/player_pipeline.css';
import {useLocation, useNavigate} from "@reach/router";
import {parseQueryString} from "../../shared-components/queryStringUtils";
import * as queryString from "query-string";

const {TabPane} = Tabs;

const PlayerPipeline = () => {
    const listData = useSelector(selectPlayerPipelineLists);
    const gotLists = useSelector(selectGotLists);
    const loading = useSelector(selectLoading);
    const [movePlayersModalVisible, setMovePlayersModalVisible] = useState(false);
    const hideMovePlayersModal = () => setMovePlayersModalVisible(false);
    const [listNameModalListId, setListNameModalListId] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const hideListNameModal = () => setListNameModalListId(null);
    const [showDeleteListConfirm, setShowDeleteListConfirm] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {listId} = parseQueryString(location.search, 'listId');

    const onChangeListId = (listId) => {
        const queryParams = queryString.parse(location.search);
        delete queryParams.listId;

        if (listId)
            navigate(location.pathname + '?' + queryString.stringify({...queryParams, listId}));
        else
            navigate(location.pathname + '?' + queryString.stringify(queryParams));
    };

    useEffect(() => {
        if (!gotLists && !loading)
            dispatch(getLists());

        if (!listId && listData && listData[0])
            onChangeListId(listData[0].listId);
    });

    //this should be refactored some day. 1. pull modal into it's own file and 2. maybe don't use the same modal for add, reorder and rename. Sorry. :(
    const ListName = ({isVisible, onHandle}) => {
        const editNameInput = React.createRef();
        const [listDataTemp, setListDataTemp] = useState(listData);
        const okCancel = (ok) => () => {
            if(ok) {
                if(listNameModalListId === 'ALL') {
                    setListOrder();
                } else if(listNameModalListId === 'NEW') {
                    saveListName();
                } else {
                    saveNewName();
                }
            }

            onHandle();
        };

        const saveListName = () => {
            const listName = editNameInput.current.input.value;
            if (listName !== undefined){
                dispatch(createList({listName, playerIds: [], isPlayerPipelineList: 1}));
            }
        };

        const saveNewName = () => {
            const listName = editNameInput.current.input.value;
            try {
                dispatch(updateList(listNameModalListId, {listName: listName}, getLists));
                message.success('List name updated!', 2);
            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
        };

        const setListOrder = () => {
            dispatch(updateListOrder({lists: listDataTemp}));
        };

        const onSortEnd = ({oldIndex, newIndex}) => {
            setListDataTemp(arrayMove(listDataTemp, oldIndex, newIndex));
        };

        const SortableItem = SortableElement(({value}) =>
            <span className="sortableHelper">{value['listName']}</span>
        );

        const SortableList = SortableContainer(({items, readOnly = false}) => {
            return (
                <ul className="sortableList">
                    {items.map((value, index) => (
                        <p key={index}><SortableItem key={`item-${index}`} index={index} value={value} /></p>
                    ))}
                </ul>
            );
        });

        return (
            <Modal
                title="List name"
                visible={isVisible}
                onOk={okCancel(true)}
                onCancel={okCancel(false)}
                destroyOnClose={true}
            >
                {listNameModalListId !== 'ALL' ?
                    <Input
                        ref={editNameInput}
                        placeholder={'List Name'}
                        autoFocus
                        onPressEnter={okCancel(true)}
                    /> :
                    null}
                {listNameModalListId === 'ALL' ? <SortableList items={listDataTemp} onSortEnd={onSortEnd}/> : null}
            </Modal>
        );
    };

    const MovePlayersModal = ({isVisible, onHandle}) => {
        const [savedLists, setSavedLists] = useState([]);
        const okCancel = (ok) => () => {
            if (ok && selectedRowKeys.length > 0){
                savedLists.forEach(v => {
                    const addedFromList = listData.find(list => list.listId === listId);
                    dispatch(updateList(v, {additions: selectedRowKeys, addedFrom: addedFromList.listName, addedFromDetail: 'pipeline move'}, getSeasonstatsByFilter));
                });
                selectedRowKeys.forEach(playerId => {
                    dispatch(updateList(listId, {deletions: [playerId]}, getQuicksearchByQuerystring));
                });
                setSelectedRowKeys([]);
            }

            onHandle();
        };

        return (
            <Modal
                title="Move lists"
                visible={isVisible}
                onOk={okCancel(true)}
                onCancel={okCancel(false)}
                destroyOnClose={true}
            >
                <SavedListsSelect
                    onChange={setSavedLists}
                    width={200}
                    mode='multiple'
                    onlyShowPipelineLists={true}
                />
            </Modal>
        );
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (srk) => {
            setSelectedRowKeys(srk);
        },
    };

    const removeList = (listId) => {
        const listName = listData.find(list => list.listId === listId).listName;
        dispatch(deleteList(listId));
        onChangeListId(null);
        message.success(`List "${listName}" successfully deleted!`);
    };

    const DeleteList = ({listId, isVisible, onHandle}) => {

        const confirm = (ok) => {
            if (ok)
                removeList(listId);
            onHandle();
        }

        return(
            <Modal
                title="Delete List"
                visible={isVisible}
                onOk={() => confirm(true)}
                onCancel={() => confirm(false)}
                destroyOnClose={true}
            >
                <p>Are you sure you want to delete this list?</p>
                <p>This cannot be undone.</p>
            </Modal>
        )
    }

    return (
        <Layout>
            <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                <h1>Player Pipeline</h1>
                <Space>
                    <Button type={"primary"} onClick={() => setListNameModalListId('NEW')}>
                        New Pipeline List
                    </Button>
                    <Button type={"primary"} onClick={() => setListNameModalListId('ALL')}>
                        Reorder Lists
                    </Button>
                </Space>
            </Layout.Content>
            <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                <Tabs size={'large'} onChange={onChangeListId} activeKey={listId}>
                    {listData.map(list =>
                        <TabPane key={list.listId} value={list.listName} tab={list.listName}>
                            <Layout>
                                <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                                    <Space>
                                        <Button icon={<EditOutlined />} onClick={() => setListNameModalListId(list.listId)} >Rename List</Button>
                                        <Button icon={<DeleteOutlined />} onClick={() => setShowDeleteListConfirm(true)} >Delete List</Button>
                                        {selectedRowKeys.length > 0 ? <Button icon={<UserSwitchOutlined />} onClick={() => setMovePlayersModalVisible(true)} >Move Players</Button> : null}
                                    </Space>
                                </Layout.Content>
                                <Layout.Content>
                                    {
                                        listId === list.listId ?
                                        <SavedList listId={listId} rowSelection={rowSelection} isPipelineList={true}/> :
                                        null
                                    }
                                </Layout.Content>
                                <MovePlayersModal isVisible={movePlayersModalVisible} onHandle={hideMovePlayersModal} />
                            </Layout>
                        </TabPane>)}
                </Tabs>
            </Layout.Content>
            <ListName isVisible={listNameModalListId !== null} onHandle={hideListNameModal}/>
            <DeleteList listId={listId} isVisible={showDeleteListConfirm} onHandle={() => setShowDeleteListConfirm(false)} />
        </Layout>
    );
};

export default PlayerPipeline;
