import {API} from "aws-amplify";

export const GET_DEPTH_CHART_PLAYERS_STARTED = 'GET_DEPTH_CHART_PLAYERS_STARTED';
export const GET_DEPTH_CHART_PLAYERS_SUCCESS = 'GET_DEPTH_CHART_PLAYERS_SUCCESS';
export const GET_DEPTH_CHART_PLAYERS_FAILURE = 'GET_DEPTH_CHART_PLAYERS_FAILURE';

export const PUT_DEPTH_CHART_PLAYERS_STARTED = 'PUT_DEPTH_CHART_PLAYERS_STARTED';
export const PUT_DEPTH_CHART_PLAYERS_SUCCESS = 'PUT_DEPTH_CHART_PLAYERS_SUCCESS';
export const PUT_DEPTH_CHART_PLAYERS_FAILURE = 'PUT_DEPTH_CHART_PLAYERS_FAILURE';

export const CREATE_DEPTH_CHART_PLAYERS_SUCCESS = 'CREATE_DEPTH_CHART_PLAYERS_SUCCESS';
export const CREATE_DEPTH_CHART_PLAYERS_STARTED = 'CREATE_DEPTH_CHART_PLAYERS_STARTED';
export const CREATE_DEPTH_CHART_PLAYERS_FAILURE = 'CREATE_DEPTH_CHART_PLAYERS_FAILURE';

export const UPDATE_DEPTH_CHART_PLAYERS_BY_POSITION = 'UPDATE_DEPTH_CHART_PLAYERS_BY_POSITION';
export const RESET_DEPTH_CHART_PLAYERS_BY_POSITION = 'RESET_DEPTH_CHART_PLAYERS_BY_POSITION';

export function getDepthChartPlayersRosterIdAndChartId(params) {
    console.log('getting depth chart players for params: ', params);
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    return async (dispatch) => {
        try {
            dispatch(getDepthChartPlayersStarted(queryString));
            const players = await API.get('coachAPI', `depthchartplayers?${queryString}`);
            console.log('got Depth Chart Players for:', queryString);
            dispatch(getDepthChartPlayersSuccess(queryString, players));
        } catch (e) {
            console.log('problem getting depth chart players:', e);
            dispatch(getDepthChartPlayersFailure(e));
        }
    }
}

const getDepthChartPlayersSuccess = (queryString, data = []) => ({
    type: GET_DEPTH_CHART_PLAYERS_SUCCESS,
    payload: {
        depthChartPlayers: [...data],
    }
});

const getDepthChartPlayersStarted = (rosterId) => ({
    type: GET_DEPTH_CHART_PLAYERS_STARTED,
    rosterId,
});

const getDepthChartPlayersFailure = error => ({
    type: GET_DEPTH_CHART_PLAYERS_FAILURE,
    payload: {
        error
    }
});

export function putDepthChartPlayers(players, chartId, dispatchOnSuccess = null) {
    return async (dispatch) => {
        try {
            dispatch(putDepthChartPlayersStarted(players));
            const res = await API.put('coachAPI', `depthchartplayers`, {
                body: {
                    chartId: chartId,
                    depthChartPlayers: players
                }
            });
            if (dispatchOnSuccess)
                dispatch(() => dispatchOnSuccess());
            console.log('put depthChartPlayers for:', players);
            dispatch(putDepthChartPlayersSuccess(res));
        } catch (e) {
            console.log('problem putting depthChartPlayers:', e);
            dispatch(putDepthChartPlayersFailure(e.message));
        }
    }
}

const putDepthChartPlayersSuccess = resp => ({
    type: PUT_DEPTH_CHART_PLAYERS_SUCCESS,
    payload: {...resp}
});

const putDepthChartPlayersStarted = (players) => ({
    type: PUT_DEPTH_CHART_PLAYERS_STARTED,
    payload: { depthChartPlayers: players},
});

const putDepthChartPlayersFailure = error => ({
    type: PUT_DEPTH_CHART_PLAYERS_FAILURE,
    payload: {
        error
    }
});

export function createDepthChartForGameIdAndRoster(depthChartData, dispatchOnSuccess = null) {
    console.log('creating depth chart', depthChartData);
    return async (dispatch) => {
        try {
            dispatch(createDepthChartStarted());
            const depthCharts = await API.post('coachAPI', `depthcharts`, {
                body: depthChartData
            });
            if (dispatchOnSuccess)
                dispatch(() => dispatchOnSuccess(depthCharts.chartId));
            dispatch(createDepthChartSuccess(depthChartData.players, depthCharts))
        } catch (e) {
            dispatch(createDepthChartFailure(e));
        }
    }
}

const createDepthChartSuccess = (depthChartPlayers, data = {}) => ({
    type: CREATE_DEPTH_CHART_PLAYERS_SUCCESS,
    payload: { depthChartPlayers, data }
});

const createDepthChartStarted = () => ({
    type: CREATE_DEPTH_CHART_PLAYERS_STARTED,
});

const createDepthChartFailure = error => ({
    type: CREATE_DEPTH_CHART_PLAYERS_FAILURE,
    payload: { error },
});

export const updateDepthChartPlayerDataByPosition = (type, level, position, depthChartPlayers = null, newPostionName = null) => ({
    type: UPDATE_DEPTH_CHART_PLAYERS_BY_POSITION,
    payload: {type, depthChartPlayers, position, level, newPostionName},
});
