import {
    GET_TEAM_GAMESTATS_FAILURE,
    GET_TEAM_GAMESTATS_STARTED,
    GET_TEAM_GAMESTATS_SUCCESS,
    GET_TEAM_GAMEOFFEFF_FAILURE,
    GET_TEAM_GAMEOFFEFF_STARTED,
    GET_TEAM_GAMEOFFEFF_SUCCESS,
    GET_TEAM_SEASONSTATS_FAILURE,
    GET_TEAM_SEASONSTATS_STARTED,
    GET_TEAM_SEASONSTATS_SUCCESS,
} from '../actions/teamstatsActions';

const initialState = {
    loading: false,
    syncing: false,
    error: null,
    rosterIds: [],
    gameIds: [],
    gamedataByRosterId: {},
    seasondataByRosterId: {},
    gameoffeffdataByGameId: {},
};

export default function gamestatsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TEAM_GAMESTATS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_TEAM_GAMESTATS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                rosterIds: state.rosterIds.includes(action.payload.rosterId) ? state.rosterIds : [...state.rosterIds, action.payload.rosterId],
                gamedataByRosterId: { ...state.gamedataByRosterId, [action.payload.rosterId]: action.payload.data },
            };
        case GET_TEAM_GAMESTATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case GET_TEAM_GAMEOFFEFF_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_TEAM_GAMEOFFEFF_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                gameIds: state.gameIds.includes(action.payload.gameId) ? state.gameIds : [...state.gameIds, action.payload.gameId],
                gameoffeffdataByGameId: { ...state.gameoffeffdataByGameId, [action.payload.gameId]: action.payload.data },
            };
        case GET_TEAM_GAMEOFFEFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case GET_TEAM_SEASONSTATS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_TEAM_SEASONSTATS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                rosterIds: state.rosterIds.includes(action.payload.rosterId) ? state.rosterIds : [...state.rosterIds, action.payload.rosterId],
                seasondataByRosterId: { ...state.seasondataByRosterId, [action.payload.rosterId]: action.payload.data },
            };
        case GET_TEAM_SEASONSTATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}
