import React from "react";
import {parseQueryString} from "../../shared-components/queryStringUtils";
import RosterTabs from "./components/RosterTabs";
import EditSchedule from "./components/EditSchedule";

const Schedule = ({testRosterId, testSeasonId, ...props}) => {
    const rosterId = testRosterId ? testRosterId : parseQueryString(props.location.search, 'rosterId')['rosterId'];
    const seasonId = testSeasonId ? testSeasonId : parseQueryString(props.location.search)['seasonId'];

    return (
        <div className="center">
            <h1>
                Schedule Management
            </h1>

            <RosterTabs rosterId={rosterId} hideInactive={true} hideScouting={true}/>
            <EditSchedule rosterId={rosterId} seasonId={seasonId}/>
        </div>
    );
};

export default Schedule;
