import {API} from "aws-amplify";

export const GET_TEAM_GAMESTATS_STARTED = 'GET_TEAM_GAMESTATS_STARTED';
export const GET_TEAM_GAMESTATS_SUCCESS = 'GET_TEAM_GAMESTATS_SUCCESS';
export const GET_TEAM_GAMESTATS_FAILURE = 'GET_TEAM_GAMESTATS_FAILURE';

export const GET_TEAM_GAMEOFFEFF_STARTED = 'GET_TEAM_GAMEOFFEFF_STARTED';
export const GET_TEAM_GAMEOFFEFF_SUCCESS = 'GET_TEAM_GAMEOFFEFF_SUCCESS';
export const GET_TEAM_GAMEOFFEFF_FAILURE = 'GET_TEAM_GAMEOFFEFF_FAILURE';

export const GET_TEAM_SEASONSTATS_STARTED = 'GET_TEAM_SEASONSTATS_STARTED';
export const GET_TEAM_SEASONSTATS_SUCCESS = 'GET_TEAM_SEASONSTATS_SUCCESS';
export const GET_TEAM_SEASONSTATS_FAILURE = 'GET_TEAM_SEASONSTATS_FAILURE';

export function getTeamGamestatsByRosterId(rosterId) {
    console.log('getting teamgamestats for: ', rosterId);

    return async (dispatch) => {
        try {
            dispatch(getTeamGamestatsStarted(rosterId));
            const summary = await API.get('coachAPI', `team-game-summary/${rosterId}`);
            console.log('got gamestats for:', rosterId);
            dispatch(getTeamGamestatsSuccess(rosterId, summary));
        } catch (e) {
            console.log('problem getting gamestats:', e);
            dispatch(getTeamGamestatsFailure(e));
        }
    }
}

const getTeamGamestatsSuccess = (rosterId, data = []) => ({
    type: GET_TEAM_GAMESTATS_SUCCESS,
    payload: { rosterId, data }
});

const getTeamGamestatsStarted = (rosterId) => ({
    type: GET_TEAM_GAMESTATS_STARTED,
    payload: rosterId,
});

const getTeamGamestatsFailure = error => ({
    type: GET_TEAM_GAMESTATS_FAILURE,
    payload: {
        error
    }
});

export function getTeamGameOffEff(gameId) {
    console.log('getting teamgameoffeff for: ', gameId);

    return async (dispatch) => {
        try {
            dispatch(getTeamGameOffEffStarted(gameId));
            const teamGameOffEff = await API.get('coachAPI', `team-game-off-eff/${gameId}`);
            console.log('got gamestats for:', gameId);
            dispatch(getTeamGameOffEffSuccess(gameId, teamGameOffEff));
        } catch (e) {
            console.log('problem getting gamestats:', e);
            dispatch(getTeamGameOffEffFailure(e));
        }
    }
}

const getTeamGameOffEffSuccess = (gameId, data = []) => ({
    type: GET_TEAM_GAMEOFFEFF_SUCCESS,
    payload: { gameId, data }
});

const getTeamGameOffEffStarted = (gameId) => ({
    type: GET_TEAM_GAMEOFFEFF_STARTED,
    payload: gameId,
});

const getTeamGameOffEffFailure = error => ({
    type: GET_TEAM_GAMEOFFEFF_FAILURE,
    payload: {
        error
    }
});

export function getTeamSeasonstatsByRosterId(rosterId) {
    console.log('getting teamseasonstats for: ', rosterId);

    return async (dispatch) => {
        try {
            dispatch(getTeamSeasonstatsStarted(rosterId));
            const summary = await API.get('coachAPI', `team-season-summary/${rosterId}`);
            console.log('got seasonstats for:', rosterId);
            dispatch(getTeamSeasonstatsSuccess(rosterId, summary));
        } catch (e) {
            console.log('problem getting seasonstats:', e);
            dispatch(getTeamSeasonstatsFailure(e));
        }
    }
}

const getTeamSeasonstatsSuccess = (rosterId, data = []) => ({
    type: GET_TEAM_SEASONSTATS_SUCCESS,
    payload: { rosterId, data }
});

const getTeamSeasonstatsStarted = (rosterId) => ({
    type: GET_TEAM_SEASONSTATS_STARTED,
    payload: rosterId,
});

const getTeamSeasonstatsFailure = error => ({
    type: GET_TEAM_SEASONSTATS_FAILURE,
    payload: {
        error
    }
});
