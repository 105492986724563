import {API} from "aws-amplify";
import {getTeamGameOffEff, getTeamGamestatsByRosterId} from "./teamstatsActions";

export const GET_GAMES_STARTED = 'GET_GAMES_STARTED';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_FAILURE = 'GET_GAMES_FAILURE';

export const GET_GAMEDATA_STARTED = 'GET_GAMEDATA_STARTED';
export const GET_GAMEDATA_SUCCESS = 'GET_GAMEDATA_SUCCESS';
export const GET_GAMEDATA_FAILURE = 'GET_GAMEDATA_FAILURE';

export const RESET_GAMEDATA_STARTED = 'RESET_GAMEDATA_STARTED';
export const RESET_GAMEDATA_SUCCESS = 'RESET_GAMEDATA_SUCCESS';
export const RESET_GAMEDATA_FAILURE = 'RESET_GAMEDATA_FAILURE';

export const RECALC_GAMESTATS_STARTED = 'RECALC_GAMESTATS_STARTED';
export const RECALC_GAMESTATS_SUCCESS = 'RECALC_GAMESTATS_SUCCESS';
export const RECALC_GAMESTATS_FAILURE = 'RECALC_GAMESTATS_FAILURE';

export const CREATE_GAME_STARTED = 'CREATE_GAME_STARTED';
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS';
export const CREATE_GAME_FAILURE = 'CREATE_GAME_FAILURE';

export const UPDATE_GAME_STARTED = 'UPDATE_GAME_STARTED';
export const UPDATE_GAME_SUCCESS = 'UPDATE_GAME_SUCCESS';
export const UPDATE_GAME_FAILURE = 'UPDATE_GAME_FAILURE';

export const DELETE_GAME_STARTED = 'DELETE_GAME_STARTED';
export const DELETE_GAME_SUCCESS = 'DELETE_GAME_SUCCESS';
export const DELETE_GAME_FAILURE = 'DELETE_GAME_FAILURE';

export function getGamesByRosterId(rosterId, usePlayerApi = false) {
    console.log('getting games for: rosterId', rosterId);

    return async (dispatch) => {
        try {
            dispatch(getGamesStarted(rosterId));
            const games = await API.get(usePlayerApi ? 'playerAPI' : 'coachAPI', `games?rosterId=${rosterId}`);
            if (games) {
                console.log('got games for: rosterId', rosterId);
                return dispatch(getGamesSuccess(rosterId, games));
            }
        } catch (e) {
            console.log('problem getting games:', e);
            return dispatch(getGamesFailure(e));
        }
    }
}

const getGamesSuccess = (rosterId, data = []) => ({
    type: GET_GAMES_SUCCESS,
    payload: { rosterId, data }
});

const getGamesStarted = (rosterId) => ({
    type: GET_GAMES_STARTED,
    rosterId,
});

const getGamesFailure = error => ({
    type: GET_GAMES_FAILURE,
    payload: {
        error
    }
});

export function getGamedataByGameId(gameId) {
    console.log('getting game data for: ', gameId);

    return async (dispatch) => {
        try {
            dispatch(getGamedataStarted(gameId));
            const games = await API.get('coachAPI', `games/${gameId}`);
            console.log('got game data for:', gameId);
            return dispatch(getGamedataSuccess(gameId, games));
        } catch (e) {
            console.log('problem getting game data:', e);
            return dispatch(getGamedataFailure(e));
        }
    }
}

const getGamedataSuccess = (gameId, data = []) => ({
    type: GET_GAMEDATA_SUCCESS,
    payload: { gameId, data }
});

const getGamedataStarted = (gameId) => ({
    type: GET_GAMEDATA_STARTED,
    gameId,
});

const getGamedataFailure = error => ({
    type: GET_GAMEDATA_FAILURE,
    payload: {
        error
    }
});

export function resetGamedataByGameId(gameId) {
    console.log('resetting game data for: ', gameId);

    return async (dispatch) => {
        try {
            dispatch(resetGamedataStarted(gameId));
            const gamePlays = await API.del('coachAPI', `gameplays/${gameId}`);
            console.log('reset game data for:', gameId);
            return dispatch(resetGamedataSuccess(gameId, gamePlays));
        } catch (e) {
            console.log('problem resetting game data:', e);
            return dispatch(resetGamedataFailure(e));
        }
    }
}

const resetGamedataSuccess = (gameId) => ({
    type: RESET_GAMEDATA_SUCCESS,
    payload: { gameId }
});

const resetGamedataStarted = (gameId) => ({
    type: RESET_GAMEDATA_STARTED,
    gameId,
});

const resetGamedataFailure = error => ({
    type: RESET_GAMEDATA_FAILURE,
    payload: {
        error
    }
});


export function recalcGamestatsByGameId(rosterId, gameId) {
    console.log('recalcing game stats for: ', gameId);

    return async (dispatch) => {
        try {
            dispatch(recalcGamestatsStarted(gameId));
            await API.patch('coachAPI', `gameplays/${gameId}`);
            dispatch(getTeamGamestatsByRosterId(rosterId));
            dispatch(getTeamGameOffEff(gameId));
            return dispatch(recalcGamestatsSuccess(gameId));
        } catch (e) {
            console.log('problem recalcing game stats:', e);
            return dispatch(recalcGamestatsFailure(e));
        }
    }
}

const recalcGamestatsSuccess = (gameId) => ({
    type: RECALC_GAMESTATS_SUCCESS,
    payload: { gameId }
});

const recalcGamestatsStarted = (gameId) => ({
    type: RECALC_GAMESTATS_STARTED,
    gameId,
});

const recalcGamestatsFailure = error => ({
    type: RECALC_GAMESTATS_FAILURE,
    payload: {
        error
    }
});

export function createGame(gameData, rosterId) {
    // expects gameData = {teamId, rosterId, opposingTeamId, date, gameType, locationSchoolId}
    // api returns { gameId: ... }
    console.log('creating game', gameData);
    return async (dispatch) => {
        try {
            dispatch(createGameStarted());
            const games = await API.post('coachAPI', `games`, {
                body: gameData
            });
            // refresh games data from server:
            dispatch(getGamesByRosterId(rosterId));
            return dispatch(createGameSuccess(games));
        } catch (e) {
            return dispatch(createGameFailure(e))
        }
    }
}

const createGameSuccess = (gameData) => ({
    type: CREATE_GAME_SUCCESS,
    payload: { gameData }
});

const createGameStarted = () => ({
    type: CREATE_GAME_STARTED,
});

const createGameFailure = error => ({
    type: CREATE_GAME_FAILURE,
    payload: { error },
});

export function updateGame(gameId, gameData, rosterId) {
    // expects gameData = {opposingTeamId, date, gameType, locationSchoolId}
    console.log('updating game', gameId);
    return async (dispatch) => {
        try {
            dispatch(updateGameStarted(gameId, gameData, rosterId));
            await API.put('coachAPI', `games/${gameId}`, {
                body: gameData
            });
            // refresh games data from server:
            dispatch(getGamesByRosterId(rosterId));
            return dispatch(updateGameSuccess());
        } catch (e) {
            return dispatch(updateGameFailure(e));
        }
    }
}

const updateGameSuccess = () => ({
    type: UPDATE_GAME_SUCCESS,
});

const updateGameStarted = (gameId, gameData, rosterId) => ({
    type: UPDATE_GAME_STARTED,
    payload: { gameId, gameData, rosterId },
});

const updateGameFailure = error => ({
    type: UPDATE_GAME_FAILURE,
    payload: { error },
});

export function deleteGame(gameId, rosterId) {
    console.log('deleting game', gameId);
    return async (dispatch) => {
        try {
            dispatch(deleteGameStarted(gameId, rosterId));

            // really should be: dispatch(resetGamedataByGameId(gameId)).then(...
            const gamePlays = await API.del('coachAPI', `gameplays/${gameId}`);
            console.log('reset game data for:', gameId);
            dispatch(resetGamedataSuccess(gameId, gamePlays));

            await API.del('coachAPI', `games/${gameId}`);
            // refresh games data from server:
            dispatch(getGamesByRosterId(rosterId));
            return dispatch(deleteGameSuccess());
        } catch (e) {
            return dispatch(deleteGameFailure(e));
        }
    }
}

const deleteGameSuccess = () => ({
    type: DELETE_GAME_SUCCESS,
});

const deleteGameStarted = (gameId, rosterId) => ({
    type: DELETE_GAME_STARTED,
    payload: { gameId, rosterId },
});

const deleteGameFailure = error => ({
    type: DELETE_GAME_FAILURE,
    payload: { error },
});
