import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {approveStagedUser, getStagedUsers, deleteStagedUser, updateStagedUser} from "../../redux/stagedusers_slice";
import {Button, Form, Input, message, Select, Space, Spin, Table} from "antd";
import {CheckOutlined, CheckSquareTwoTone, CloseOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {userTypeOptions, positionOptions} from "../../auth-components/CustomSignup";
import debounce from 'lodash/debounce';
import {API} from "aws-amplify";

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            showSearch
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

const fetchSchoolList = (userType) => async (search) => {
    console.log('fetching schoolList for type', search, userType);
    return API.get('userAdminAPI', `schools/${search}?userType=${userType}`)
        .then(response =>
        response.map(s => {
            if (s.schoolId) {
                return { label: `${s.schoolName} ${s.mascot}, ${s.city}`, value: s.schoolId };
            } else if (s.recruiterId) {
                return { label: s.schoolName, value: s.recruiterId };
            } else if (s.playerId) {
                return { label: `${s.playerName} (${s.schoolName} ${s.mascot}, ${s.city})`, value: s.playerId };
            } else {
                return {label: 'N/A', value: 'N/A'};
            }
        }));
}

const EditableCell = form => (props) => {
    const {
        editing,
            dataIndex,
            title,
            record,
            index,
            children,
    ...restProps
    } = props;
    let inputNode  = <Input />;
    if (dataIndex === 'position') {
        inputNode = <Select
            placeholder="Select Position"
            value={record.position}
            options={positionOptions.map(v => { return { label: v, value: v}})}
        />;
    }
    else if (dataIndex === 'userType') {
        inputNode = <Select
            placeholder="Select User Type"
            value={record.userType}
            options={userTypeOptions.map(v => { return { label: v, value: v}})}
        />;
    }
    else if (dataIndex === 'schoolId') {
        inputNode = <DebounceSelect
            value={record.schoolId}
            placeholder="Select School/Player"
            fetchOptions={fetchSchoolList(form.getFieldValue('userType'))}
            style={{
                width: '100%',
            }}
        />
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


const StagedUsers = (props) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.stagedUsers.data);
    const loading = useSelector(state => state.stagedUsers.loading);
    //const updating = useSelector(state => state.stagedUsers.updating);

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const isEditing = record => record.key === editingKey;

    const displayData = userData ?
        userData.map(r => {
            return {
                ...r, key: r.userId
            }
        })
        : null;

    const edit = record => {
        form.setFieldsValue(record);
        setEditingKey(record.key);
        console.log('editing: ', record);
    };

    const saveRow = async (record) => {
        try {
            const newData = await form.validateFields();
            const newRecord = {...record, ...newData, schoolId: newData.schoolId.value };

            if (record.userType === 'Recruiter') {
                newRecord.schoolName = newData.schoolId.label;
                newRecord.recruiterId = newData.schoolId.value;
            }

            dispatch(updateStagedUser(record.userId,newRecord));

            setEditingKey('');
            message.success('Staged users updated!', 2);
            return newRecord;
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    useEffect(() => {
        if (!userData && !loading) {
            dispatch(getStagedUsers());
        }
    }, [dispatch, userData, loading]);

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            editable: true,
        },
        {
            title: 'School Entered',
            dataIndex: 'school',
        },
        {
            title: 'School/Player',
            dataIndex: 'schoolId',
            editable: true,
        },
        {
            title: 'Position',
            dataIndex: 'position',
            editable: true,
        },
        {
            title: 'User Type',
            dataIndex: 'userType',
            editable: true,
        },
        {
            dataIndex: 'operation',
            render: (_, record) => {
                if (isEditing(record)) {
                    return (
                        <Space>
                            <Button shape="circle" icon={<CheckOutlined/>} onClick={() => saveRow(record)}/>
                            <Button shape="circle" icon={<CloseOutlined/>} onClick={() => setEditingKey('')}/>
                        </Space>
                    );
                } else {
                    return (
                        <Space>
                            <Button shape="circle" icon={<EditOutlined/>} onClick={() => edit(record)}/>
                            <Button shape="circle" icon={<DeleteOutlined/>}
                                    onClick={() => dispatch(deleteStagedUser(record.userId))}/>
                            <Button shape="circle" icon={<CheckSquareTwoTone />}
                                    onClick={() => dispatch(approveStagedUser(record.userId, record))}/>
                        </Space>
                    );
                }
            },
        },
    ].map(column => {
        return !column.editable ? column : {
            ...column,
            onCell: record => ({
                record,
                title: column.title,
                dataIndex: column.dataIndex,
                editing: isEditing(record),
            }),
        }
    });


    return (
        <div>
            <h1>
                Staged Users
            </h1>
            <Form form={form} component={false}>
            <Table
                rowKey='userId'
                columns={columns}
                dataSource={displayData}
                pagination={false}
                tableLayout='fixed'
                loading={loading}
                style={{width: "100%"}}
                size='small'
                components={{
                    body: {
                        cell: EditableCell(form),
                    },
                }}
            />
            </Form>
        </div>
    );
}

export default StagedUsers;
