export const selectTeamstatsState = store => store.teamstatsData;

export const selectTeamGamestatsByRosterId = (rosterId) => (store) => {
    try {
        return selectTeamstatsState(store).gamedataByRosterId[rosterId];
    }
    catch (e) {
        return null;
    }
}

export const selectTeamGamestatsByRosterIdGameId = (rosterId, gameId) => (store) => {
    try {
        return selectTeamGamestatsByRosterId(rosterId)(store).filter(g => g.gameId === gameId);
    }
    catch (e) {
        return null;
    }
}

export const selectTeamGameoffeffByGameId = (gameId, od) => (store) => {
    try {
        return selectTeamstatsState(store).gameoffeffdataByGameId[gameId].filter(s => s.odk === od);
    }
    catch (e) {
        return null;
    }
}

export const selectTeamSeasonstatsByRosterId = (rosterId) => (store) =>
    selectTeamstatsState(store) ? selectTeamstatsState(store).seasondataByRosterId[rosterId] : null;

export const selectTeamstatsLoading = store =>
    selectTeamstatsState(store) ? selectTeamstatsState(store).loading : false;
