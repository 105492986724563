import {Select} from "antd";
import React, {useEffect, useState} from "react";
import * as queryString from "query-string";
import {useDispatch, useSelector} from "react-redux";
import {
    getCustomAnalytics,
    selectDataByQueryString,
    selectDataError,
    selectDataLoading
} from "../../../../redux/customanaytics_slice";
import {
    getCustomAnalyticsFilters,
    selectFilterData,
    selectFilterDataError,
    selectFilterDataLoading, selectFilterDataRefresh
} from "../../../../redux/customAnayticsFilter_slice";
import {analyticGroupings, analyticStats, enhanceData} from "./utils";

const {Option} = Select;

export const FBAnalyticGroupingsSelect = ({onChange, width, value, includeAll, excludeGame = false, disabled = false}) => {

    const options = analyticGroupings
        .map(ag => <Option key={ag.value} values={ag.value}>{ag.name}</Option>);

    if (includeAll)
        options.unshift(<Option key='all_stats' value='all_stats'>All Stats</Option>);

    if (excludeGame) {
        const index = options.findIndex(item => item.key === 'gameId');

        if (index !== -1) {
            options.splice(index, 1);
        }
    }

    return (
        <Select
            showSearch={true}
            placeholder="Select an Analytic Grouping"
            style={!!width ? {width: width} : {}}
            defaultValue={value}
            value={value}
            onChange={onChange}
            disabled={disabled}
        >
            {options}
        </Select>
    );
};

export const FBAnalyticStatSelect = ({onChange, width, value}) => {

    const options = analyticStats
        .map(ag => <Option key={ag.value} value={ag.value}>{ag.name}</Option>);

    return (
        <Select
            showSearch={true}
            placeholder="Select an Analytic Stat"
            style={!!width ? {width: width} : {}}
            defaultValue={value}
            value={value}
            onChange={onChange}
        >
            {options}
        </Select>
    );
};

export const FBAnalyticFilterSelect = ({onChange, width, values, gameIds, odk, groupingField1, groupingField2}) => {
    const qs = queryString.stringify({gameIds, odk, groupingField1, groupingField2}, {arrayFormat: 'comma'});
    const data = useSelector(selectDataByQueryString(qs));
    const dataLoading = useSelector(selectDataLoading);
    const dataError = useSelector(selectDataError);
    const dispatch = useDispatch();

    useEffect(() => {
        if (gameIds && !dataLoading && !dataError && !data) {
            dispatch(getCustomAnalytics(qs));
        }
    });

    const optionsData = data ?
        enhanceData(data)
            .map(r => '' + r[groupingField1])
            .filter((v, i, s) => s.indexOf(v) === i)
            .filter(v => v.length>0)
            .sort()
        : null;

    const options = optionsData ?
        optionsData.map(v => <Option key={v} value={v}>{v}</Option> )
        : null;

    if (options)
        options.unshift(<Option key='all' value='all'>All</Option>);

    const cleanValues = (vals) => {
        let cleaned = !vals ? [] : (Array.isArray(vals) ? vals : vals.split(','));
        cleaned = cleaned.map(v => v.toLowerCase());
        if (cleaned.length>1)
            cleaned = cleaned.filter(v => v !== 'all');


        if (optionsData) {
            const lcOptions = optionsData ? optionsData.map(o => o.toLowerCase()) : null;
            const exactMatches = cleaned.filter(v => lcOptions.includes(v));
            const inexactMatches = cleaned.filter(v => !lcOptions.includes(v));

            return optionsData.filter(o =>
                exactMatches.includes(o.toLowerCase())
                || inexactMatches.some(v => o.toLowerCase().startsWith(v))
            );
        }
        else
            return cleaned;
    }

    return (
        <Select
            showSearch={true}
            mode="tags"
            allowClear
            placeholder="Select a Breakdown value"
            style={!!width ? {width: width} : {}}
            value={cleanValues(values)}
            onChange={(vals) => onChange(cleanValues(vals))}
        >
            {options}
        </Select>
    );
}

export const SavedFilterSelect = ({onChange, width}) => {
    const data = useSelector(selectFilterData());
    const dataLoading = useSelector(selectFilterDataLoading);
    const dataError = useSelector(selectFilterDataError);
    const dataRefresh = useSelector(selectFilterDataRefresh);
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if ((!dataLoading && !dataError && !data) || dataRefresh) {
            dispatch(getCustomAnalyticsFilters());
        }
    }, [dataRefresh, dataLoading, dataError, data, dispatch]);

    const options = data ?
        data.map(v => <Option key={v.filterId} value={v.filterId}>{v.filterName}</Option> )
        : null;

    const handleSelectChange = (value) => {
        setSelectedValue(value);
        if (onChange) {
            const selectedOption = data.find(option => option.filterId === value);

            onChange(selectedOption);
        }
    };

    return (
        <Select
            showSearch={true}
            value={options?.some(option => option.key === selectedValue) ? selectedValue : null}
            allowClear
            placeholder="Select a saved filter"
            style={!!width ? {width: width} : {width: 250}}
            onChange={handleSelectChange}
        >
            {options}
        </Select>
    );
}
