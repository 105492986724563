export const selectGamestatsState = store => store.gamestatsData;

export const selectGamestatsByQueryString = (queryString) => (store) =>
    selectGamestatsState(store) ? selectGamestatsState(store).dataByQueryString[queryString] : [];

const keyToColumn = key => {
    const fixedCols = ['playerName', 'position'];
    return {
        title: key,
        dataIndex: key,
        // sorter is added in PlayerTable
        //sorter: (a, b) => typeof a[key] === 'string' ? a[key].localeCompare(b[key]) : a[key] - b[key],
        sortDirections: ['descend', 'ascend'],
        fixed: fixedCols.indexOf(key)>=0 ? 'left' : null,
        //width: fixedCols.indexOf(key)>=0 ? 200 : null,
        //ellipsis: true,
    }
};

export const selectGamestatColumnsByQueryString = (queryString) => (store) => {
    const data = selectGamestatsByQueryString(queryString)(store);
    try {
        return Object.keys(data[0])
            .filter(key => key.indexOf('Id') < 0 && !data.every(row => row[key] === 0))
            .map(keyToColumn);
    }
    catch (e) {
        return [];
    }
};

export const selectGamestatsLoading = store =>
    selectGamestatsState(store) ? selectGamestatsState(store).loading : false;
