import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    selectMyPlayerIds,
    selectPlayerAboutsByPlayerId,
    selectPlayerLinksByPlayerId,
    selectPlayerProfiledataByPlayerId,
    selectPlayerProfileLoading
} from "../../redux/selectors/playerProfileSelectors";
import {
    getMyPlayerIds,
    getPlayerAboutsByPlayerId,
    getPlayerLinksByPlayerId,
    getPlayerProfiledataByPlayerId,
    putPlayerAboutsByPlayerId,
    putPlayerLinksByPlayerId
} from "../../redux/actions/playerProfileActions";
import {Button, Form, Input, Card, Row, Col, Table} from "antd";

const ProfileData = ({playerId}) => {
    const dispatch = useDispatch();
    const data = useSelector(selectPlayerProfiledataByPlayerId(playerId));
    const loading = useSelector(selectPlayerProfileLoading);

    useEffect(() => {
        if (!loading && !data) {
            dispatch(getPlayerProfiledataByPlayerId(playerId));
        }
    });

    const gridStyle1 = {
        width: '50%',
        boxShadow: 'none',
        textAlign: 'right'
    };

    const gridStyle2 = {
        width: '50%',
        boxShadow: 'none',
        textAlign: 'left'
    };

    if (data && data[0]) {
        return (
            <Card title="Academic Information" style={{height: 500, textAlign: 'center'}}>

                <Card.Grid style={gridStyle1} hoverable={false}>GPA:</Card.Grid>
                <Card.Grid style={gridStyle2} hoverable={false}>{data[0].gpa}</Card.Grid>

                <Card.Grid style={gridStyle1} hoverable={false}>Rank:</Card.Grid>
                <Card.Grid style={gridStyle2} hoverable={false}>{data[0].rank_pct}</Card.Grid>

                <Card.Grid style={gridStyle1} hoverable={false}>PSAT:</Card.Grid>
                <Card.Grid style={gridStyle2} hoverable={false}>{data[0].psat}</Card.Grid>

                <Card.Grid style={gridStyle1} hoverable={false}>SAT:</Card.Grid>
                <Card.Grid style={gridStyle2} hoverable={false}>{data[0].sat}</Card.Grid>

                <Card.Grid style={gridStyle1} hoverable={false}>ACT:</Card.Grid>
                <Card.Grid style={gridStyle2} hoverable={false}>{data[0].act}</Card.Grid>
            </Card>
        );
    } else {
        return (
            <Card title="Academic Information" style={{height: 500, textAlign: 'center'}}>
                <Card.Grid style={gridStyle1} hoverable={false} bordered={false}>No Information</Card.Grid>
            </Card>
        );
    }
}

const Links = ({playerId}) => {
    const dispatch = useDispatch();
    const links = useSelector(selectPlayerLinksByPlayerId(playerId));
    const loading = useSelector(selectPlayerProfileLoading);
    const columns = [
        {
            title: 'Link',
            dataIndex: 'link',
            render: (l, r) => {
                const url = l.toLowerCase().startsWith('http') ? l : 'http://' + l;
                return (
                    <a href={url} target="_blank" rel="noopener noreferrer">{r.linkType}</a>
                );
            }
        }
    ]

    useEffect(() => {
        if (!loading && !links) {
            dispatch(getPlayerLinksByPlayerId(playerId));
        }
    });

    return (
        <Card
            title="Game Film and Other Links"
            style={{height: 500, textAlign: 'center'}}
            bodyStyle={{height: 450, overflow: 'auto'}}
        >
            <Table
                columns={columns}
                dataSource={links}
                pagination={false}
                showHeader={false}
            />
        </Card>
    )

}

const CoachNotes = ({playerId}) => {
    const dispatch = useDispatch();
    const data = useSelector(selectPlayerProfiledataByPlayerId(playerId));
    const loading = useSelector(selectPlayerProfileLoading);

    useEffect(() => {
        if (!loading && !data) {
            dispatch(getPlayerProfiledataByPlayerId(playerId));
        }
    });

    return (
        <Card title="Coach Notes" style={{}}>
            {(data && data[0]) ? data[0].coachNotes
                : <p>No Data To Show</p>}
        </Card>
    )
}


const AboutsAndLinks = ({playerId}) => {

    const abouts = useSelector(selectPlayerAboutsByPlayerId(playerId));
    const links = useSelector(selectPlayerLinksByPlayerId(playerId));
    //const data = useSelector(selectPlayerProfiledataByPlayerId(playerId));
    const myPlayerIds = useSelector(selectMyPlayerIds);
    const loading = useSelector(selectPlayerProfileLoading);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        if (!loading && !(abouts && links)) {
            dispatch(getPlayerAboutsByPlayerId(playerId));
            dispatch(getPlayerLinksByPlayerId(playerId));
            dispatch(getMyPlayerIds());
        }
    });

    useEffect(() => {
        if (abouts && abouts[0]) {
            form.setFieldsValue({aboutContent: abouts[0].content});
        }
    }, [abouts, form]);

    useEffect(() => {
        if (links && links.length > 0) {
            form.setFieldsValue(Object.fromEntries(links.map(l => [l.linkType, l.link])));
        }
    }, [links, form]);

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 10,
        },
    };

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            //span: 16,
        },
    };

    const onFinish = values => {
        dispatch(putPlayerAboutsByPlayerId(playerId, {content: values.aboutContent}));
        dispatch(putPlayerLinksByPlayerId(playerId, {
            links: [
                {linkType: 'link1', link: values.link1},
                {linkType: 'link2', link: values.link2},
                {linkType: 'link3', link: values.link3},
            ]
        }));
    };

    const onFinishFailed = errorInfo => {
    };

    const onReset = () => {
        form.setFieldsValue({aboutContent: abouts[0].content});
        form.setFieldsValue(Object.fromEntries(links.map(l => [l.linkType, l.link])));
    };

    if (myPlayerIds.includes(playerId)) {
        return (
            <Form
                {...layout}
                name="AboutsAndLinks"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Form.Item
                    label="About"
                    name="aboutContent"
                    rules={[
                        {
                            max: 256,
                        },
                    ]}
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>

                <Form.Item
                    label="Link1"
                    name="link1"
                    rules={[
                        {
                            max: 256,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Link2"
                    name="link2"
                    rules={[
                        {
                            max: 256,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Link3"
                    name="link3"
                    rules={[
                        {
                            max: 256,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button onClick={onReset}>
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        );
    } else {
        return (
            <>
                <Row gutter={[16, 24]}>
                    <Col span={10}>
                        <ProfileData playerId={playerId}/>
                    </Col>
                    <Col span={10}>
                        <Links playerId={playerId}/>
                    </Col>
                    <Col span={20}>
                        <CoachNotes playerId={playerId}/>
                    </Col>
                </Row>
            </>
        )
    }
}

export default AboutsAndLinks;
