import {API} from "aws-amplify";

export const GET_GAMESTATS_STARTED = 'GET_GAMESTATS_STARTED';
export const GET_GAMESTATS_SUCCESS = 'GET_GAMESTATS_SUCCESS';
export const GET_GAMESTATS_FAILURE = 'GET_GAMESTATS_FAILURE';

export function getGamestatsByFilter(params) {
    console.log('getting gamestats for: ', params);
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    return async (dispatch) => {
        try {
            dispatch(getGamestatsStarted(queryString));
            const gameStats = await API.get('coachAPI', `gamestats?${queryString}`);
            console.log('got gamestats for:', queryString);
            dispatch(getGamestatsSuccess(queryString, gameStats));
        } catch (e) {
            console.log('problem getting gamestats:', e);
            return dispatch(getGamestatsFailure(e));
        }
    }
}

const getGamestatsSuccess = (queryString, data = []) => ({
    type: GET_GAMESTATS_SUCCESS,
    payload: { queryString, data }
});

const getGamestatsStarted = (queryString) => ({
    type: GET_GAMESTATS_STARTED,
    payload: queryString,
});

const getGamestatsFailure = error => ({
    type: GET_GAMESTATS_FAILURE,
    payload: {
        error
    }
});
