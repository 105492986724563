export const recruiterSearchState = store => store.recruitersearchData;

export const selectGotSearches = store => recruiterSearchState(store) ? recruiterSearchState(store).gotSearches : false;
export const selectSearchLoading = store => recruiterSearchState(store) ? recruiterSearchState(store).loading : false;

export const selectSearches = store => recruiterSearchState(store) ? recruiterSearchState(store).searches : null;

export const selectSearchByID = (searchId) => store => recruiterSearchState(store) ? recruiterSearchState(store).searchesById[searchId] : null;

export const selectNewSearchId = store => recruiterSearchState(store) ? recruiterSearchState(store).newSearchId : '';
