export const recruiterListState = store => store.recruiterlistData;

export const selectGotLists = store => recruiterListState(store) ? recruiterListState(store).gotLists : false;
export const selectLoading = store => recruiterListState(store) ? recruiterListState(store).loading : false;

export const selectLists = store => recruiterListState(store) ? recruiterListState(store).lists.map(l => {
    return {...l, key: l.listId}
}) : null;

export const selectAllLists = store => recruiterListState(store) ? recruiterListState(store).allLists.map(l => {
    return {...l, key: l.listId}
}) : null;

export const selectPlayerPipelineLists = store => recruiterListState(store) ? recruiterListState(store).playerPipelineLists.map(l => {
    return {...l, key: l.listId}
}) : null;
