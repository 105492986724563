import React from "react";
import {Select} from "antd";
import statGroupings from "./categories_fb";

const {Option, OptGroup} = Select;

// antd wants children to be its own components so this has to be a function and not a component:
const getPositionOptionGroup = (key, label, groupings, quoteValues) => {
    const positions = groupings.split(',')
        .map(v => statGroupings[v].positions)
        .reduce((a, v) => a.concat(v), []);

    const positionOptions = positions.map(v => (<Option key={v} value={quoteValues ? `'${v}'` : v}>{v}</Option>));

    return (
        <OptGroup key={key} label={label}>
            {positionOptions}
        </OptGroup>
    );
}

export const FBPositionSelect = ({onChange, width, defaultValue, value, quoteValues}) => {
    return (
        <Select
            showSearch={true}
            placeholder="Select a position"
            style={!!width ? {minWidth: 150, width: width} : {minWidth: 150}}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
        >
            {getPositionOptionGroup("1","Offense","passing,rushing,receiving", quoteValues)}
            {getPositionOptionGroup("2","Kicking","kicking", quoteValues)}
            {getPositionOptionGroup("3","Defense","defense", quoteValues)}
        </Select>
    );
};

export const FBStatGroupingsSelect = ({onChange, width, defaultValue}) => {

    const options = Object.keys(statGroupings)
        .filter(k => !statGroupings[k].noLeaders)
        .map(k => <Option key={k} values={k}>{statGroupings[k].name}</Option>);

    return (
        <Select
            showSearch={true}
            placeholder="Select a Stat Grouping"
            style={!!width ? {width: width} : {}}
            defaultValue={defaultValue}
            onChange={onChange}
        >
            {options}
        </Select>
    );
};
