import React, {useEffect} from "react";
import {Tabs} from "antd";
import QuickSearch from "./QuickSearch";
import SavedSearches from "./SavedSearches";
import AdvancedSearch from "./AdvancedSearch";
import {useLocation, useNavigate} from "@reach/router";
import * as queryString from "query-string";

const Research = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const {tab} = queryString.parse(location.search)

    const tabChange = (tab) => {
        if (tab === 'advancedSearch')
            navigate(location.pathname + `?tab=${tab}&editMode=true&searchId=new`);
        else
            navigate(location.pathname + `?tab=${tab}`);
    };

    useEffect(() => {
        if (!tab)
            navigate(location.pathname + '?tab=quickSearch&editMode=true');
    });

    return (
        <Tabs activeKey={tab} onChange={tabChange} size={'large'}>
            <Tabs.TabPane key={'quickSearch'} value={'quickSearch'} tab={'Quick Search'}>
                <QuickSearch/>
            </Tabs.TabPane>
            <Tabs.TabPane key={'advancedSearch'} value={'advancedSearch'} tab={'Advanced Search'}>
                <AdvancedSearch/>
            </Tabs.TabPane>
            <Tabs.TabPane key={'savedSearches'} value={'savedSearches'} tab={'Saved Searches'}>
                <SavedSearches/>
            </Tabs.TabPane>
        </Tabs>
    );
}

export default Research;
