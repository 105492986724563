import React from "react";

const EditProfile = (props) => {
    return (
        <div className="center">
            <h1>
                Edit Profile
            </h1>
            <p>
                content goes here
            </p>
        </div>
    );
}

export default EditProfile;