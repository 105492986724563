import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {selectPlayerstatsByPlayerId} from "../../redux/selectors/playerstatsSelectors";
import {getPlayerstatsByPlayerId} from "../../redux/actions/playerstatsActions";

const GeneralInfo = ({playerId}) => {

    const data = useSelector(selectPlayerstatsByPlayerId(playerId));
    const loading = useSelector(store => store.playerstatsData.loading);
    const dispatch = useDispatch();

    useEffect(() => {
            if (!loading && !data) {
                dispatch(getPlayerstatsByPlayerId(playerId));
            }
        }
    );

    if (data && data.player_info[0]) {
        const info = data.player_info[0];
        const ng_stats = data.non_game_stats[0]
            ? data.non_game_stats[0]
            : { height: 'N/A', weight: 'N/A', wingspan: 'N/A' };

        return (
            <>
                <h1>#{info.jersey} {info.playerName} ({info.graduationYear})</h1>
                <h2>{info.position}{info.depth} - {info.level}, {info.schoolName} {info.teamName} - {info.state}</h2>
                <h3>Height: {ng_stats.displayHeight}, Weight: {ng_stats.weight} lbs, Wingspan: {ng_stats.wingspan} in</h3>
            </>
        );
    }
    else
        return null;

}

export default GeneralInfo;
