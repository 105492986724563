import React, { useEffect } from "react";
import GeneralInfo from "./GeneralInfo";
import NongameStats from "./NongameStats";
import GamestatsTable from "./GamestatsTable";
import { Col, Layout, Row, Tabs } from "antd";
import statGroupings from "../../shared-components/categories_fb";
import { useDispatch, useSelector } from "react-redux";
import { selectPlayerstatsByPlayerId } from "../../redux/selectors/playerstatsSelectors";
import { getPlayerstatsByPlayerId } from "../../redux/actions/playerstatsActions";
import RecruiterNotes from "./RecruiterNotes";
import Messages from "./Messages";
import * as queryString from "query-string";
import { useLocation, useNavigate } from "@reach/router";
import AboutsAndLinks from "./AboutsAndLinks";
import RecruiterPlayerPipelineHistory from "./RecruiterPlayerPipelineHistory";
import { getUser } from "../../redux/user_slice";
import { updateReferral } from "../../redux/referral_slice";
import TeamSchedule from "../coach/components/TeamSchedule";

const PlayerDashboardStats = ({ seasonId, rosterId, playerId }) => {
  const data = useSelector(selectPlayerstatsByPlayerId(playerId));
  const loading = useSelector((store) => store.playerstatsData.loading);
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  console.log("user Data", userData);

  const { tab } = queryString.parse(location.search);

  const dispatch = useDispatch();

  const onChangeRoster = (rosterId, replace) => {
    const queryParams = queryString.parse(location.search);
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({ ...queryParams, rosterId }),
      { replace }
    );
  };

  const onChangeSeason = (seasonId, replace) => {
    const queryParams = queryString.parse(location.search);
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({ ...queryParams, seasonId }),
      { replace }
    );
  };

  useEffect(() => {
    if (!userData.userId) {
      dispatch(getUser());
    }

    if (!loading && !data) {
      dispatch(getPlayerstatsByPlayerId(playerId));
      dispatch(updateReferral(playerId));
    }
  }, [userData?.userId, data, loading]);

  console.log("data", data);

  useEffect(() => {
    if (data && data?.player_info[0]?.rosterId && !rosterId) {
      onChangeRoster(data?.player_info[0]?.rosterId, true);
    }
  }, [data, rosterId]);

  useEffect(() => {
    if (data && data?.season_stats_fb[0]?.seasonId && !seasonId) {
      onChangeSeason(data?.player_info[0]?.seasonId, true);
    }
  }, [data, seasonId]);

  const onChangeTab = (tab) => {
    const queryParams = queryString.parse(location.search);
    navigate(
      location.pathname + "?" + queryString.stringify({ ...queryParams, tab }),
      { replace: true }
    );
  };

  let tabs = !data
    ? null
    : Object.keys(statGroupings)
        .filter(
          (sg) =>
            data &&
            data.season_stats_fb.filter(
              (r) =>
                statGroupings[sg].keyStats.some((s) =>
                  parseInt(r[statGroupings[sg].statPrefix + s])
                ) > 0
            ).length > 0
        )
        .map((sg) => (
          <Tabs.TabPane
            key={sg}
            value={sg}
            tab={statGroupings[sg].name + " Stats"}
          >
            <GamestatsTable
              playerId={playerId}
              statGrouping={sg}
              sport={"fb"}
            />
          </Tabs.TabPane>
        ));

  if (
    tabs &&
    userData &&
    userData.userGroups &&
    userData.userGroups.includes("recruiters")
  ) {
    tabs = tabs
      .concat(
        <Tabs.TabPane
          key={"recruiter-notes"}
          value={"recruiter-notes"}
          tab={"Recruiter Notes"}
        >
          <RecruiterNotes playerId={playerId} />
        </Tabs.TabPane>
      )
      .concat(
        <Tabs.TabPane
          key={"recruiter-pipeline-history"}
          value={"recruiter-pipeline-history"}
          tab={"Player Pipeline History"}
        >
          <RecruiterPlayerPipelineHistory playerId={playerId} />
        </Tabs.TabPane>
      )
      .concat(
        <Tabs.TabPane key={"messages"} value={"messages"} tab={"Messages"}>
          <Messages playerId={playerId} />
        </Tabs.TabPane>
      );
  }

  return (
    <Layout>
      <Layout.Content style={{ paddingBottom: "48px" }}>
        <Row style={{ marginBottom: 24 }}>
          <Col span={12}>
            <TeamSchedule
              rosterId={data?.player_info[0]?.rosterId}
              allowGameEdit={false}
              usePlayerApi={true}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              tabBarStyle={{ marginLeft: 16 }}
              activeKey={tab ? tab : tabs ? tabs[0]?.key : null}
              onChange={onChangeTab}
              size={"large"}
              style={{ background: "white" }}
            >
              {tabs}
            </Tabs>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default PlayerDashboardStats;
