import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMessageById, updateMessage} from "../../redux/actions/messageActions";
import {selectMessageById} from "../../redux/selectors/messageSelectors";
import NewMessage from "../../shared-components/newMessage";
import {Button, Modal} from "antd";

const Message = (props) => {

    const dispatch = useDispatch();
    const messageData = useSelector(selectMessageById(props.messageId));
    const message = messageData && messageData.length > 0 ? messageData[0] : null;
    const loading = useSelector(store => store.messageData.loading);
    const userData = useSelector(state => state.user);

    const [showNewMessage, setShowNewMessage] = useState(false);

    useEffect(() => {
        if (!loading && !messageData) {
            dispatch(getMessageById(props.messageId));
        }
    }, [loading, messageData, dispatch, props.messageId]);


    useEffect(() => {
        if (message && message.messageId && message.toId === userData.userId && !message.isRead) {
            dispatch(updateMessage(message.messageId, true));
        }
    }, [message, userData.userId, dispatch]);

    if (message) {
        return (
            <>
                <h1>
                    {message.subject}
                </h1>
                <table>
                    <tbody>
                    <tr>
                        <th>From:</th>
                        <td>{message.fromName}</td>
                    </tr>
                    <tr>
                        <th>Sent On:</th>
                        <td>{message.sentOn}</td>
                    </tr>
                    <tr>
                        <th>Message:</th>
                        <td>{message.content}</td>
                    </tr>
                    </tbody>
                </table>

                <Button type='primary' onClick={() => setShowNewMessage(true)}>
                    Reply
                </Button>

                <Modal
                    width={640}
                    //placement="right"
                    closable={true}
                    destroyOnClose={true}
                    visible={showNewMessage}
                    footer={null}
                >
                    <NewMessage toId={message.fromId}
                                previousMessageId={message.messageId}
                                subject={'RE: ' + message.subject}
                                closeCallback={() => setShowNewMessage(false)}
                    />
                </Modal>

            </>
        );
    } else
        return (<></>);
}

export default Message;
