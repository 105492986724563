import {
    CREATE_GAME_FAILURE,
    CREATE_GAME_STARTED,
    CREATE_GAME_SUCCESS,
    GET_GAMEDATA_FAILURE,
    GET_GAMEDATA_STARTED,
    GET_GAMEDATA_SUCCESS,
    RESET_GAMEDATA_FAILURE,
    RESET_GAMEDATA_STARTED,
    RESET_GAMEDATA_SUCCESS,
    RECALC_GAMESTATS_FAILURE,
    RECALC_GAMESTATS_STARTED,
    RECALC_GAMESTATS_SUCCESS,
    GET_GAMES_FAILURE,
    GET_GAMES_STARTED,
    GET_GAMES_SUCCESS,
    UPDATE_GAME_FAILURE,
    UPDATE_GAME_STARTED,
    UPDATE_GAME_SUCCESS,
    DELETE_GAME_FAILURE,
    DELETE_GAME_STARTED,
    DELETE_GAME_SUCCESS,
} from '../actions/gamesActions';

const initialState = {
    gamesLoading: false,
    gamedataLoading: false,
    syncing: false,
    error: null,
    rosterIds: [],
    gamesByRosterId: {},
    gameIds: [],
    gamedataByGameId: {},
};

export default function gamesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_GAMES_STARTED:
            return {
                ...state,
                gamesLoading: true,
            };
        case GET_GAMES_SUCCESS:
            return {
                ...state,
                gamesLoading: false,
                error: null,
                rosterIds: state.rosterIds.includes(action.payload.rosterId) ? state.rosterIds : [...state.rosterIds, action.payload.rosterId],
                gamesByRosterId: {
                    ...state.gamesByRosterId,
                    [action.payload.rosterId]: action.payload.data.map(r => {
                        return {
                            ...r,
                            colorKey: <div style={{width: '25px', 'height': '25px', backgroundColor: getColor(r.gameId)}}/>
                        }
                    })
                },
            };
        case GET_GAMES_FAILURE:
            return {
                ...state,
                gamesLoading: false,
                error: action.payload.error
            };
        case GET_GAMEDATA_STARTED:
            return {
                ...state,
                gamedataLoading: true,
            };
        case GET_GAMEDATA_SUCCESS:
            return {
                ...state,
                gamedataLoading: false,
                error: null,
                gameIds: state.gameIds.includes(action.payload.gameId) ? state.gameIds : [...state.gameIds, action.payload.gameId],
                gamedataByGameId: { ...state.gamedataByGameId, [action.payload.gameId]: action.payload.data },
            };
        case GET_GAMEDATA_FAILURE:
            return {
                ...state,
                gamedataLoading: false,
                error: action.payload.error
            };
        case RESET_GAMEDATA_STARTED:
            return {
                ...state,
                gamedataLoading: true,
            };
        case RESET_GAMEDATA_SUCCESS:
            return {
                ...state,
                gamedataLoading: false,
                error: null,
                gameIds: state.gameIds.filter(g => g!==action.payload.gameId),
                gamedataByGameId: { ...state.gamedataByGameId, [action.payload.gameId]: null },
            };
        case RESET_GAMEDATA_FAILURE:
            return {
                ...state,
                gamedataLoading: false,
                error: action.payload.error
            };
        case CREATE_GAME_STARTED:
            return {
                ...state,
                //TODO
            };
        case CREATE_GAME_SUCCESS:
            return {
                ...state,
                syncing: false,
            };
        case CREATE_GAME_FAILURE:
            return {
                ...state,
                syncing: false,
                error: action.payload.error
            };
        case UPDATE_GAME_STARTED:
        case RECALC_GAMESTATS_STARTED:
            return {
                ...state,
                syncing: true,
            };
        case UPDATE_GAME_SUCCESS:
        case RECALC_GAMESTATS_SUCCESS:
            return {
                ...state,
                syncing: false,
            };
        case UPDATE_GAME_FAILURE:
        case RECALC_GAMESTATS_FAILURE:
            return {
                ...state,
                syncing: false,
                error: action.payload.error
            };
        case DELETE_GAME_STARTED:
            return {
                ...state,
                syncing: true,
            };
        case DELETE_GAME_SUCCESS:
            return {
                ...state,
                syncing: false,
            };
        case DELETE_GAME_FAILURE:
            return {
                ...state,
                syncing: false,
                error: action.payload.error
            };

        default:
            return state;
    }
}

let gameColors = {};

const getColor = (row) => {
    if (gameColors[row]) {
        return gameColors[row];
    } else {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        gameColors = {
            ...gameColors,
            [row]: color,
        }

        return color;
    }
}
