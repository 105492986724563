import React, {useState} from "react";
import {SavedListsSelect} from "./SavedListsSelect";
import {createList, getLists, updateList} from "../redux/actions/recruiterlistActions";
import {useDispatch, useSelector} from "react-redux";
import {selectAllLists} from "../redux/selectors/recruiterlistSelectors";
import {Button, Space, message} from "antd";

const AddToSavedLists = ({selectedRowKeys, clearSelectedRows, addedFrom, addedFromDetail}) => {
    const [savedLists, setSavedLists] = useState([]);

    const listOptions = useSelector(selectAllLists);

    const dispatch = useDispatch();

    const saveLists = () => {
        savedLists.forEach(v => {
            if (listOptions.map(l=>l.listId).indexOf(v) < 0)
                dispatch(createList({listName: v, playerIds: selectedRowKeys, addedFrom: addedFrom, addedFromDetail: addedFromDetail}));
            else
                dispatch(updateList(v, {additions: selectedRowKeys, addedFrom: addedFrom, addedFromDetail: addedFromDetail}, getLists));
        });

        clearSelectedRows();
        message.success(`${selectedRowKeys.length} player(s) added.`, 2);
    };

    return (
        <Space>
            Add selected players to saved lists:
            <SavedListsSelect
                onChange={setSavedLists}
                width={200}
                mode='multiple'
            />
            <Button type={"primary"} onClick={saveLists}>Save Selection</Button>
        </Space>
    );
};

export default AddToSavedLists;
