import { createSlice } from '@reduxjs/toolkit';
import {API} from 'aws-amplify';
import {clearPlayerProfiledata} from "./actions/playerProfileActions";
import {convertInches} from "./utils";

const slice = createSlice({
    name: 'prospects',
    initialState: {
        loading: false,
        syncing: false,
        error: null,
        listBySeasonId: {},
    },
    reducers: {
        prospectsLoading: (state, action) => {
            state.loading = action.payload;
        },
        getProspectsSuccess: (state, action) => {
            state.loading = false;
            state.listBySeasonId[action.payload.seasonId] = action.payload.data;
        }
    },
});
export default slice.reducer;

// Actions
export const { prospectsLoading, getProspectsSuccess } = slice.actions;

export const getProspects = (seasonId) => async dispatch => {
    try {
        console.log('getting prospects', seasonId);
        dispatch(prospectsLoading(true));
        const prospects = await API.get('coachAPI', `prospects?seasonId=${seasonId}`);
        dispatch(getProspectsSuccess({seasonId, data: prospects}));
    } catch (e) {
        dispatch(prospectsLoading(false));
        alert(e.message);
    }
};

export const updateProspect = (seasonId, playerId, playerData) => async dispatch => {
    try {
        console.log('updating prospect', playerId, playerData);
        dispatch(prospectsLoading(true));
        await API.put('coachAPI', `prospects/${playerId}`, {
            body: playerData
        });
        dispatch(getProspects(seasonId));
        // clear data in player profile:
        dispatch(clearPlayerProfiledata(playerId));
    } catch (e) {
        dispatch(prospectsLoading(false));
        alert(e.message);
    }
};

export const deleteProspect = (seasonId, playerId) => async dispatch => {
    try {
        console.log('deleting prospect', playerId);
        dispatch(prospectsLoading(true));
        await API.del('coachAPI', `prospects/${playerId}`);
        dispatch(getProspects(seasonId));
        // clear data in player profile:
        dispatch(clearPlayerProfiledata(playerId));
    } catch (e) {
        dispatch(prospectsLoading(false));
        alert(e.message);
    }
};

export const selectProspectList = seasonId => store =>
    store.prospectListData && store.prospectListData.listBySeasonId[seasonId]
        ? store.prospectListData.listBySeasonId[seasonId]
            .map(p => {
                return {
                    ...p,
                    key: p.playerId,
                    displayHeight: convertInches(p.height),
                }
            })
        : null;

export const selectProspectListLoading = store =>
    store.prospectListData ? store.prospectListData.loading : false;
