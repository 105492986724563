import React from "react";
import { globalHistory, Router } from "@reach/router";

import "./App.css";
import logo from "./resources/logo_transparent_background.png";

import Home from "./screens/home";

import Navs from "./navs/navs";
import CoachHome from "./screens/coach/coachHome";
import CoachDashboard from "./screens/coach/Dashboard";
import CoachNongameData from "./screens/coach/NongameData";
import CoachRoster from "./screens/coach/RosterManage";
import CoachDepth from "./screens/coach/depthManage";
import CoachSchedule from "./screens/coach/ScheduleManage";
import CustomAnalytics from "./screens/coach/CustomAnalytics";
import SchoolProfile from "./screens/coach/SchoolProfile";
import PlayerHome from "./screens/player/dashboard";
import PlayerDashboard from "./screens/player/dashboard";
import PlayerTraining from "./screens/player/training";
import PlayerProfile from "./screens/player/editProfile";
import PlayerTeam from "./screens/player/teamInfo";
import RecruiterHome from "./screens/recruiter/RecruiterHome";
import RecruiterStatLeaders from "./screens/recruiter/StatLeaders";
import RecruiterResearch from "./screens/recruiter/Research";
import RecruiterNeeds from "./screens/recruiter/TeamNeeds";
import RecruiterLists from "./screens/recruiter/SavedLists";
import RecruiterList from "./screens/recruiter/SavedList";
import CommCenter from "./screens/comm/comCenter";
import Inbox from "./screens/comm/inbox";
import Outbox from "./screens/comm/outbox";
import AllMessages from "./screens/comm/AllMessages";
import Message from "./screens/comm/message";
import PlayerScreen from "./screens/PlayerScreen";
import GameScreen from "./screens/coach/game";
import StagedUsers from "./screens/admin/stagedusers";
import ReferralHome from "./screens/referral/ReferralHome";
import Referrals from "./screens/referral/Referrals";

import {
  customServices,
  customSignup,
  customFormFields,
} from "./auth-components/CustomSignup";

// Amplify:
import { Amplify, Auth, Hub } from "aws-amplify";
import { Authenticator, Image } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
// redux:
import { Provider } from "react-redux";
import store from "./redux";
import User from "./screens/user";
// google analytics
import ReactGA from "react-ga4";
// hotjar
import { hotjar } from "react-hotjar";
import PlayerPipeline from "./screens/recruiter/PlayerPipeline";
import ProspectList from "./screens/coach/ProspectList";

import awsConfig from "./amplify-config";
import Scouting from "./screens/coach/Scouting";

//ms-clarity
import { clarity } from "react-microsoft-clarity";
import { ConfigProvider } from "antd";

Amplify.configure(awsConfig);

if (process.env.REACT_APP_STAGE === "prod") {
  const trackingId = "G-2HBBGGPVTN";
  ReactGA.initialize(trackingId);

  globalHistory.listen(({ location }) => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: location.pathname + location.search,
    });
  });

  const clarityId = "gytsr0lia5";
  clarity.init(clarityId);
  clarity.setTag("env", process.env.REACT_APP_STAGE);

  Auth.currentAuthenticatedUser()
    .then((authRes) => {
      ReactGA.set({ userId: authRes.username });
    })
    .catch(() => ReactGA.set({ userId: null }));

  Hub.listen("auth", (data) => {
    if (data.payload.event === "signIn") {
      ReactGA.set({ userId: data.payload.data.username });
      clarity.identify("USER_ID", { userProperty: data.payload.data.username });
    } else if (data.payload.event === "signOut") {
      ReactGA.set({ userId: null });
      clarity.identify("USER_ID", { userProperty: null });
    }
  });
}

console.log("env", process.env.REACT_APP_STAGE);

hotjar.initialize(1882951, 6);

function App({ user, ...props }) {
  // let group = null;
  // try {
  //     group = user.signInUserSession.accessToken.payload['cognito:groups'][0];
  // }
  // catch (e) {
  // }

  //if (group) {
  return (
    <div className="App">
      <div>
        <Router>
          <Navs path="/">
            <Home path="/" />
            <User path="/user" />

            <CoachHome path="/coaches/" />
            <PlayerScreen path="/coaches/players/:playerId" />
            <CoachDashboard path="/coaches/dashboard" />
            <CoachNongameData path="/coaches/non-game-data" />
            <CoachRoster path="/coaches/roster" />
            <CoachDepth path="/coaches/depth" />
            <CoachSchedule path="/coaches/schedule" />
            <CustomAnalytics path="/coaches/analytics" />
            <Scouting path="/coaches/scouting" />
            <GameScreen path="/coaches/games/:rosterId/:gameId" />
            <PlayerScreen path="/coaches/players/:playerId" />
            <ProspectList path="/coaches/prospect-list" />
            <SchoolProfile path="/coaches/school-profile" />

            <PlayerHome path="/players/" />
            <PlayerProfile path="/players/profile" />
            <PlayerDashboard path="/players/dashboard" />
            <PlayerTraining path="/players/training" />
            <PlayerTeam path="/players/team" />
            <PlayerScreen path="/players/players/:playerId" />

            <RecruiterHome path="/recruiters/" />
            <RecruiterStatLeaders path="/recruiters/statleaders" />
            <PlayerPipeline path="/recruiters/playerpipeline" />
            <RecruiterResearch path="/recruiters/research" />
            <RecruiterNeeds path="/recruiters/needs" />
            <RecruiterLists path="/recruiters/lists" />
            <RecruiterList path="/recruiters/lists/:listId" />
            <PlayerScreen path="/recruiters/players/:playerId" />

            <ReferralHome path="/referrals/" />
            <Referrals path="/referrals/sharedprofiles" />
            <PlayerScreen path="/referrals/players/:playerId" />

            <CommCenter path="/comm/" />
            <Inbox path="/comm/inbox" />
            <Outbox path="/comm/outbox" />
            <AllMessages path="/comm/all" />
            <Message path="/comm/messages/:messageId" />

            <StagedUsers path="/admin/stagedusers" />
          </Navs>
        </Router>
      </div>
    </div>
  );
  // }
  // else {
  //     return (
  //         <div className="App">
  //             <Home />
  //         </div>
  //     );
  // }
}

//export default withAuthenticator(App);
const Main = () => (
  <Provider store={store}>
    <Authenticator
      components={{
        Header() {
          return <Image src={logo} alt="logo" />;
        },
        SignUp: customSignup,
      }}
      services={customServices}
      formFields={customFormFields}
    >
      {({ signOut, user }) => <App user={user} />}
    </Authenticator>
  </Provider>
);

const ThemedMain = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#446eb2",
        colorInfo: "#446eb2",
        borderRadius: 2,
      },
      components: {
        Menu: {
          subMenuItemBg: "rgb(255, 255, 255)",
          itemHoverColor: "rgb(22, 119, 255)",
          itemHoverBg: "rgb(255, 255, 255)",
        },
        Layout: {
          bodyBg: "#f0f2f5",
        },
      },
    }}
  >
    <Main />
  </ConfigProvider>
);

export default ThemedMain;
