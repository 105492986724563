import {Button, Tooltip} from "antd";
import {UserDeleteOutlined} from '@ant-design/icons';
import {updateList} from "../../../redux/actions/recruiterlistActions";
import {getQuicksearchByQuerystring} from "../../../redux/actions/quicksearchActions";
import React from "react";
import {useDispatch} from "react-redux";

export const RemoveFromSavedList = ({listId, playerId}) => {
    const dispatch = useDispatch();

    return (
        <Tooltip title='Remove from Saved List' placement='left'>
            <Button shape="circle" icon={<UserDeleteOutlined/>}
                    onClick={() => dispatch(updateList(listId, {deletions: [playerId]}, getQuicksearchByQuerystring))}/>
        </Tooltip>);
};
