import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import * as queryString from "query-string";
import {
    selectQuicksearchCurrentQuery,
    selectQuicksearchData,
    selectQuicksearchLoading
} from "../../redux/selectors/quicksearchSelectors";
import {getQuicksearchByQuerystring} from "../../redux/actions/quicksearchActions";
import PlayerTable from "../../shared-components/PlayerTable";
import {RemoveFromSavedList} from "./components/SavedListComponents";
import {formatRelative} from "date-fns";

const SavedList = ({listId, rowSelection = null, isPipelineList = false}) => {

    const dispatch = useDispatch();

    const query = queryString.stringify({listId});

    const data = useSelector(selectQuicksearchData);
    const currentQuery = useSelector(selectQuicksearchCurrentQuery);
    const loading = useSelector(selectQuicksearchLoading);

    let displayData = [];
    if (listId && !loading && query === currentQuery && data) {
        displayData = data
            .map(p => {
                return {
                    ...p,
                    dateAddedDisplay: formatRelative(new Date(p.dateAdded), new Date()),
                }
            })
    }

    const getColumns = () => {
        let columns;

        //this is okay for now, BUT if we start to use this component for more player lists with different
        //column configurations we should either add a column parameter so the column list lives in the parent component
        //OR probably even better create separate endpoints, queries and components as the shared query is getting messy too.
        if(isPipelineList) {
            columns = [
                {
                    title: 'Player',
                    dataIndex: 'playerName',
                },
                {
                    title: 'State',
                    dataIndex: 'state',
                    filterable: true,
                },
                {
                    title: 'School',
                    dataIndex: 'schoolName',
                },
                {
                    title: 'Position',
                    dataIndex: 'position',
                    filterable: true,
                },
                {
                    title: 'Graduating Class',
                    dataIndex: 'graduationYear',
                    filterable: true,
                },
                {
                    title: 'Height',
                    dataIndex: 'height',
                    filterable: true,
                },
                {
                    title: 'Weight',
                    dataIndex: 'weight',
                    filterable: true,
                },
                {
                    title: 'Hudl Link',
                    dataIndex: 'link',
                },
                {
                    title: 'Date Added',
                    dataIndex: 'dateAddedDisplay',
                },
                {
                    dataIndex: 'playerId',
                    render: (playerId) => (<RemoveFromSavedList listId={listId} playerId={playerId} />),
                },
            ];
        } else {
            columns = [
                {
                    title: 'Player',
                    dataIndex: 'playerName',
                },
                {
                    title: 'State',
                    dataIndex: 'state',
                    filterable: true,
                },
                {
                    title: 'School',
                    dataIndex: 'schoolName',
                },
                {
                    title: 'Coaches',
                    dataIndex: 'coaches',
                },
                {
                    title: 'Jersey',
                    dataIndex: 'jersey',
                },
                {
                    title: 'Position',
                    dataIndex: 'position',
                    filterable: true,
                },
                {
                    title: 'Graduating Class',
                    dataIndex: 'graduationYear',
                    filterable: true,
                },
                {
                    title: 'Hudl Link',
                    dataIndex: 'link',
                },
                {
                    title: 'Date Added',
                    dataIndex: 'dateAddedDisplay',
                },
                {
                    title: 'Added From',
                    dataIndex: 'addedFrom',
                    filterable: true,
                },
                {
                    dataIndex: 'playerId',
                    render: (playerId) => (<RemoveFromSavedList listId={listId} playerId={playerId} />),
                },
            ];
        }

        return columns;
    };

    useEffect(() => {
        // don't do anything if already loading
        if ((listId) && !loading && query !== currentQuery) {
            dispatch(getQuicksearchByQuerystring(query));
        }
    });

    return (
        <div className="center">
            {!isPipelineList ?
                <h1>
                    Saved List
                </h1> :
                null
            }
            <PlayerTable
                rowSelection={rowSelection}
                playerData={displayData}
                columns={getColumns()}
                loading={loading}
                navBase={'/recruiters'}
            />
        </div>
    );
}

export default SavedList;
