import {
    GET_PLAYERSTATS_FAILURE,
    GET_PLAYERSTATS_STARTED,
    GET_PLAYERSTATS_SUCCESS,
    GET_PLAYERSTATS_NGFB_FAILURE,
    GET_PLAYERSTATS_NGFB_STARTED,
    GET_PLAYERSTATS_NGFB_SUCCESS,
    RESET_PLAYERSTATS,
} from '../actions/playerstatsActions';

const initialState = {
    loading: false,
    syncing: false,
    error: null,
    currentPlayerId: null,
    playerIds: [],
    dataByPlayerId: {},
    ngfbDataByPlayerId: {},
};

export default function playerstatsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PLAYERSTATS_STARTED:
            return {
                ...state,
                loading: true,
                currentPlayerId: action.payload,
            };
        case GET_PLAYERSTATS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                playerIds: state.playerIds.includes(action.payload.playerId) ? state.playerIds : [...state.playerIds, action.payload.playerId],
                dataByPlayerId: { ...state.dataByPlayerId, [action.payload.playerId]: action.payload.data },
            };
        case GET_PLAYERSTATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case GET_PLAYERSTATS_NGFB_STARTED:
            return {
                ...state,
                loading: true,
                currentPlayerId: action.payload,
            };
        case GET_PLAYERSTATS_NGFB_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                playerIds: state.playerIds.includes(action.payload.playerId) ? state.playerIds : [...state.playerIds, action.payload.playerId],
                ngfbDataByPlayerId: { ...state.ngfbDataByPlayerId, [action.payload.playerId]: action.payload.data },
            };
        case GET_PLAYERSTATS_NGFB_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case RESET_PLAYERSTATS:
            return {...initialState};
        default:
            return state;
    }
}
