import {convertInches} from "../utils";

export const selectSeasonstatsState = store => store.seasonstatsData;

export const selectSeasonstatsByQueryString = (queryString) => (store) => {
    try {
        if (selectSeasonstatsState(store).currentQueryString === queryString)
            return selectSeasonstatsState(store).dataByQueryString
                .map(row => {
                    const newrow = {...row};
                    if (row.height && typeof row.height === 'number')
                        newrow.displayHeight = convertInches(row.height);

                    if (row.listIds)
                        newrow.playerName = newrow.playerName + ' (In Pipeline)';

                    return newrow;
                });
        else
            return null;
    }
    catch (e) {
        return null;
    }
}

const keyToColumn = key => {
    const fixedCols = ['playerName', 'state', 'schoolName', 'graduationYear', 'position'];

    return {
        title: key,
        dataIndex: key,
        // sorter is added in PlayerTable:
        // sorter: (a, b) => {
        //     // sort by original field if display value
        //     const k = key.startsWith('display') ? (key.substring(7, 8).toLowerCase() + key.substring(8)) : key;
        //     return (typeof a[k] === 'string' ? a[k].localeCompare(b[k]) : a[k] - b[k]);
        // },
        sortDirections: ['descend', 'ascend'],
        fixed: fixedCols.indexOf(key)>=0 ? 'left' : null,
        filterable: key === 'position' || key === 'graduationYear',
        //width: fixedCols.indexOf(key)>=0 ? 200 : null,
        //ellipsis: true,
        //onChange: console.log("keyToColumn: "),

    }
}

export const selectSeasonstatColumnsByQueryString = (queryString) => (store) => {
    const data = selectSeasonstatsByQueryString(queryString)(store);
    try {
        return Object.keys(data[0])
            // remove columns with all zeros
            .filter(key => key.indexOf('Id') < 0 && !data.every(row => row[key] === 0))
            // remove columns with display fields
            .filter(key => !Object.keys(data[0]).includes('display' + key.substring(0, 1).toUpperCase() + key.substring(1)))
            .map(keyToColumn)
    }
    catch (e) {
        return [];
    }
}

export const selectSeasonstatsLoading = store =>
    selectSeasonstatsState(store) ? selectSeasonstatsState(store).loading : false;
