import React, {useEffect} from 'react';
import RosterTabs from "./components/RosterTabs";
import NongamestatTabs from "./components/NongamestatTabs";
import PlayerTable from "../../shared-components/PlayerTable";
import {useDispatch, useSelector} from "react-redux";
import {getNongamestats, updateNongamestatTargets} from "../../redux/actions/nongamestatsActions";
import {selectNongamestatsByRosterStat, selectNongamestatsLoading} from "../../redux/selectors/nongamestatsSelectors";
import * as queryString from "query-string";
import {nongamestats} from "../../shared-components/categories_fb";
import {Layout} from 'antd';
import FlatfileNGSUpload from "./components/FlatfileNGSUpload";

const NongameData = (props) => {

    const {rosterId, stat} = queryString.parse(props.location.search);

    const data = useSelector(selectNongamestatsByRosterStat(rosterId, stat));

    const loading = useSelector(selectNongamestatsLoading);

    const statObject = stat ? nongamestats.find(s => s.stat === stat) : null;
    const statLabel = statObject ? statObject.label : null;

    const dispatch = useDispatch();

    const updatePlayer = row => {
        dispatch(updateNongamestatTargets(rosterId, row.playerId, stat, stat + '_target', row[stat + '_target']));
    };

    useEffect(() => {
            if (rosterId && stat && !loading && !data)
                dispatch(getNongamestats(rosterId, stat));
        }
    );

    const baseColumns = [
        {
            title: 'Player',
            dataIndex: 'playerName',
        },
        {
            title: 'Jersey',
            dataIndex: 'jersey',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            filterable: true,
        },
    ];

    const columns = (stat === 'attribs') ?
        baseColumns.concat([
            {
                title: 'Height',
                dataIndex: 'height',
            },
            {
                title: 'Weight',
                dataIndex: 'weight',
            },
            {
                title: 'Wingspan',
                dataIndex: 'wingspan',
            },
        ]) :
        baseColumns.concat([
            {
                title: statLabel + ' Target',
                dataIndex: stat + '_target',
                editable: true,
            },
            {
                title: statLabel,
                dataIndex: stat,
            },
            {
                title: 'Date Recorded',
                dataIndex: 'date_recorded',
            },
        ]);

    return (
        <Layout>
            <RosterTabs rosterId={rosterId} hideScouting={true}>
                <Layout.Content style={{"marginRight": "15px"}}>

                <NongamestatTabs stat={stat} rosterId={rosterId}>
                    <FlatfileNGSUpload
                        rosterId={rosterId}
                    />
                    <PlayerTable
                        playerData={data}
                        columns={columns}
                        loading={loading}
                        updatePlayer={updatePlayer}
                        editable={stat !== 'attribs'}
                        navBase={'/coaches'}
                    />
                </NongamestatTabs>
                </Layout.Content>
            </RosterTabs>
        </Layout>
    );
}

export default NongameData;
