import {useDispatch, useSelector} from "react-redux";
import * as queryString from "query-string";
import {getCustomAnalytics, selectDataByQueryString, selectDataLoading} from "../../../../redux/customanaytics_slice";
import React, {useEffect} from "react";
import {Card, Col, Row} from "antd";
import {selectGamesByRosterId} from "../../../../redux/selectors/gamesSelectors";

const HitChart = ({gameIds, odk, groupingField1, groupingField2, filterValue, rosterId}) => {
    const dispatch = useDispatch();
    const qs = queryString.stringify({gameIds, odk, groupingField1, groupingField2}, {arrayFormat: 'comma'});
    const data = useSelector(selectDataByQueryString(qs));
    const dataLoading = useSelector(selectDataLoading);
    const gameData = useSelector(selectGamesByRosterId(rosterId));

    useEffect(() => {
        if (gameIds && !dataLoading && !data) {
            dispatch(getCustomAnalytics(qs));
        }
    },[gameIds, dataLoading, data, dispatch, qs]);

    const hitChartRegionItems = (direction, playType) => {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                {buildItems(data?.filter(playSet => {
                    return playSet?.direction?.charAt(0).toLowerCase() === direction
                        && filterValue?.includes(playSet.off_formation)
                        && ((playType === 'run' && playSet.runs > 0) || (playType === 'pass' && playSet.passes > 0))
                }))}
            </div>
        );
    }

    const buildItems = (filteredData) => {
        const counts = {};
        filteredData?.forEach(item => {
            if (counts[item.off_play]) {
                counts[item.off_play] += 1;
            } else {
                counts[item.off_play] = 1;
            }
        });

        return filteredData?.map((playSet, index) => {
            if(counts[playSet.off_play]) {
                const backgroundColor = gameData.find(game => game.gameId === playSet.gameId)?.colorKey?.props?.style?.backgroundColor;
                const itemText = counts[playSet.off_play] > 1 ? `${playSet.off_play} x ${counts[playSet.off_play]}` : playSet.off_play;
                delete counts[playSet.off_play];
                return (
                    <div
                        key={index}
                        style={{
                            flex: '1 0 calc(50% - 20px)', // This allows the box to grow and shrink but starts at the calculated width
                            maxWidth: 'calc(50%)', // Ensuring the maximum width includes margin
                            height: '25px',
                            border: `5px solid ${backgroundColor}`, // Colored border
                            marginBottom: '5px',
                            marginRight: '10px',
                            display: 'flex', // Using Flexbox
                            alignItems: 'center', // Vertically center
                            justifyContent: 'center', // Horizontally center (if needed)
                            boxSizing: 'border-box'
                        }}>
                        {itemText}
                    </div>
                );
            } else {
                return [];
            }
        })
    }

    return (
        <div>
            {/* Heading */}
            <Row gutter={[16, 16]} style={{ display: 'flex' }} justify="center">
                <Col span={1} style={{display: 'flex', paddingLeft: 0, paddingRight: 0}}/>
                <Col span={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontWeight: 'bold' }}>
                    Weak
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontWeight: 'bold' }}>
                    Middle
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontWeight: 'bold' }}>
                    Strong
                </Col>
            </Row>
            {/* First row */}
            <Row gutter={[16, 16]} style={{ display: 'flex' }}>
                <Col span={1} style={{ display: 'flex', paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{
                        writingMode: 'vertical-lr',
                        transform: 'rotate(180deg)', // This rotates the text so it starts from the top
                        margin: 'auto', // This centers the text within the flex container
                        padding: '10px',
                        fontWeight: 'bold'
                    }}>
                        Pass
                    </div>
                </Col>
                <Col span={7} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ height: '100%' }}>
                        <Card bordered={false} style={{ width: '100%', height: '150px', borderBottom: '4px solid black', borderRight: '4px solid black' }}>
                            {
                                hitChartRegionItems('w', 'pass')
                            }
                        </Card>
                    </div>
                </Col>
                <Col span={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ height: '100%' }}>
                        <Card bordered={false} style={{ width: '100%', height: '150px', borderRight: '4px solid black', borderBottom: '4px solid black', borderLeft: '4px solid black' }}>
                            {
                                hitChartRegionItems('m', 'pass')
                            }
                        </Card>
                    </div>
                </Col>
                <Col span={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ height: '100%' }}>
                        <Card bordered={false} style={{ width: '100%', height: '150px', borderBottom: '4px solid black', borderLeft: '4px solid black' }}>
                            {
                                hitChartRegionItems('s', 'pass')
                            }
                        </Card>
                    </div>
                </Col>
            </Row>

            {/* Second row */}
            <Row gutter={[16, 16]} style={{ display: 'flex' }}>
                <Col span={1} style={{ display: 'flex', paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{
                        writingMode: 'vertical-lr',
                        transform: 'rotate(180deg)', // This rotates the text so it starts from the top
                        margin: 'auto', // This centers the text within the flex container
                        padding: '10px',
                        fontWeight: 'bold'
                    }}>
                        Run
                    </div>
                </Col>
                <Col span={7} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ height: '100%' }}>
                        <Card bordered={false} style={{ width: '100%', height: '150px', borderRight: '4px solid black', borderTop: '4px solid black' }}>
                            {
                                hitChartRegionItems('w', 'run')
                            }
                        </Card>
                    </div>
                </Col>
                <Col span={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ height: '100%' }}>
                        <Card bordered={false} style={{ width: '100%', height: '150px', borderRight: '4px solid black', borderTop: '4px solid black', borderLeft: '4px solid black' }}>
                            {
                                hitChartRegionItems('m', 'run')
                            }
                        </Card>
                    </div>
                </Col>
                <Col span={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ height: '100%' }}>
                        <Card bordered={false} style={{ width: '100%', height: '150px', borderTop: '4px solid black', borderLeft: '4px solid black' }}>
                            {
                                hitChartRegionItems('s', 'run')
                            }
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default HitChart;