import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Space, Layout} from 'antd';
import {getSeasonstatsByFilter} from "../../redux/actions/seasonstatsActions";
import {
    selectSeasonstatColumnsByQueryString,
    selectSeasonstatsByQueryString,
    selectSeasonstatsLoading
} from "../../redux/selectors/seasonstatsSelectors";
import {selectNewSearchId, selectSearchByID,} from "../../redux/selectors/recruitersearchSelectors";
import {clearNewSearchId, getSearchById} from "../../redux/actions/recruitersearchActions";
import AddToSavedLists from "../../shared-components/addToSavedList";
import PlayerTable from "../../shared-components/PlayerTable";
import BuildSearchForm from "./components/BuildSearchForm";
import {useLocation, useNavigate} from "@reach/router";
import * as qs from "query-string";
import {SeasonsSelect} from "../../shared-components/seasonsSelect";

const { Content } = Layout;

const AdvancedSearch = (props) => {
    const location = useLocation();
    const {searchId, seasonId} = qs.parse(location.search);

    return (
        <>
            <SeasonsSelect sport={'fb'} seasonId={seasonId} />
            <SearchPane searchId={searchId ? searchId : 'new'} seasonId={seasonId}/>
        </>
    );
}

export const SearchPane = ({searchId, seasonId}) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const queryString = qs.stringify({searchId, seasonId });
    const data = useSelector(selectSeasonstatsByQueryString(queryString));
    const columns = useSelector(selectSeasonstatColumnsByQueryString(queryString), shallowEqual);
    const loading = useSelector(selectSeasonstatsLoading);
    const search = useSelector(selectSearchByID(searchId));
    const newSearchId = useSelector(selectNewSearchId);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (searchId === 'new' && newSearchId) {
            navigate(`./research?tab=advancedSearch&searchId=${newSearchId}&editMode=true`, { replace: true });
            dispatch(clearNewSearchId());
        }

        if (searchId && seasonId && !loading && !data) {
            dispatch(getSeasonstatsByFilter({searchId, seasonId}));
            if (searchId !== 'new')
                dispatch(getSearchById(searchId));
        }
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (srk) => {
            setSelectedRowKeys(srk);
        },
    };

    return (
        <Layout>
            <Content>
            <h1>
                {search ? search.searchName : null}
            </h1>
            <h2>
                {search ? search.searchDescription : null}
            </h2>
            </Content>
            <Space direction={'vertical'} align={'start'}>

                <BuildSearchForm searchId={searchId} />

                <AddToSavedLists selectedRowKeys={selectedRowKeys} clearSelectedRows={() => setSelectedRowKeys([])}
                                 addedFrom={"adv search"} addedFromDetail={search ? search.searchName : 'new'}/>

            </Space>
            <PlayerTable
                rowSelection={rowSelection}
                playerData={data}
                columns={columns}
                loading={loading}
                navBase={'/recruiters'}
            />
        </Layout>
    );
};

export default AdvancedSearch;
