import * as queryString from 'query-string';


export function buildQueryString(tabName, tab, page, filters, sortOrder, sortField, otherParams){
    const qs = {
        [tabName]: tab,
        page,
        filters: JSON.stringify(filters),
        sortOrder,
        sortField,
        ...otherParams,
    }

    return queryString.stringify(qs);
}

export function parseQueryString(qs, tabName) {
    const queryParams = queryString.parse(qs);
    return {
        ...queryParams,
        [tabName ? tabName : 'notab']: queryString.parse(qs)[tabName],
        filters: queryString.parse(qs).filters ? JSON.parse(queryString.parse(qs).filters) : null,
    }
}
