import React from "react";

const AllMessages = (props) => {
    return (
        <div className="center">
            <h1>
                All Messages
            </h1>
            <p>
                content goes here
            </p>
        </div>
    );
}

export default AllMessages;
