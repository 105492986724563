import { createSlice } from '@reduxjs/toolkit';
import {API} from "aws-amplify";

const slice = createSlice({
    name: 'seasonsData',
    initialState: {
        loading: false,
        error: null,
        dataBySport: {},
    },
    reducers: {
        seasonsLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        getSeasonsSuccess: (state, action) => {
            state.loading = false;
            state.dataBySport = { ...state.dataBySport, [action.payload.sport]: action.payload.data };
            state.error = null;
        },
        getSeasonsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export default slice.reducer;

// Actions
const {
    seasonsLoading,
    getSeasonsSuccess,
    getSeasonsFailure,
} = slice.actions;

export const getSeasonsBySport = (sport) => async dispatch => {
    try {
        dispatch(seasonsLoading());
        console.log('getting seasons', sport);
        const response = await API.get('playerAPI', `seasons/${sport}`);
        console.log('got seasons', sport, response);

        if (response.error) {
            dispatch(getSeasonsFailure(response.error));
        }
        else {
            dispatch(getSeasonsSuccess({sport, data: response}));
        }
    } catch (e) {
        dispatch(getSeasonsFailure(e));
    }
};

// Selectors
export const selectSeasonsLoading = (store) => store.seasonsData.loading;

export const selectSeasonsBySport = (sport) => (store) => {
    try {
        return store.seasonsData.dataBySport[sport];
    }
    catch (e) {
        return null;
    }
}
