import {API} from "aws-amplify";

export const GET_RECRUITER_PLAYER_NOTES_STARTED = 'GET_RECRUITER_PLAYER_NOTES_STARTED';
export const GET_RECRUITER_PLAYER_NOTES_SUCCESS = 'GET_RECRUITER_PLAYER_NOTES_SUCCESS';
export const GET_RECRUITER_PLAYER_NOTES_FAILURE = 'GET_RECRUITER_PLAYER_NOTES_FAILURE';

export const CREATE_RECRUITER_PLAYER_NOTES_STARTED = 'CREATE_RECRUITER_PLAYER_NOTES_STARTED';
export const CREATE_RECRUITER_PLAYER_NOTES_SUCCESS = 'CREATE_RECRUITER_PLAYER_NOTES_SUCCESS';
export const CREATE_RECRUITER_PLAYER_NOTES_FAILURE = 'CREATE_RECRUITER_PLAYER_NOTES_FAILURE';

export const DELETE_RECRUITER_PLAYER_NOTES_STARTED = 'DELETE_RECRUITER_PLAYER_NOTES_STARTED';
export const DELETE_RECRUITER_PLAYER_NOTES_SUCCESS = 'DELETE_RECRUITER_PLAYER_NOTES_SUCCESS';
export const DELETE_RECRUITER_PLAYER_NOTES_FAILURE = 'DELETE_RECRUITER_PLAYER_NOTES_FAILURE';

export function getRecruiterPlayerNotes() {
    console.log('getting recruiter player notes');
    return async (dispatch) => {
        try {
            dispatch(getRecruiterPlayerNotesStarted());
            const playerNotes = await API.get('recruiterAPI', `recruiterPlayerNotes`);
            console.log('got recruiter player notes');
            dispatch(getRecruiterPlayerNotesSuccess(playerNotes))
        } catch (e) {
            console.log('problem getting recruiter player notes:', e);
            dispatch(getRecruiterPlayerNotesFailure(e));
        }
    }
}

const getRecruiterPlayerNotesSuccess = (apiRes = {}) => ({
    type: GET_RECRUITER_PLAYER_NOTES_SUCCESS,
    payload: {
        playerNotes: [...apiRes],
    }
});

const getRecruiterPlayerNotesStarted = () => ({
    type: GET_RECRUITER_PLAYER_NOTES_STARTED,
});

const getRecruiterPlayerNotesFailure = error => ({
    type: GET_RECRUITER_PLAYER_NOTES_FAILURE,
    payload: {
        error
    }
});

export function createRecruiterPlayerNote(playerNoteData, dispatchOnSuccess = null) {
    return async (dispatch) => {
        try {
            dispatch(createRecruiterPlayerNoteStarted());
            const playerNotes = await API.post('recruiterAPI', `recruiterPlayerNote`, {
                body: playerNoteData
            });
            if (dispatchOnSuccess)
                dispatch(dispatchOnSuccess());
            console.log('create recruiter player note for: ', playerNoteData);
            dispatch(createRecruiterPlayerNoteSuccess(playerNotes));
        } catch (e) {
            console.log('problem putting recruiter player note:', e);
            dispatch(createRecruiterPlayerNoteFailure(e.message));
        }
    }
}

const createRecruiterPlayerNoteSuccess = resp => ({
    type: CREATE_RECRUITER_PLAYER_NOTES_SUCCESS,
    payload: {...resp}
});

const createRecruiterPlayerNoteStarted = () => ({
    type: CREATE_RECRUITER_PLAYER_NOTES_STARTED,
});

const createRecruiterPlayerNoteFailure = error => ({
    type: CREATE_RECRUITER_PLAYER_NOTES_FAILURE,
    payload: {
        error
    }
});

export function deletePlayersNote(playerNoteId) {
    console.log('deleting note', playerNoteId);
    return async (dispatch) => {
        try {
            console.log('deleting note', playerNoteId);
            dispatch(deletePlayerNoteStarted());
            await API.del('recruiterAPI', `recruiterPlayerNote/${playerNoteId}`);
            dispatch(deletePlayerNoteSuccess(playerNoteId))
        } catch (e) {
            dispatch(deletePlayerNoteFailure(e));
        }
    }
}

const deletePlayerNoteSuccess = (playerNoteId) => ({
    type: DELETE_RECRUITER_PLAYER_NOTES_SUCCESS,
    payload: { playerNoteId },
});

const deletePlayerNoteStarted = () => ({
    type: DELETE_RECRUITER_PLAYER_NOTES_STARTED,
});

const deletePlayerNoteFailure = error => ({
    type: DELETE_RECRUITER_PLAYER_NOTES_FAILURE,
    payload: { error },
});