import {getGamedataByGameId} from "./gamesActions";
import {API} from "aws-amplify";
import {getTeamGameOffEff, getTeamGamestatsByRosterId} from "./teamstatsActions";

export const POST_GAMEPLAYS_STARTED = 'POST_GAMEPLAYS_STARTED';
export const POST_GAMEPLAYS_SUCCESS = 'POST_GAMEPLAYS_SUCCESS';
export const POST_GAMEPLAYS_FAILURE = 'POST_GAMEPLAYS_FAILURE';

export function postGamePlays(rosterId, gameId, playData) {
    console.log('posting game plays', gameId);
    return async (dispatch) => {
        try {
            dispatch(postGamePlaysStarted(gameId));
            const gamePlays = await API.post('coachAPI', `gameplays/${gameId}`, {
                body: playData
            });
            if (gamePlays.error) {
                dispatch(postGamePlaysFailure(gamePlays.error));
            }
            else {
                // refresh games data from server:
                dispatch(getGamedataByGameId(gameId));
                dispatch(getTeamGamestatsByRosterId(rosterId));
                dispatch(getTeamGameOffEff(gameId));
                dispatch(postGamePlaysSuccess())
            }
        } catch (e) {
            dispatch(postGamePlaysFailure(e));
        }
    }
}

const postGamePlaysSuccess = () => ({
    type: POST_GAMEPLAYS_SUCCESS,
});

const postGamePlaysStarted = (gameId) => ({
    type: POST_GAMEPLAYS_STARTED,
    payload: { gameId },
});

const postGamePlaysFailure = error => ({
    type: POST_GAMEPLAYS_FAILURE,
    payload: { error },
});
