import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {selectGamedataByGameId, selectGamedataLoading} from "../../../redux/selectors/gamesSelectors";
import {getGamedataByGameId} from "../../../redux/actions/gamesActions";
import {Button, Layout, Space, Tabs} from "antd";
import XLSX from 'xlsx';
import Spreadsheet from "react-spreadsheet";
import {GameHeader} from "../game";
import {postGamePlays} from "../../../redux/actions/gamePlaysActions";
import FlatfileGameUpload from "./FlatfileGameUpload";

const ShowWorkbook = ({rosterId, gameId, workbook, clearWorkbook}) => {

    const dispatch = useDispatch();
    const postError = useSelector(store => store.gamePlaysData.error);

    const submitData = () => {
        const gameData = workbook.SheetNames
            .flatMap(s => XLSX.utils.sheet_to_json(workbook.Sheets[s]));
        dispatch(postGamePlays(rosterId, gameId, {plays: gameData}));

        // get rid of everything on submit
        //clearWorkbook();
    }

    if (workbook) {
        //TODO: do some validation:
        // - map jersey numbers to playerIds
        // - check on required fields: playNumber, quarter, odk, down, distance, yardLine, playType, result, gainLoss
        // - check acceptable values for: quarter, odk, down, playType, result
        // - check numeric values for: playNumber, distance, yardLine, gainLoss
        // - warn on missing playNumbers
        // - check yardLine == previous play yardLine + gainLoss

        return (
            <>
                <Tabs>
                    {workbook.SheetNames.map(s => (
                            <Tabs.TabPane tab={s} key={s}>
                                <Spreadsheet
                                    data={
                                        XLSX.utils.sheet_to_json(workbook.Sheets[s], {header: 1})
                                            .map(r => r.map(c => {
                                                return {value: c}
                                            }))
                                    }/>
                            </Tabs.TabPane>
                        )
                    )}
                </Tabs>

                <Space>
                    <Button
                        type="primary"
                        onClick={submitData}
                    >
                        Ok
                    </Button>

                    <Button
                        type="primary"
                        onClick={clearWorkbook}
                    >
                        Cancel
                    </Button>
                </Space>
                {postError ? (<pre>{JSON.stringify(postError, null, 4)}</pre>) : null}
            </>

            //TODO: display errors and warnings
            //TODO: add submit button
        )
    } else
        return null;
}

const GameUpload = ({rosterId, gameId, seasonId}) =>
{

    const data = useSelector(selectGamedataByGameId(gameId));
    const loading = useSelector(selectGamedataLoading);
    const dispatch = useDispatch();
    const [workbook, setWorkbook] = useState();

    useEffect(() => {
            // don't do anything if already loading
            if (!loading && !data) {
                dispatch(getGamedataByGameId(gameId));
            }
        }
    );

    let skipPlayers = false;
    try {
        skipPlayers = data.game_info[0].level === 'Scouting';
    }
    catch (e) {}

    if (data && data.game_scores.length === 0) {
        return (
            <Layout>
                <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                    <GameHeader gameInfo={data ? data.game_info[0] : null}
                                gameScores={data ? data.game_scores : null}
                    />
                </Layout.Content>

                <FlatfileGameUpload
                    rosterId={rosterId}
                    gameId={gameId}
                    skipPlayers={skipPlayers}
                />

                <ShowWorkbook
                    rosterId={rosterId}
                    gameId={gameId}
                    workbook={workbook}
                    clearWorkbook={() => {setWorkbook(null)}}
                />
            </Layout>
        );
    } else {
        return null;
    }


}

export default GameUpload;
