import React, {useEffect} from "react";
import {Layout} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getReferrals, selectReferrals, selectReferralsLoading} from "../../redux/referral_slice";
import {Link} from "@reach/router";
import PlayerTable from "../../shared-components/PlayerTable";

const Referrals = (props) => {

    const referralData = useSelector(selectReferrals)
    const loading = useSelector(selectReferralsLoading);

    const data = referralData
        ? referralData
            .map(r => {
                return {
                    ...r,
                    playerLink: <Link to={`/referrals/players/${r.playerId}`}>{r.playerName}</Link>
                }
            })
        : [];

    const dispatch = useDispatch();

    useEffect(() => {
        if (!referralData && !loading) {
            dispatch(getReferrals());
        }
    });

    const columns = [
        {
            title: 'Player',
            dataIndex: 'playerName',
        },
        {
            title: 'State',
            dataIndex: 'state',
            filterable: true,
        },
        {
            title: 'School',
            dataIndex: 'schoolName',
        },
        {
            title: 'Coaches',
            dataIndex: 'coaches',
        },
        {
            title: 'Jersey',
            dataIndex: 'jersey',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            filterable: true,
        },
        {
            title: 'Graduating Class',
            dataIndex: 'graduationYear',
            filterable: true,
        },
        {
            title: 'Height',
            dataIndex: 'displayHeight',
            filterable: true,
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            filterable: true,
        },
        {
            title: 'Hudl Link',
            dataIndex: 'link',
        },
    ];

    return (
        <Layout>
            <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                <h1>Referred Players</h1>
            </Layout.Content>
            <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop: 15}}>
                <PlayerTable
                    playerData={data}
                    columns={columns}
                    loading={loading}
                    navBase={'/referrals'}
                />
            </Layout.Content>
        </Layout>
    );
};

export default Referrals;
