import {API} from "aws-amplify";

export const GET_NONGAMESTATS_STARTED = 'GET_NONGAMESTATS_STARTED';
export const GET_NONGAMESTATS_SUCCESS = 'GET_NONGAMESTATS_SUCCESS';
export const GET_NONGAMESTATS_FAILURE = 'GET_NONGAMESTATS_FAILURE';

export const PUT_NONGAMESTATS_STARTED = 'PUT_NONGAMESTATS_STARTED';
export const PUT_NONGAMESTATS_SUCCESS = 'PUT_NONGAMESTATS_SUCCESS';
export const PUT_NONGAMESTATS_FAILURE = 'PUT_NONGAMESTATS_FAILURE';

export const POST_NONGAMESTATS_STARTED = 'POST_NONGAMESTATS_STARTED';
export const POST_NONGAMESTATS_SUCCESS = 'POST_NONGAMESTATS_SUCCESS';
export const POST_NONGAMESTATS_FAILURE = 'POST_NONGAMESTATS_FAILURE';

export function getNongamestats(rosterId, stat) {
    console.log('getting nongamestats for: ', rosterId, stat);

    return async (dispatch) => {
        try {
            dispatch(getNongamestatsStarted(rosterId, stat));
            const stats = await API.get('coachAPI', `non-game-stats/${rosterId}/${stat}`);
            console.log('got nongamestats for:', rosterId, stat);
            dispatch(getNongamestatsSuccess(rosterId, stat, stats))
        } catch (e) {
            console.log('problem getting nongamestats:', e);
            dispatch(getNongamestatsFailure(e));
        }
    }
}

const getNongamestatsSuccess = (rosterId, stat, data = []) => ({
    type: GET_NONGAMESTATS_SUCCESS,
    payload: { rosterId, stat, data }
});

const getNongamestatsStarted = (rosterId, stat) => ({
    type: GET_NONGAMESTATS_STARTED,
    payload: {rosterId, stat},
});

const getNongamestatsFailure = error => ({
    type: GET_NONGAMESTATS_FAILURE,
    payload: {
        error
    }
});

export function updateNongamestatTargets(rosterId, playerId, stat, targetStat, target) {
    console.log('update nongamestattargets for: ', rosterId, playerId, stat, targetStat, target);

    return async (dispatch) => {
        try {
            dispatch(putNongamestatsStarted(rosterId, playerId, stat, targetStat, target));
            const stats = { stats: { [targetStat]: target } };
            const nonGameStatTarget = await API.put('coachAPI', `non-game-stat-targets/${playerId}`, {
                body: stats
            });
            console.log('put nongamestattargets for:', playerId, stats);
            dispatch(putNongamestatsSuccess(nonGameStatTarget));
        } catch (e) {
            console.log('problem putting nongamestattargets:', e);
            return dispatch(putNongamestatsFailure(e));
        }
    }
}

const putNongamestatsSuccess = (resp) => ({
    type: PUT_NONGAMESTATS_SUCCESS,
    payload: {...resp}
});

const putNongamestatsStarted = (rosterId, playerId, stat, targetStat, target) => ({
    type: PUT_NONGAMESTATS_STARTED,
    payload: {rosterId, playerId, stat, targetStat, target},
});

const putNongamestatsFailure = error => ({
    type: PUT_NONGAMESTATS_FAILURE,
    payload: {
        error
    }
});

export function postNongamestats(rosterId, stats) {
    console.log('post nongamestats for: ', rosterId, stats);

    return async (dispatch) => {
        try {
            dispatch(postNongamestatsStarted(rosterId, stats));
            const nonGameStats = await API.post('coachAPI', `non-game-stats/${rosterId}`, {
                body: stats
            });
            console.log('post nongamestattargets for:', rosterId, stats);
            dispatch(postNongamestatsSuccess(nonGameStats));
        } catch (e) {
            console.log('problem posting nongamestattargets:', e);
            return dispatch(postNongamestatsFailure(e));
        }
    }
}

const postNongamestatsSuccess = (resp) => ({
    type: POST_NONGAMESTATS_SUCCESS,
    payload: {...resp}
});

const postNongamestatsStarted = (rosterId, stats) => ({
    type: POST_NONGAMESTATS_STARTED,
    payload: {rosterId, stats},
});

const postNongamestatsFailure = error => ({
    type: POST_NONGAMESTATS_FAILURE,
    payload: {
        error
    }
});
