import * as qs from "query-string";
import {API} from "aws-amplify";

export const GET_SEASONSTATS_STARTED = 'GET_SEASONSTATS_STARTED';
export const GET_SEASONSTATS_SUCCESS = 'GET_SEASONSTATS_SUCCESS';
export const GET_SEASONSTATS_FAILURE = 'GET_SEASONSTATS_FAILURE';

export function getSeasonstatsByFilter(params) {
    console.log('getting seasonstats for: ', params);
    const queryString = qs.stringify(params);

    return async (dispatch) => {
        try {
            dispatch(getSeasonstatsStarted(queryString));
            const seasonStats = await API.get('recruiterAPI', `seasonstats?${queryString}`);
            console.log('got seasonstats for:', queryString);
            dispatch(getSeasonstatsSuccess(queryString, seasonStats))
        } catch (e) {
            console.log('problem getting seasonstats:', e);
            return dispatch(getSeasonstatsFailure(e));
        }
    }
}

const getSeasonstatsSuccess = (queryString, data = []) => ({
    type: GET_SEASONSTATS_SUCCESS,
    payload: { queryString, data }
});

const getSeasonstatsStarted = (queryString) => ({
    type: GET_SEASONSTATS_STARTED,
    payload: queryString,
});

const getSeasonstatsFailure = error => ({
    type: GET_SEASONSTATS_FAILURE,
    payload: {
        error
    }
});
