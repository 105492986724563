import {Authenticator, PhoneNumberField, Radio, RadioGroupField, SelectField, TextField} from "@aws-amplify/ui-react";
import React from "react";
import {Auth} from "aws-amplify";
import {postNewuser} from "../redux/newuser_slice";
import {dispatch} from "../redux";

export const positionOptions = [
    'Head Coach',
    'Assistant Coach',
    'Position Coach',
    'Head Recruiter',
    'Recruiting Coordinator',
    'Player',
];

export const userTypeOptions = [
    'Coach',
    'Recruiter',
    'Player',
];


export const customSignup = {
    FormFields: () => {
        //const authenticator = useAuthenticator();

        return (
            <>

                {/* Append & require additional fields  */}
                <TextField
                    name="custom:firstName"
                    placeholder="First Name"
                    isRequired={true}
                />
                <TextField
                    name="custom:lastName"
                    placeholder="Last Name"
                    isRequired={true}
                />
                <PhoneNumberField
                    name="custom:phone"
                    placeholder="Phone Number"
                    isRequired={true}
                />
                <RadioGroupField
                    label="User Type"
                    name="custom:userType"
                    direction="row"
                    isRequired={true}
                >
                    {userTypeOptions.map(t => <Radio value={t}>{t}</Radio>)}
                </RadioGroupField>
                <TextField
                    name="custom:school"
                    placeholder="School"
                    isRequired={true}
                />
                <SelectField
                    name="custom:position"
                    label="custom:position"
                    placeholder="Select your position..."
                    labelHidden
                    isRequired={true}
                    options={positionOptions}
                />

                {/* Re-use default `Authenticator.SignUp.FormFields` */}
                <Authenticator.SignUp.FormFields />
            </>
        );
    },
};

export const customServices = {
    async handleSignUp(formData) {
        const { username, password, attributes } = formData;

        const realAttributes = Object.entries(attributes)
            .map(([k, v]) => [k.replace('custom:', ''), v])
            .reduce((obj, [k, v]) => { return { ...obj, [k]: v } }, {});

        console.log(realAttributes);

        const result = await Auth.signUp({
            username,
            password,
            //attributes,
        });

        dispatch(postNewuser(result.userSub, { ...realAttributes, email: username }));
        return result;
    },
};

export const customFormFields = {
    signIn: {
        username: {
            labelHidden: false,
            placeholder: 'Enter Your Email Here',
            isRequired: true,
            label: 'Email:'
        },
    },
    signUp: {
        username: {
            placeholder: 'Enter Your Email Here',
            isRequired: true,
            label: 'Email:'
        },
    },
};
