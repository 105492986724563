import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, message, Popconfirm, Space, Table } from "antd";
import {
  selectGamesByRosterId,
  selectGamesLoading,
} from "../../../redux/selectors/gamesSelectors";
import {
  createGame,
  deleteGame,
  getGamesByRosterId,
  updateGame,
} from "../../../redux/actions/gamesActions";
import moment from "moment";
import { Link, useLocation } from "@reach/router";
import { getRosterTeams } from "../../../redux/actions/rosterActions";
import { parseQueryString } from "../../../shared-components/queryStringUtils";
import { selectRosterFromTeamsBySeasonId } from "../../../redux/selectors/rosterSelectors";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { EditableCell } from "./EditSchedule";

const TeamSchedule = ({
  rosterId,
  rowSelection,
  showAddGame,
  allowGameEdit,
  includeColorKeyColumn = false,
  usePlayerApi = false,
}) => {
  const location = useLocation();
  const { seasonId } = parseQueryString(location.search);

  const gameData = useSelector(selectGamesByRosterId(rosterId));
  const gameDataLoading = useSelector(selectGamesLoading);
  const rosterTeamData = useSelector(
    (store) => store.rosterData.rosterTeamDataBySeasonId[seasonId]
  );
  const roster = useSelector(
    selectRosterFromTeamsBySeasonId(seasonId, rosterId)
  );
  const loadingTeams = useSelector((store) => store.rosterData.loadingTeams);

  const [addingRow, setAddingRow] = useState(false);

  let displayData = gameData
    ? gameData
        .map((r) => {
          let wlt = "";
          if (!isNaN(parseInt(r.score))) {
            if (parseInt(r.score) > parseInt(r.opposingScore)) wlt = "W";
            else if (parseInt(r.score) < parseInt(r.opposingScore)) wlt = "L";
            else if (parseInt(r.score) === parseInt(r.opposingScore)) wlt = "T";
            else wlt = "X";
          }

          return {
            gameId: r.gameId,
            key: r.gameId,
            displayDate: moment.utc(new Date(r.date)).format("M/D/YYYY"),
            opponent:
              (r.location === "home" ? "vs " : "@ ") +
              r.opposingSchoolName +
              " " +
              r.opposingTeamName,
            score: !isNaN(parseInt(r.score)) ? r.score + " - " + r.opposingScore : " -- ",
            wlt: wlt,
            colorKey: r.colorKey,
          };
        })
        .map((r) => {
          return {
            ...r,
            opponent: (
              <Link to={`../games/${rosterId}/${r.gameId}`}>{r.opponent}</Link>
            ),
          };
        })
    : null;

  if (addingRow && displayData)
    displayData = displayData.concat({
      key: "newRow",
      displayDate: null,
      opponent: [],
    });
  else if (addingRow)
    displayData = [{ key: "newRow", displayDate: null, opponent: [] }];

  const getSubheading = () => {
    try {
      if (roster.level === "Scouting")
        return <h2>{"Scouting: " + roster.teamName}</h2>;
      else return <></>;
    } catch (e) {
      return <></>;
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (rosterId && !gameData && !gameDataLoading) {
      dispatch(getGamesByRosterId(rosterId, usePlayerApi));
    }

    if (!rosterTeamData && !loadingTeams) {
      dispatch(getRosterTeams(seasonId, usePlayerApi));
    }
  }, [rosterId]);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record && record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.key);
  };

  const addGame = () => {
    setAddingRow(true);
    setEditingKey("newRow");
    form.setFieldsValue({ key: "newRow", displayDate: null, opponent: [] });
  };

  const saveRow = async (record) => {
    try {
      let newData = await form.validateFields();
      if (moment.isMoment(newData.displayDate))
        newData.date = newData.displayDate.format("YYYY-MM-DD");

      let opponent = newData.opponent;
      try {
        opponent = JSON.parse(newData.opponent);
      } catch (e) {
        // noop
      }

      if (typeof opponent === "object")
        newData = {
          ...newData,
          opposingTeamId: opponent.teamId,
          opposingTeamName: opponent.teamName,
          opposingSchoolId: opponent.schoolId,
          opposingSchoolName: opponent.schoolName,
        };

      console.log("updating game", { ...record, ...newData });

      if (record.key === "newRow") {
        dispatch(
          createGame(
            {
              ...record,
              ...newData,
              rosterId,
              seasonId,
              gameType: "scouting",
              location: "home",
            },
            rosterId
          )
        );
        setAddingRow(false);
      } else {
        dispatch(
          updateGame(record.gameId, { ...record, ...newData }, rosterId)
        );
      }

      setEditingKey("");
      message.success("Game updated!", 2);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "displayDate",
      width: 150,
      editable: true,
    },
    {
      title: "Opponent",
      dataIndex: "opponent",
      editable: true,
      width: 200,
    },
    {
      title: "Score",
      dataIndex: "score",
      width: 100,
    },
    {
      title: "W/L/T",
      dataIndex: "wlt",
      width: 50,
    },
    ...(includeColorKeyColumn
      ? [
          {
            title: "Key",
            dataIndex: "colorKey",
            width: 50,
          },
        ]
      : []),
    ...(allowGameEdit
      ? [
          {
            dataIndex: "operation",
            render: (_, record) => {
              if (isEditing(record)) {
                return (
                  <Space>
                    <Button
                      shape="circle"
                      icon={<CheckOutlined />}
                      onClick={() => saveRow(record)}
                    />
                    <Button
                      shape="circle"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        setEditingKey("");
                        setAddingRow(false);
                      }}
                    />
                  </Space>
                );
              } else {
                return (
                  <Space>
                    <Button
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => edit(record)}
                    />
                    <Popconfirm
                      placement="top"
                      title={() => (
                        <>
                          <p>Are you sure you want to delete this game?</p>
                          <p>
                            This will also remove play-by-play data for this
                            game <br />
                            and calculated player, team, and score summaries.
                          </p>
                        </>
                      )}
                      onConfirm={() =>
                        dispatch(deleteGame(record.gameId, rosterId))
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Space>
                );
              }
            },
          },
        ]
      : []),
  ].map((column) => {
    return !column.editable
      ? column
      : {
          ...column,
          onCell: (record) => ({
            record,
            title: column.title,
            dataIndex: column.dataIndex,
            editing: isEditing(record),
          }),
        };
  });

  return (
    <div style={{ width: "700px" }}>
      <h1>Schedule/Scores</h1>
      {getSubheading()}
      <Form form={form} component={false}>
        <Table
          rowKey="gameId"
          columns={columns}
          dataSource={displayData}
          pagination={false}
          tableLayout="fixed"
          loading={gameDataLoading}
          size="small"
          rowSelection={rowSelection}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>

      {showAddGame ? (
        <Button
          type="dashed"
          onClick={() => {
            addGame();
          }}
          block
        >
          <PlusOutlined /> Add Game
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TeamSchedule;
