import React, {useEffect} from "react";
import {Button, Layout, Space, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectLoading} from "../../redux/selectors/recruiterlistSelectors";
import {sorter} from "../../shared-components/PlayerTable";
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {selectGotSearches, selectSearches} from "../../redux/selectors/recruitersearchSelectors";
import {deleteSearch, getSearches} from "../../redux/actions/recruitersearchActions";
import {useNavigate} from "@reach/router";
import {formatRelative} from "date-fns";
import {Link} from "@reach/router";

const SavedSearches = (props) => {
    let searchListData = useSelector(selectSearches);
    const gotSearches = useSelector(selectGotSearches);
    const loading = useSelector(selectLoading);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!gotSearches && !loading)
            dispatch(getSearches());
    });

    if (searchListData) {
        searchListData = searchListData
            .map(s => {
                return {
                    ...s,
                    searchLink: <Link to={`../research?tab=advancedSearch&searchId=${s.searchId}`}>{s.searchName}</Link>
                }
            })
            .map(s => {
                if (s.lastUpdate) {
                    return {
                        ...s,
                        lastUpdate: new Date(s.lastUpdate),
                        lastUpdateDisplay: formatRelative(new Date(s.lastUpdate), new Date()),
                    }
                }
                else {
                    return s;
                }
            });
    }

    const columns = [
        {
            title: 'Search Name',
            dataIndex: 'searchLink',
            sorter: sorter('searchName'),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Description',
            dataIndex: 'searchDescription',
            sorter: sorter('searchDescription'),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last Update',
            dataIndex: 'lastUpdateDisplay',
            sorter: sorter('lastUpdate'),
            sortDirections: ['ascend', 'descend'],
        },
        {
            dataIndex: 'searchId',
            render: (searchId, record) => {
                return (
                    <Space>
                        <Button shape="circle" icon={<EditOutlined/>}
                                onClick={() => navigate(`./research?tab=advancedSearch&searchId=${searchId}&editMode=true`)}/>
                        <Button shape="circle" icon={<DeleteOutlined/>}
                                onClick={() => dispatch(deleteSearch(searchId))}/>
                    </Space>
                )
            },
        }
    ];

    return (
        <Layout>
            <Layout.Content>
            <h1>Saved Searches</h1>
            <Space direction={"vertical"}>
                <Button type={"primary"}
                        onClick={() => navigate('./research?tab=advancedSearch&editMode=true')}>
                    New Search
                </Button>
            </Space>
            </Layout.Content>
            <Layout.Content style={{marginLeft: 0, marginRight: 30, marginTop:15}}>
                <Table
                    dataSource={searchListData}
                    rowKey='searchId'
                    columns={columns}
                    loading={loading}
                    pagination={false}
                />
            </Layout.Content>
        </Layout>
    );
};

export default SavedSearches;
