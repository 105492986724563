import React, {useEffect} from "react";
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectGamesByRosterId, selectGamesLoading} from "../redux/selectors/gamesSelectors";
import {getGamesByRosterId} from "../redux/actions/gamesActions";
import moment from "moment";

const { Option } = Select;

function buildGamesArray(gameData) {
    return gameData.map(game => {
        let chartId;

        if(moment(game.date).isBefore(new Date())) {
            chartId = game.chartId;
        } else if(moment(game.date).isAfter(new Date())) {
            chartId = game.chartId ? game.chartId : 'defaultRoster';
        }

        return {
            searchId: game.gameId + '_' + chartId,
            searchName: moment.utc(new Date(game.date)).format('M/D/YYYY')
                + (game.gameType === 'home' ? ' vs ' : ' @ ')
                + game.opposingSchoolName + ' ' + game.opposingTeamName
        }
    })
}


export const GamesSelect = ({onChange, width, mode = 'default', rosterId}) => {
    const gamesData = useSelector(selectGamesByRosterId(rosterId));
    const searchOptions = gamesData ? buildGamesArray(gamesData) : [];
    const loading = useSelector(selectGamesLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!searchOptions && !loading)
            dispatch(getGamesByRosterId(rosterId));
    });

    return (
        <Select
            showSearch={true}
            allowClear
            placeholder="Select a Game"
            mode={mode}
            style={!!width ? {width: width} : {}}
            defaultValue="defaultRoster"
            onChange={onChange}>
            { [<Option key='defaultRoster' value='defaultRoster'>Team Chart</Option>].concat(searchOptions.map(d => <Option key={d.searchId}>{d.searchName}</Option>)) }
        </Select>
    );
};
