import {
    GET_SEASONSTATS_FAILURE,
    GET_SEASONSTATS_STARTED,
    GET_SEASONSTATS_SUCCESS,
} from '../actions/seasonstatsActions';

const initialState = {
    loading: false,
    syncing: false,
    error: null,
    currentQueryString: null,
    dataByQueryString: null,
};

export default function seasonstatsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SEASONSTATS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_SEASONSTATS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                currentQueryString: action.payload.queryString,
                dataByQueryString: action.payload.data,
            };
        case GET_SEASONSTATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}
