import {Select} from "antd";
import React, {useState} from "react";
import {API} from "aws-amplify";

export const SchoolTeamSelect = ({level, ...props}) => {

    const [data, setData] = useState([]);

    const handleSearch = async value => {
        if (value) {
            try {
                const teams = await API.get('coachAPI', `teams?searchString=${encodeURI(value)}&level=${level}`);
                setData(teams)
            } catch (e) {
                console.log('problem getting teams:', e);
            }
        }
    };

    return (
        <Select
            placeholder="Select School/Team"
            allowClear
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            {...props}
        >
            {data.map(d => (
                <Select.Option
                    key={d.schoolId + '.' + d.teamId}
                    value={JSON.stringify(d)}
                >
                    {d.schoolName} {d.teamName}
                </Select.Option>
            ))}
        </Select>
    );
};
