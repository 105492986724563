import React, {useEffect, useState} from "react";
import {Modal, Radio} from "antd";
import {getRosterTeams} from "../../../redux/actions/rosterActions";
import {useDispatch, useSelector} from "react-redux";
import {selectRosterTeamsBySeasonId} from "../../../redux/selectors/rosterSelectors";

export const RosterSelect = ({seasonId, ...radioProps}) => {

    const dispatch = useDispatch();

    const rosterTeamData = useSelector(selectRosterTeamsBySeasonId(seasonId));
    const loadingTeams = useSelector(store => store.rosterData.loadingTeams);
    const teamsLoaded = useSelector(store => store.rosterData.teamsLoaded);

    const rosterOptions = rosterTeamData
        ? rosterTeamData.map(r => <Radio key={r.rosterId} value={r.rosterId}>{r.level}</Radio>)
        : [];

    useEffect(() => {
        if (!rosterTeamData && !loadingTeams && !teamsLoaded) {
            dispatch(getRosterTeams());
        }
    });

    return (
        <Radio.Group
            {...radioProps}
        >
            {rosterOptions}
        </Radio.Group>

    )

}

const RosterModal = ({isVisible, onSelectRoster, seasonId}) => {

    const [selectedRoster, setSelectedRoster] = useState('');

    // function returns a function to be passed as onOk or onCancel
    const okCancel = (ok) => () => {
        // call the passed handler with selection or null
        onSelectRoster(ok ? selectedRoster : null);
        // reset selection
        setSelectedRoster('');
    }

    return (
        <Modal
            title="Team Options"
            visible={isVisible}
            onOk={okCancel(true)}
            onCancel={okCancel(false)}
        >
            <h3>Which Roster?</h3>
            <RosterSelect
                seasonId={seasonId}
                onChange={e => setSelectedRoster(e.target.value)}
                value={selectedRoster}
            />
        </Modal>
    );
};

export default RosterModal;
