import { createSlice } from '@reduxjs/toolkit';
import {API} from "aws-amplify";

const slice = createSlice({
    name: 'stagedUsers',
    initialState: {
        loading: false,
        updating: false,
        data: null,
        error: null,
    },
    reducers: {
        stagedUsersLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        getStagedUsersSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        },
        getStagedUsersFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        updateStagedUserLoading: (state) => {
            state.updating = true;
            state.error = null;
        },
        updateStagedUserSuccess: (state, action) => {
            state.updating = false;
            state.data = null;
            state.error = null;
        },
        updateStagedUserFailure: (state, action) => {
            state.updating = false;
            state.error = action.payload;
        },

        deleteStagedUserLoading: (state) => {
            state.updating = true;
            state.error = null;
        },
        deleteStagedUserSuccess: (state, action) => {
            state.updating = false;
            state.data = null;
            state.error = null;
        },
        deleteStagedUserFailure: (state, action) => {
            state.updating = false;
            state.error = action.payload;
        },

        approveStagedUserLoading: (state) => {
            state.updating = true;
            state.error = null;
        },
        approveStagedUserSuccess: (state, action) => {
            state.updating = false;
            state.data = null;
            state.error = null;
        },
        approveStagedUserFailure: (state, action) => {
            state.updating = false;
            state.error = action.payload;
        },
    },
});
export default slice.reducer;

// Actions
const {
    stagedUsersLoading,
    getStagedUsersSuccess,
    getStagedUsersFailure,
    updateStagedUserLoading,
    updateStagedUserSuccess,
    updateStagedUserFailure,
    deleteStagedUserLoading,
    deleteStagedUserSuccess,
    deleteStagedUserFailure,
    approveStagedUserLoading,
    approveStagedUserSuccess,
    approveStagedUserFailure,
} = slice.actions;

export const getStagedUsers = () => async dispatch => {
    try {
        dispatch(stagedUsersLoading());
        console.log('getting staged users');

        const response = await API.get('userAdminAPI', `stagedusers`);
        console.log('got staged users', response);

        if (response.error) {
            dispatch(getStagedUsersFailure(response.error));
        }
        else {
            dispatch(getStagedUsersSuccess(response));
        }
    } catch (e) {
        dispatch(getStagedUsersFailure(e));
    }
};

export const updateStagedUser = (userId, userData) => async dispatch => {
    try {
        dispatch(updateStagedUserLoading());
        console.log('updating staged user', userData);

        const response = await API.put('userAdminAPI', `stagedusers/${userId}`, { body: userData });
        console.log('updated staged users', response);

        if (response.error) {
            dispatch(updateStagedUserFailure(response.error));
        }
        else {
            dispatch(updateStagedUserSuccess(response));
        }
    } catch (e) {
        dispatch(updateStagedUserFailure(e));
    }
};

export const deleteStagedUser = (userId) => async dispatch => {
    try {
        dispatch(deleteStagedUserLoading());
        console.log('deleting staged user', userId);

        const response = await API.del('userAdminAPI', `stagedusers/${userId}`);
        console.log('deleted staged users', response);

        if (response.error) {
            dispatch(deleteStagedUserFailure(response.error));
        }
        else {
            dispatch(deleteStagedUserSuccess(response));
        }
    } catch (e) {
        dispatch(deleteStagedUserFailure(e));
    }
};

export const approveStagedUser = (userId, userData) => async dispatch => {
    try {
        dispatch(approveStagedUserLoading());
        console.log('approving staged user', userId, userData);

        const response = await API.post('userAdminAPI', `stagedusers/${userId}`, { body: userData });

        console.log('approved staged user', response);

        if (response.error) {
            dispatch(approveStagedUserFailure(response.error));
        }
        else {
            dispatch(approveStagedUserSuccess(response));
        }
    } catch (e) {
        dispatch(approveStagedUserFailure(e));
    }
};
