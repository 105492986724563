import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentRosterId,
  selectRosterPlayersByRosterId,
} from "../../../redux/selectors/rosterSelectors";
import { getRosterPlayersByRosterId } from "../../../redux/actions/rosterActions";
import { postGamePlays } from "../../../redux/actions/gamePlaysActions";
import { getUser } from "../../../redux/user_slice";
import FlatfileWrapper from "../../../shared-components/FlatfileWrapper";

const validResults = [
  "Complete",
  "Ffumb",
  "Fumble",
  "FumbLost",
  "FumbRec",
  "Incomplete",
  "INT",
  "KickMade",
  "KickMiss",
  "KickRet",
  "PenaltyAcc",
  "PenaltyDecl",
  "PuntRet",
  "Run",
  "Sack",
  "Safety",
  "Touchback",
  "Touchdown",
  "TwoPointConv",
];

const resultOptions = validResults.map((r) => {
  return { value: r, label: r };
});

const gameUploadBlueprint = {
  name: "Play-by-play Data",
  stub: "pbp-data",
  readonly: false,
  allowAdditionalFields: true,
  fields: [
    {
      label: "Play Number",
      key: "playNumber",
      type: "string",
      constraints: [{ type: "required" }, { type: "unique" }],
    },
    {
      label: "Quarter",
      key: "quarter",
      type: "enum",
      config: {
        options: [
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "OT", label: "OT" },
        ],
      },
      constraints: [{ type: "required" }],
    },
    {
      label: "ODK",
      key: "odk",
      type: "enum",
      config: {
        options: [
          { value: "O", label: "O" },
          { value: "D", label: "D" },
          { value: "K", label: "K" },
        ],
      },
      constraints: [{ type: "required" }],
    },
    {
      label: "Down",
      key: "down",
      type: "enum",
      config: {
        options: ["0", "1", "2", "3", "4"].map((v) => {
          return { value: v, label: v };
        }),
      },
      constraints: [{ type: "required" }],
    },
    {
      label: "Distance",
      key: "distance",
      type: "number",
      constraints: [{ type: "required" }],
    },
    {
      label: "Yard Line",
      key: "yardLine",
      type: "number",
      constraints: [{ type: "required" }],
    },
    {
      label: "Play Call Type",
      key: "playCallType",
      type: "enum",
      config: {
        options: [
          { value: "Kickoff", label: "Kickoff" },
          { value: "KickRet", label: "KickReturn" },
          { value: "FG", label: "FG" },
          { value: "FGB", label: "FGB" },
          { value: "XP", label: "XP" },
          { value: "XPB", label: "XPB" },
          { value: "Run", label: "Run" },
          { value: "Pass", label: "Pass" },
          { value: "Punt", label: "Punt" },
          { value: "PuntRet", label: "PuntRet" },
          { value: "NoPlay", label: "NoPlay" },
        ],
      },
      constraints: [{ type: "required" }],
    },
    {
      label: "Gain/Loss",
      key: "gainLoss",
      type: "number",
      constraints: [{ type: "required" }],
    },
    {
      label: "Result1",
      key: "result1",
      type: "enum",
      config: {
        options: resultOptions,
      },
    },
    {
      label: "Result2",
      key: "result2",
      type: "enum",
      config: {
        options: resultOptions,
      },
    },
    {
      label: "Result3",
      key: "result3",
      type: "enum",
      config: {
        options: resultOptions,
      },
    },
    {
      label: "Result4",
      key: "result4",
      type: "enum",
      config: {
        options: resultOptions,
      },
    },
  ],
};

const playerFields = [
  { label: "QB Jersey", key: "qbJersey", type: "string" },
  { label: "Receiver Jersey", key: "recJersey", type: "string" },
  { label: "Runner Jersey", key: "runJersey", type: "string" },
  { label: "Tackler1 Jersey", key: "tack1Jersey", type: "string" },
  { label: "Tackler2 Jersey", key: "tack2Jersey", type: "string" },
  { label: "Pass Defense Jersey", key: "passDefJersey", type: "string" },
  { label: "INT Jersey", key: "intJersey", type: "string" },
  { label: "Fumble Jersey", key: "fumJersey", type: "string" },
  { label: "FF Jersey", key: "forceFumJersey", type: "string" },
  { label: "Fum Rec Jersey", key: "fumRecJersey", type: "string" },
  { label: "Kicker Jersey", key: "kickJersey", type: "string" },
];

const moreFields = [
  { label: "Offensive Formation1", key: "offFormation1", type: "string" },
  { label: "Offensive Formation2", key: "offFormation2", type: "string" },
  { label: "Offensive Formation3", key: "offFormation3", type: "string" },
  { label: "Offensive Formation4", key: "offFormation4", type: "string" },
  { label: "Offensive Playcall1", key: "offPlaycall1", type: "string" },
  { label: "Offensive Playcall2", key: "offPlaycall2", type: "string" },
  { label: "Offensive Playcall3", key: "offPlaycall3", type: "string" },
  { label: "Offensive Playcall4", key: "offPlaycall4", type: "string" },
  {
    label: "Play Direction",
    key: "direction",
    type: "enum",
    config: {
      options: [
        { value: "Strong", label: "Strong" },
        { value: "Weak", label: "Weak" },
        { value: "Middle", label: "Middle" },
      ],
    },
  },
  { label: "Defensive Personnel", key: "defPersonnel", type: "string" },
  { label: "Defensive Front", key: "defFront", type: "string" },
  { label: "Defensive Coverage", key: "defCoverage", type: "string" },
  { label: "Defensive Playcall", key: "defPlaycall", type: "string" },
];

const FlatfileGameUpload = ({ rosterId, gameId, skipPlayers }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  useEffect(() => {
    if (!userData.userAttributes) {
      dispatch(getUser());
    }
  });

  const currentRosterIdInStore = useSelector(selectCurrentRosterId);
  const rosterPlayerData = useSelector(selectRosterPlayersByRosterId(rosterId));
  const loadingPlayers = useSelector(
    (store) => store.rosterData.loadingPlayers
  );

  useEffect(() => {
    if (!!rosterId && rosterId !== currentRosterIdInStore && !loadingPlayers) {
      dispatch(getRosterPlayersByRosterId(rosterId));
    }
  });

  const transformData = (plays) => {
    return plays
      .map((play) => {
        const results = [play.result1];
        if (play.result2) {
          results.push(play.result2);
        }
        if (play.result3) {
          results.push(play.result3);
        }
        if (play.result4) {
          results.push(play.result4);
        }

        const offFormation = [play.offFormation1];
        if (play.offFormation2) {
          offFormation.push(play.offFormation2);
        }
        if (play.offFormation3) {
          offFormation.push(play.offFormation3);
        }
        if (play.offFormation4) {
          offFormation.push(play.offFormation4);
        }

        const offPlaycall = [play.offPlaycall1];
        if (play.offPlaycall2) {
          offPlaycall.push(play.offPlaycall2);
        }
        if (play.offPlaycall3) {
          offPlaycall.push(play.offPlaycall3);
        }
        if (play.offPlaycall4) {
          offPlaycall.push(play.offPlaycall4);
        }

        return {
          ...play,
          result: results.join(","),
          offFormation: offFormation.join(" "),
          offPlaycall: offPlaycall.join(" "),
        };
      })
      .map((play) => {
        if (!skipPlayers) {
          const qb = rosterPlayerData.find(
            (p) => p.jersey === String(play.qbJersey)
          );
          const rec = rosterPlayerData.find(
            (p) => p.jersey === String(play.recJersey)
          );
          const run = rosterPlayerData.find(
            (p) => p.jersey === String(play.runJersey)
          );
          const tack1 = rosterPlayerData.find(
            (p) => p.jersey === String(play.tack1Jersey)
          );
          const tack2 = rosterPlayerData.find(
            (p) => p.jersey === String(play.tack2Jersey)
          );
          const kick = rosterPlayerData.find(
            (p) => p.jersey === String(play.kickJersey)
          );
          const passDef = rosterPlayerData.find(
            (p) => p.jersey === String(play.passDefJersey)
          );
          const int = rosterPlayerData.find(
            (p) => p.jersey === String(play.intJersey)
          );
          const forceFum = rosterPlayerData.find(
            (p) => p.jersey === String(play.forceFumJersey)
          );
          const fumRec = rosterPlayerData.find(
            (p) => p.jersey === String(play.fumRecJersey)
          );

          return {
            ...play,
            qbPlayerId: qb ? qb.playerId : null,
            recPlayerId: rec ? rec.playerId : null,
            runPlayerId: run ? run.playerId : null,
            tack1PlayerId: tack1 ? tack1.playerId : null,
            tack2PlayerId: tack2 ? tack2.playerId : null,
            kickPlayerId: kick ? kick.playerId : null,
            passDefPlayerId: passDef ? passDef.playerId : null,
            intPlayerId: int ? int.playerId : null,
            forceFumPlayerId: forceFum ? forceFum.playerId : null,
            fumRecPlayerId: fumRec ? fumRec.playerId : null,
          };
        } else return play;
      });
  };

  const blueprint = skipPlayers
    ? {
        ...gameUploadBlueprint,
        fields: gameUploadBlueprint.fields.concat(moreFields),
      }
    : {
        ...gameUploadBlueprint,
        fields: gameUploadBlueprint.fields
          .concat(playerFields)
          .concat(moreFields),
      };

  const validateJersey = (record, jerseyField) => {
    const j = record.get(jerseyField);
    if (rosterPlayerData && j && !rosterPlayerData.find((p) => p.jersey === j))
      record.addError(jerseyField, "Jersey not found in roster");
  };

  const defaultToZero = (record, numberField) => {
    const n = record.get(numberField);
    if (!n || n === "") record.set(numberField, 0);
  };
  const recordHandler = (record) => {
    const defaultZeroFields = ["down", "distance", "gainLoss"];
    defaultZeroFields.forEach((f) => defaultToZero(record, f));

    blueprint.fields
      .filter((f) => f.key.endsWith("Jersey"))
      .forEach((f) => validateJersey(record, f.key));
  };

  return (
    <>
      <FlatfileWrapper
        blueprint={blueprint}
        handleRecord={recordHandler}
        handleSubmit={(data) =>
          dispatch(
            postGamePlays(rosterId, gameId, { plays: transformData(data) })
          )
        }
        buttonContent={<>Upload game data</>}
      ></FlatfileWrapper>
    </>
  );
};

export default FlatfileGameUpload;
