import React from "react";

const TeamNeeds = (props) => {
    return (
        <div className="home">
            <h1>
                Team Needs
            </h1>
            <p>
                This section is where you can create priority lists for each recruiting class including position focuses
                and individual player priorities and rankings. We are open to all input on content for this page and
                different management tools you would like to have.
            </p>
        </div>
    );
}

export default TeamNeeds;
