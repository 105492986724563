import React, {useEffect, useMemo, useState} from "react";
import {Space, Tabs} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {PositionCardsForPositionGroups} from "./positionCardsForPositionGroup";
import {GamesSelect} from "../../../shared-components/gamesSelect";
import {
    selectActiveDepthByChartId,
    selectDepthChartPlayersGroupedByPosition
} from "../../../redux/selectors/depthChartSelectors";
import {
    createDepthChartForGameIdAndRoster,
    getDepthChartPlayersRosterIdAndChartId,
    putDepthChartPlayers,
} from "../../../redux/actions/depthChartActions";
import {getGamedataByGameId, getGamesByRosterId} from "../../../redux/actions/gamesActions";
import {useLocation, useNavigate} from "@reach/router";
import * as queryString from "query-string";
import {selectGamesByRosterId} from "../../../redux/selectors/gamesSelectors";
import {PDFDownloadLink} from "@react-pdf/renderer";
import DepthChartToPdf from "./DepthChartToPdf";
import {Button} from "antd";
import moment from "moment";

const {TabPane} = Tabs;

export const PlayerPositionGroupTabs = ({rosterId, readOnly = false}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [chartId, setChartId] = useState('defaultRoster');
    const [gameId, setGameId] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(readOnly);
    const gamesByRosterId = useSelector(selectGamesByRosterId(rosterId));
    const activeDepthByChartId = useSelector(selectDepthChartPlayersGroupedByPosition);
    const depthChartActiveChartId = useSelector(selectActiveDepthByChartId);
    const activePositionGroup = queryString.parse(location.search)['positionGroup'] ?
        queryString.parse(location.search)['positionGroup'] :
        "offense";

    useEffect(() => {
        const getDepthChartDataByChartId = () => {
            if (rosterId) {
                const params = chartId === 'defaultRoster' ? {rosterId} : {rosterId, chartId};
                dispatch(getDepthChartPlayersRosterIdAndChartId(params));
                dispatch(getGamesByRosterId(rosterId));
            }
        };

        getDepthChartDataByChartId();
    }, [dispatch, chartId, rosterId]);

    useEffect(() => {
        if(gamesByRosterId && !readOnly) {
            const game = gamesByRosterId.find(game => game.gameId === gameId);
            setIsReadOnly(game ?
                moment(game.date).isBefore(new Date()) :
                readOnly);
        }
    }, [gamesByRosterId, gameId, readOnly]);

    useEffect(() => {
        const updateDepthChart = () => {
            let playersWithUpdatedDepth = [];
            Object.entries(activeDepthByChartId).forEach(([k, v], index) => {
                Object.entries(v).forEach(([key, value], index) => {
                    playersWithUpdatedDepth = playersWithUpdatedDepth.concat(value);
                });
            });

            if(playersWithUpdatedDepth.length > 0) {
                if ((playersWithUpdatedDepth[0].chartId === "noChartId" || gameId !== null) && chartId === 'defaultRoster') {
                    dispatch(createDepthChartForGameIdAndRoster({
                        rosterId: rosterId,
                        gameId: gameId === null ? "" : gameId,
                        players: playersWithUpdatedDepth
                    }, setNewChartId));
                } else {
                    dispatch(putDepthChartPlayers(playersWithUpdatedDepth, chartId));
                }
            }
        };

        if(Object.keys(activeDepthByChartId).length > 0) {
            updateDepthChart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDepthByChartId]);

    const setNewChartId = (chartId) => {
        setChartId(chartId);
    };

    const updateFromGameSelection = (idForSelectedGame) => {
        if (idForSelectedGame && idForSelectedGame !== 'defaultRoster') {
            const selectedGameId = idForSelectedGame.substring(0, idForSelectedGame.indexOf('_'));
            const chartIdForSelectedGame = idForSelectedGame.substring(idForSelectedGame.indexOf('_') + 1, idForSelectedGame.length);
            setGameId(selectedGameId);
            setChartId(chartIdForSelectedGame);
            dispatch(getGamedataByGameId(selectedGameId));
        } else {
            setChartId('defaultRoster');
            setGameId(null);
            dispatch(getGamedataByGameId(null));
        }
    };

    const setSelectedPositionGroup = (positionGroup) => {
        const queryParams = queryString.parse(location.search);
        navigate(location.pathname + '?' + queryString.stringify({...queryParams, positionGroup}));
    };

    const RerenderablePDF = () => {
        return useMemo(
            () => (
                <PDFDownloadLink document={<DepthChartToPdf activeDepthByChartId={activeDepthByChartId} />} fileName="Depth_Chart.pdf">
                    {
                        ({ loading }) => (loading ? 'Loading document...' : 'Download to PDF')
                    }
                </PDFDownloadLink>
            ),
            [],
        )
    };

    return (<div>
        <Space>
            <GamesSelect onChange={updateFromGameSelection} width={300} rosterId={rosterId}/>
            {depthChartActiveChartId ? <Button type="primary"><RerenderablePDF /></Button> : null}
        </Space>
        <Tabs activeKey={activePositionGroup} defaultActiveKey={"offense"} onChange={setSelectedPositionGroup}
              size={'large'}>
            <TabPane tab="Offense" key="offense" value="offense">
                <PositionCardsForPositionGroups rosterId={rosterId} chartId={depthChartActiveChartId} positionGroup={'offense'} readOnly={isReadOnly}/>
            </TabPane>
            <TabPane tab="Defense" key="defense" value="defense">
                <PositionCardsForPositionGroups rosterId={rosterId} chartId={depthChartActiveChartId} positionGroup={'defense'} readOnly={isReadOnly}/>
            </TabPane>
            <TabPane tab="Special Teams" key="specialTeams" value="specialTeams">
                <PositionCardsForPositionGroups rosterId={rosterId} chartId={depthChartActiveChartId} positionGroup={'specialTeams'} readOnly={isReadOnly}/>
            </TabPane>
        </Tabs>
    </div>);
};
