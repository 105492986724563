import React, {useEffect, useState} from "react";
import {Form, Input, message, Modal} from "antd";
import {addRosterPlayers, getRosterPlayersBySeasonId} from "../../../redux/actions/rosterActions";
import {useDispatch, useSelector} from "react-redux";
import {FBPositionSelect} from "../../../shared-components/FBSelects";
import {selectCurrentSeasonId, selectRosterPlayersByRosterId} from "../../../redux/selectors/rosterSelectors";
import {RosterSelect} from "./RosterModal";

const AddPlayerModal = ({isVisible, closeModal, rosterId, seasonId}) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [selectedRoster, setSelectedRoster] = useState(rosterId);

    const currentSeasonIdInStore = useSelector(selectCurrentSeasonId);
    const rosterPlayerDataAll = useSelector(selectRosterPlayersByRosterId("all"));
    const rosterPlayerData = useSelector(selectRosterPlayersByRosterId(rosterId==='all' ? selectedRoster : rosterId));
    const loadingPlayers = useSelector(store => store.rosterData.loadingPlayers);

    useEffect(() => {
        if ((seasonId !== currentSeasonIdInStore) && !loadingPlayers) {
            dispatch(getRosterPlayersBySeasonId(seasonId));
        }
    });

    const onOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                dispatch(
                    addRosterPlayers(
                        rosterId==='all' ? selectedRoster : rosterId,
                        {rosterPlayers: [{
                            ...values,
                                rosterId: rosterId==='all' ? selectedRoster : rosterId
                        }]}
                        )
                );
                closeModal();
                message.success(`Player added to roster!`, 3);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <Modal
            title="Add Player"
            visible={isVisible}
            okText="Add Player"
            cancelText="Cancel"
            onOk={onOk}
            onCancel={() => {
                closeModal();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                {rosterId === 'all' ?
                    <Form.Item
                        name="rosterId"
                        label="Roster"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a roster!',
                            }
                        ]}
                    >
                        <RosterSelect
                            seasonId={seasonId}
                            onChange={e => setSelectedRoster(e.target.value)}
                            value={selectedRoster}
                        />
                    </Form.Item>
                    : null
                }
                <Form.Item
                    name="playerName"
                    label="Player Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a player name!',
                        },
                        () => ({
                            validator(_, value) {
                                if (!value || !rosterPlayerDataAll.find(p=>p.playerName.toLowerCase()===value.toLowerCase())) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Player Name already on a roster!'));
                            },
                        }),

                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="position"
                    label="Position"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a position!'
                        },
                    ]}
                >
                    <FBPositionSelect />
                </Form.Item>
                <Form.Item
                    name="jersey"
                    label="Jersey"
                    rules={[
                        {
                            required: true,
                            message: 'Please assign a jersey!',
                        },
                        {
                            message:'Should be a 2 digit number ## or 2 digit number plus capital letter ##A',
                            pattern: /^\d{1,2}(\d{1,2}[A-Z])?$/
                        },
                        () => ({
                            validator(_, value) {
                                if (!rosterPlayerData.find(p=>p.jersey===value)) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Jersey already assigned!'));
                            },
                        }),
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="graduationYear"
                    label="Graduating Year"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a graduating year!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddPlayerModal;
